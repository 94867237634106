import Actions from "./components/Actions"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "21"

export const Course19: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>Польза интервального голодания для здоровья</h1>
      </div>

      <div className={style.content}>
        <div className={style.article}>
          <p>
            Приветствую тебя в новом увлекательном уроке нашего курса, где мы раскроем секреты, как простой метод
            интервального голодания может стать мощным инструментом для улучшения твоего здоровья. Мы узнаем, почему это
            не только о потере веса, но и о многих других выгодах, которые могут серьёзно улучшить твою жизнь. Готов?
            Поехали!
          </p>

          <div className={style.divider} />

          <h3>1. Улучшение работы мозга</h3>
          <p>
            Да, ты не ослышался! Интервальное голодание может сделать тебя умнее. Исследования показывают, что периоды
            без пищи стимулируют выработку нейротрофического фактора головного мозга, что способствует росту и защите
            нервных клеток. Это означает улучшение памяти, способности к обучению и общей когнитивной функции. Так что,
            если тебе нужен дополнительный толчок для твоего мозга, интервальное голодание стоит попробовать.
          </p>

          <div className={style.divider} />

          <h3>2. Повышение уровня энергии</h3>
          <p>
            Может показаться парадоксальным, но отказ от еды на определённое время может дать тебе больше энергии. Как
            это работает? Когда ты не ешь, твой организм начинает использовать запасы жира в качестве топлива, что
            обеспечивает более стабильный и долгосрочный источник энергии, в отличие от скачков сахара в крови от
            постоянного перекусывания. Это может привести к уменьшению усталости и летаргии в течение дня.
          </p>

          <div className={style.divider} />

          <h3>3. Потеря веса и снижение жировой массы</h3>
          <p>
            Один из самых очевидных и популярных эффектов интервального голодания — потеря веса. Перерывы в еде помогают
            сократить общее количество потребляемых калорий и улучшить гормональный баланс, что способствует сжиганию
            жира. Интересно, что это сжигание жира происходит, не затрагивая мышечную массу, что делает интервальное
            голодание отличным выбором для поддержания стройной фигуры.
          </p>

          <div className={style.divider} />

          <h3>4. Улучшение здоровья сердца</h3>
          <p>
            Твоё сердце тоже скажет тебе "спасибо"! Регулярное интервальное голодание может улучшить множество ключевых
            показателей, связанных со здоровьем сердца, включая уровни холестерина, кровяное давление, триглицериды и
            воспалительные маркеры. Эти изменения способствуют уменьшению риска развития сердечно-сосудистых
            заболеваний.
          </p>

          <div className={style.divider} />

          <h3>5. Долголетие и замедление процессов старения</h3>
          <p>
            Кто из нас не хочет жить долго и счастливо? Исследования на животных показали, что интервальное голодание
            может продлевать продолжительность жизни и замедлять процессы старения. Хотя нам ещё предстоит увидеть
            однозначные доказательства этих эффектов у людей, перспектива крайне захватывающая.
          </p>

          <div className={style.divider} />

          <h3>6. Улучшение метаболического здоровья</h3>
          <p>
            Интервальное голодание помогает нормализовать уровни сахара в крови, улучшить чувствительность к инсулину и
            даже помочь в борьбе с диабетом 2 типа. Это значительно улучшает твоё метаболическое здоровье, снижая риск
            развития множества хронических заболеваний.
          </p>

          <div className={style.divider} />

          <h3>В заключение</h3>
          <p>
            Интервальное голодание — это не просто модная диета, это образ жизни, который может принести тебе огромную
            пользу. От потери веса до улучшения когнитивных функций и замедления старения — преимущества многочисленны и
            могут серьёзно повлиять на твоё здоровье и самочувствие. Главное — подходить к этому методу с умом и слушать
            своё тело, чтобы найти тот ритм питания, который подходит именно тебе. Помни, твоё здоровье в твоих руках!
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
