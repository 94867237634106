import cn from "classnames"

import style from "../style.module.scss"

import type { FC } from "react"
import type { AdminLeadsTotal } from "state/types/admin"

type Props = {
  total: AdminLeadsTotal
}

export const Total: FC<Props> = ({ total }) => {
  return (
    <div className={cn("mt-6 text-end", style.total)}>
      <p>Всего лидов:</p>
      <p className="font-bold text-lg amount">{total?.leads || 0}</p>
    </div>
  )
}
