import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import API from "lib/api"
import config from "config"
import { buildParams } from "lib/helper"

import { Filters } from "./Filters"
import { Table } from "./Table"
import { Total } from "./Total"

import type { FC } from "react"
import type { AdminLeadsItem, AdminLeadsQuery, AdminLeadsTotal } from "state/types/admin"

const AdminLeads: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [isLoading, setLoading] = useState(false)
  const [leads, setLeads] = useState<Array<AdminLeadsItem>>([])
  const [total, setTotal] = useState<AdminLeadsTotal>({
    leads: 0,
  })

  const filters: AdminLeadsQuery = {
    stream: searchParams.get("stream") || "unset",
    from: searchParams.get("from") || "",
    to: searchParams.get("to") || "",
  }

  const setFilters = (filters: AdminLeadsQuery) => {
    setSearchParams(filters)
  }

  const getData = (isLive?: boolean) => {
    if (!isLive) {
      setLoading(true)
    }

    const query = {
      ...filters,
      from: filters.from || "",
      to: filters.to || "",
    }

    const url = `${config.paths.admin.leads}?${buildParams(query)}`

    API.get(url)
      .then(({ data }) => {
        setLeads(data.items)
        setTotal(data.total)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  return (
    <>
      <Filters filters={filters} setFilters={setFilters} />

      <Total total={total} />

      <Table items={leads} isLoading={isLoading} />
    </>
  )
}

export default AdminLeads
