import React from "react"
import { Navigate, Outlet } from "react-router"

import { AUTH_ROUTE } from "routes"

import type { ReactElement } from "react"

type Props = {
  isLogin: boolean
  children?: ReactElement
}

export const PrivateRoute = ({ isLogin, children }: Props) => {
  if (!isLogin) {
    return <Navigate to={AUTH_ROUTE} replace />
  }

  return children ? children : <Outlet />
}
