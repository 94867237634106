import Cookies from "universal-cookie"

type Options = {
  expires?: Date
  path?: string
}

type ExtendedFilter = {
  [key: string]: any
}

type ParamValue = number | string | Array<string> | undefined | boolean | null

export type Params = {
  [key: string]: ParamValue
}

type ParamsOptions = {
  exclude?: Array<string>
  allowEmpty?: Array<string>
}

const cookies = new Cookies()

export const getRefreshToken = () => {
  return cookies.get("refresh_token") || null
}

export const getToken = () => {
  return cookies.get("access_token") || null
}

export const setToken = (nameToken: string, value: string, options?: Options) => {
  cookies.set(nameToken, value, { path: "/", ...options })
}

export const removeRefreshToken = () => {
  cookies.remove("refresh_token", { path: "/" })
}

export const removeToken = () => {
  cookies.remove("access_token", { path: "/" })
}

export const removeTokens = () => {
  removeRefreshToken()
  removeToken()
}

export const getBaseUrl = () => {
  // return "https://api.dietgolodplan.ru"
  return process.env.NODE_ENV === "development" ? "http://localhost:4000" : "https://api.dietgolodplan.ru"
}

const paramToQueryString = (key: string, value: ParamValue) => {
  const isArray = Array.isArray(value)

  if (typeof value === "undefined") {
    return ""
  }

  return isArray
    ? `${key}=${encodeURIComponent((value as Array<string>).join(","))}`
    : `${key}=${encodeURIComponent(value as string)}`
}

export const queryString = <T extends {}>(query: T): string => {
  const queryObj = { ...query } as T & ExtendedFilter
  return Object.keys(query)
    .reduce(
      (acc, key: string) => (queryObj[key] !== "" ? [...acc, paramToQueryString(key, queryObj[key])] : acc),
      [] as Array<string>
    )
    .filter((param: ParamValue) => param)
    .join("&")
}

const removeEmptyParams = (query: Params, allowEmpty: Array<string> = []) => {
  return Object.keys(query).reduce(
    (acc, param) =>
      typeof query[param] !== "boolean" && (!query[param] || query[param] === "unset") && !allowEmpty.includes(param)
        ? acc
        : { ...acc, [param]: query[param] },
    {}
  )
}

export const buildParams = (obj: Params, options?: ParamsOptions) => {
  if (!obj) {
    return ""
  }

  let queryObject: Params = removeEmptyParams(obj, options?.allowEmpty)

  return Object.keys(queryObject)
    .reduce(
      (acc, key: string) =>
        obj[key] !== "" && obj[key] !== "undefined" && obj[key] !== "unset"
          ? [...acc, paramToQueryString(key, obj[key])]
          : acc,
      [] as Array<ParamValue>
    )
    .join("&")
}

export const getBankName = (bank: string) => {
  if (bank.includes("TINKOFF")) {
    return "Тиньков"
  }

  if (bank.includes("SBERBANK")) {
    return "Сбер"
  }

  if (bank.includes("VTB")) {
    return "ВТБ"
  }

  if (bank.includes("POCHTA")) {
    return "Почта"
  }

  if (bank.includes("GAZPROMBANK")) {
    return "Газпром"
  }

  if (bank.includes("URALSIB")) {
    return "Уралсиб"
  }

  if (bank.includes("OTKRITIE")) {
    return "Открытие"
  }

  if (bank.includes("PROMSVYAZBANK")) {
    return "Промсвязь"
  }

  if (bank.includes("PETERSBURG")) {
    return "СПБ"
  }

  return "-"
}
