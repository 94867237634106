import React, { useEffect } from "react"

import { ReactComponent as GlassEmpty } from "images/glass-empty.svg"
import { ReactComponent as GlassFull } from "images/glass-full.svg"
import { ReactComponent as Minus } from "images/minus.svg"
import { ReactComponent as Plus } from "images/plus.svg"

import { getDateNowFormatted } from "helper/dates"

import type { Dispatch } from "react"
import type { FC, SetStateAction } from "react"
import type { GlassItem } from "./types"

import style from "./style.module.scss"

type Props = {
  glassesData: GlassItem
  setGlassesData: Dispatch<SetStateAction<GlassItem>>
  setGlassesHistory: Dispatch<SetStateAction<Array<GlassItem>>>
}

export const defaultGlasses: GlassItem = {
  date: getDateNowFormatted(),
  glasses: 0,
}

export const GLASSES = "glasses"
export const GLASSES_HISTORY = "glassesHistory"

export const Water: FC<Props> = ({ glassesData, setGlassesData, setGlassesHistory }) => {
  const handleRemoveOneGlass = () => {
    if (glassesData.glasses > 0) {
      setGlassesData(prev => ({ ...prev, glasses: prev.glasses - 1 }))
    }
  }

  const handleAddOneGlass = () => {
    if (glassesData.glasses < 8) {
      setGlassesData(prev => ({ ...prev, glasses: prev.glasses + 1 }))
    }
  }

  useEffect(() => {
    if (glassesData.date !== getDateNowFormatted()) {
      setGlassesData({
        glasses: defaultGlasses.glasses,
        date: defaultGlasses.date,
      })
    }

    localStorage.setItem(GLASSES, JSON.stringify(glassesData))

    const glassesHistory: Array<GlassItem> = JSON.parse(localStorage.getItem(GLASSES_HISTORY) || "[]")
    const filteredGlassesHistory: Array<GlassItem> = glassesHistory.slice(-5).filter(el => {
      if (el.date !== getDateNowFormatted() && !el.glasses) {
        return false
      }

      return true
    })
    const newGlassesHistory: Array<GlassItem> = filteredGlassesHistory.reduce((acc, el) => {
      const exIndex: number = acc.findIndex(p => p.date === el.date)

      if (exIndex === -1) {
        return [...acc, el]
      }

      return [...acc]
    }, [] as Array<GlassItem>)

    const todayIndex: number = newGlassesHistory.findIndex(el => el.date === getDateNowFormatted())

    if (todayIndex === -1) {
      newGlassesHistory.push(glassesData)
    } else {
      newGlassesHistory[todayIndex] = glassesData
    }

    setGlassesHistory(newGlassesHistory)
    localStorage.setItem(GLASSES_HISTORY, JSON.stringify(newGlassesHistory))
  }, [glassesData, setGlassesData, setGlassesHistory])

  return (
    <>
      <h2 className="text-2xl mb-3 mt-7">Вода</h2>
      <div className={style.water}>
        <p>Укажите объем выпитой воды</p>
        <div className={style.content}>
          <Minus className={style.actionIcon} onClick={handleRemoveOneGlass} />
          <div className={style.cups}>
            {Array.from({ length: 8 }).map((el, index) => {
              const isActive = index < glassesData.glasses

              return (
                <div className={style.item} key={index.toString()}>
                  {isActive && <span className="font-semibold text-xs mb-1 block mr-auto ml-auto">0.5 л</span>}
                  {isActive ? <GlassFull className={style.glassIcon} /> : <GlassEmpty className={style.glassIcon} />}
                </div>
              )
            })}
          </div>
          <Plus className={style.actionIcon} onClick={handleAddOneGlass} />
        </div>
      </div>
    </>
  )
}
