import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "6"

export const Course4: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Здоровые Жиры для Похудения</h1>
      </div>

      <Ration kkal={900} portion="4 - 5" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти рецепты не только вкусные, но и обогащены здоровыми жирами, которые помогают поддерживать сбалансированный
        рацион и способствуют похудению.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Польза здоровых жиров</h3>
          <p>
            Здоровые жиры играют важную роль в питании и могут способствовать похудению. Они не только улучшают усвоение
            жирорастворимых витаминов, таких как A, D, E и K, но и поддерживают работу мозга и сердечно-сосудистой
            системы. Ненасыщенные жиры, особенно омега-3 и омега-6, содержащиеся в рыбе, авокадо, орехах и оливковом
            масле, помогают улучшить метаболизм и способствуют сжиганию жиров. Они также играют роль в уменьшении
            воспалительных процессов в организме и могут улучшать состояние кожи и волос.
          </p>
          <p>
            Здоровые жиры помогают поддерживать длительное чувство сытости, что снижает вероятность перекусов и помогает
            контролировать общее потребление калорий. Они также способствуют медленному и стабильному высвобождению
            энергии, что предотвращает резкие скачки уровня сахара в крови и поддерживает энергетический баланс в
            течение дня.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Авокадо на завтрак, салат с оливковым маслом, рыба на ужин</h3>
          <p>
            Включение в рацион продуктов с высоким содержанием здоровых жиров, таких как авокадо, оливковое масло и
            рыба, помогает поддерживать длительное чувство сытости и обеспечивает организм необходимыми питательными
            веществами. Авокадо на завтрак может быть отличным началом дня, так как оно содержит мононенасыщенные жиры,
            которые способствуют удовлетворению голода и поддержанию уровня энергии. Салаты, заправленные оливковым
            маслом, обогащают организм полезными жирами и антиоксидантами, улучшая пищеварение и общее самочувствие.
            Рыба на ужин, особенно жирные сорта, такие как лосось, богата омега-3 жирными кислотами, которые полезны для
            сердца и мозга, а также способствуют снижению веса.
          </p>
          <p>
            Включение этих продуктов в ежедневный рацион помогает обеспечить сбалансированное питание, поддерживает
            длительное чувство сытости и способствует здоровому похудению.
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
