import axios, { CancelTokenSource } from "axios"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import API from "lib/api"
import config from "config"
import { buildParams } from "lib/helper"

import { Filters } from "./Filters"
import { Content } from "./Content"

import type { FC } from "react"
import type { AdminStatsData, AdminStatsQuery } from "state/types/admin"

const defaultData: AdminStatsData = {
  clicks: 0,
  regs: 0,
  bills: 0,
  rebills: "0.00",
  rebillsCount: 0,
  amount: "0.00",
  active: 0,
  unsubscribed: 0,
  epc: "0.00",
  cr: 0,
  ltv: "0.00",
  lite: 0,
}

const CancelToken = axios.CancelToken
let source: CancelTokenSource

const AdminStats: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [statsData, setStatsData] = useState<AdminStatsData>(defaultData)
  const [isLoading, setLoading] = useState(true)

  const filters: AdminStatsQuery = {
    stream: searchParams.get("stream") || "unset",
    from: searchParams.get("from") || "",
    to: searchParams.get("to") || "",
  }

  const setFilters = (filters: AdminStatsQuery) => {
    setSearchParams(filters)
  }

  const getData = () => {
    if (source) {
      source.cancel()
    }

    const query = {
      ...filters,
      from: filters.from || "",
      to: filters.to || "",
    }

    const url = `${config.paths.admin.stats}?${buildParams(query)}`

    setLoading(true)

    source = CancelToken.source()

    API.get(url, { cancelToken: source.token, params: { fallback: false } }).then(({ data }) => {
      setStatsData(data)
      setLoading(false)
    })
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  return (
    <>
      <Filters filters={filters} setFilters={setFilters} />

      <Content data={statsData} isLoading={isLoading} />
    </>
  )
}

export default AdminStats
