import cn from "classnames"
import { Button } from "primereact/button"
import { useNavigate } from "react-router"

import { useAppSelector, useAppDispatch } from "hooks/dispatch"
import { getTopicRoute, MATERIALS_ROUTE } from "routes"
import { putActiveTopic } from "state/dispatchers/topics"

import style from "./style.module.scss"

import type { FC } from "react"

type Props = {
  courseId: number
}

const Actions: FC<Props> = ({ courseId }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { topicsCount, activeTopic } = useAppSelector(state => state.topics.data)

  const isPrevHidden = courseId === 1

  const onPrev = () => {
    navigate(getTopicRoute(courseId - 1))
  }

  const onNext = () => {
    if (activeTopic === topicsCount) {
      dispatch(putActiveTopic(Number(topicsCount)))
      navigate(MATERIALS_ROUTE)

      return
    }

    navigate(getTopicRoute(courseId + 1))

    if (activeTopic === courseId - 1) {
      dispatch(putActiveTopic(activeTopic + 1))
    }
  }

  return (
    <div className={style.actionsWrapper}>
      <Button
        label="Назад"
        outlined
        className={cn({ [style.hidden]: isPrevHidden })}
        disabled={isPrevHidden}
        onClick={onPrev}
      />
      <div className={style.currentTopic}>
        Урок {courseId} / {topicsCount}
      </div>
      <Button label="Далее" onClick={onNext} />
    </div>
  )
}

export default Actions
