import Actions from "./components/Actions"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "20"

export const Course18: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>Различные методы интервального голодания</h1>
      </div>

      <div className={style.content}>
        <div className={style.article}>
          <p>
            Добро пожаловать в мир интервального голодания, где время — ваш новый лучший друг в достижении здоровья и
            благополучия! Сегодня мы погрузимся в увлекательный мир различных методов интервального голодания. Подобно
            тому как люди выбирают разные стили одежды, чтобы выразить свою индивидуальность, так и в интервальном
            голодании существуют различные подходы, подходящие для разных стилей жизни и целей. Давайте рассмотрим
            некоторые из самых популярных методов!
          </p>

          <div className={style.divider} />

          <h3>1. Метод 16/8</h3>
          <p>
            Представьте себе, что ваш день разделён на две части: время для еды и время для отдыха от еды. Метод 16/8 —
            это именно такой подход. Вы едите в течение 8-часового окна (например, с 12:00 до 20:00) и голодаете в
            течение оставшихся 16 часов, которые включают время вашего сна. Этот метод идеально подходит для тех, кто
            ищет простой способ улучшить своё здоровье без значительных изменений в рационе.
          </p>

          <div className={style.divider} />

          <h3>2. Метод "Ешь, остановись, ешь"</h3>
          <p>
            Этот метод предлагает более интенсивный подход к интервальному голоданию, включая 24-часовые периоды
            голодания один-два раза в неделю. Например, если вы закончили ужин в 19:00 в понедельник, ваш следующий
            приём пищи будет не раньше 19:00 во вторник. Такой подход может быть более сложным для новичков, но он
            предлагает глубокое очищение и перезагрузку вашего метаболизма.
          </p>

          <div className={style.divider} />

          <h3>3. Метод 5:2</h3>
          <p>
            Метод 5:2 предлагает простую формулу: ешьте обычно 5 дней в неделю, а в оставшиеся 2 дня ограничивайте себя
            до 500-600 калорий в день. Эти "голодные" дни не обязательно должны идти подряд, что делает этот метод
            гибким и привлекательным для тех, кто хочет постепенно подойти к интервальному голоданию.
          </p>

          <div className={style.divider} />

          <h3>4. Альтернативный метод дня голодания</h3>
          <p>
            Вариация метода "еда-голодание", где вы чередуете дни обычного питания с днями, когда употребляете очень
            мало калорий (около 25% от вашей нормы). Этот метод требует большей дисциплины, но может быть эффективным
            для достижения определённых целей по снижению веса и улучшению здоровья.
          </p>

          <div className={style.divider} />

          <h3>5. Спонтанное пропускание приёмов пищи</h3>
          <p>
            Не так структурирован, как другие методы, но не менее эффективен. Просто пропускайте приёмы пищи, когда не
            голодны или заняты. Этот подход помогает уменьшить общее количество потребляемых калорий и учит ваш организм
            эффективнее использовать накопленную энергию.
          </p>

          <div className={style.divider} />

          <h3>На что обратить внимание</h3>
          <p>
            <b>- Подходящий выбор:</b> Выберите метод, который лучше всего подходит вашему образу жизни и целям.
          </p>
          <p>
            <b>- Постепенность:</b> Начните медленно и постепенно увеличивайте продолжительность голодания.
          </p>
          <p>
            <b>- Гидратация:</b> Пейте достаточно воды во время голодания, чтобы оставаться гидратированными.
          </p>
          <p>
            <b>- Прислушивайтесь к своему телу:</b> Если вы чувствуете себя плохо, возможно, стоит скорректировать ваш
            подход.
          </p>

          <div className={style.divider} />

          <h3>Заключение</h3>
          <p>
            Интервальное голодание предлагает гибкие подходы к улучшению здоровья и контролю веса. Экспериментируя с
            различными методами, вы сможете найти тот, который лучше всего соответствует вашему ритму жизни и целям.
            Главное — начать, и вы можете удивиться, как много вы способны достичь, просто меняя время приёма пищи.
            Удачи на вашем пути к здоровью и счастью!
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
