import { useState } from "react"

import { ConfirmDialog } from "primereact/confirmdialog"
import { Dialog } from "primereact/dialog"
import { Card } from "primereact/card"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"

import type { FC } from "react"
import type { AdminStreamsItem } from "state/types/admin"

type Props = {
  items: Array<AdminStreamsItem>
  refetch: () => void
}

export const Table: FC<Props> = ({ items, refetch }) => {
  const [selectedStream, setSelectedStream] = useState<AdminStreamsItem | null>(null)

  const onCopy = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  const codeBodyTemplate = (stream: AdminStreamsItem) => {
    const url = `https://order.dietgolodplan.ru?r=${stream.code}`

    return (
      <div className="cursor-pointer" onClick={() => onCopy(url)}>
        {url}
      </div>
    )
  }

  const codeBodyActions = (stream: AdminStreamsItem) => {
    return <Button size="small" label="Все ссылки" severity="info" onClick={() => setSelectedStream(stream)} />
  }

  // const accept = (stream: AdminStreamsItem) => {
  //   API.delete(`${config.paths.admin.streams}/${stream.id}`).finally(() => refetch())
  // }

  // const confirmContent = (stream: AdminStreamsItem) => {
  //   confirmDialog({
  //     message: "Подтвердите удаление потока",
  //     header: "Подтверждение",
  //     icon: "pi pi-exclamation-triangle",
  //     defaultFocus: "accept",
  //     baseZIndex: 9000,
  //     draggable: false,
  //     rejectLabel: "Нет",
  //     acceptLabel: "Да",
  //     accept: () => accept(stream),
  //   })
  // }

  const footerContent = (
    <div>
      <Button label="Закрыть" icon="pi pi-check" onClick={() => setSelectedStream(null)} autoFocus />
    </div>
  )

  return (
    <>
      <ConfirmDialog />
      <Dialog
        visible={!!selectedStream?.code}
        modal
        header="Все ссылки"
        footer={footerContent}
        style={{ width: "620px" }}
        onHide={() => setSelectedStream(null)}
        draggable={false}
        blockScroll
        dismissableMask
        baseZIndex={9000}
      >
        <p className="m-0">Поток: {selectedStream?.name}</p>
        <p className="m-2">Нажмите на ссылку для копирования</p>

        <p className="mt-4">Форма оплаты:</p>
        <div
          className="cursor-pointer mt-1 font-bold"
          onClick={() => onCopy(`https://order.dietgolodplan.ru?r=${selectedStream?.code}`)}
        >
          {`https://order.dietgolodplan.ru?r=${selectedStream?.code}`}
        </div>

        <p className="mt-4">Лендинг #1:</p>
        <div
          className="cursor-pointer mt-1 font-bold"
          onClick={() => onCopy(`https://dietgolodplan.ru?r=${selectedStream?.code}`)}
        >
          {`https://dietgolodplan.ru?r=${selectedStream?.code}`}
        </div>

        <p className="mt-4">Лендинг #2:</p>
        <div
          className="cursor-pointer mt-1 font-bold"
          onClick={() => onCopy(`https://dietgolodplan.ru/landing2?r=${selectedStream?.code}`)}
        >
          {`https://dietgolodplan.ru/landing2?r=${selectedStream?.code}`}
        </div>
      </Dialog>

      <Card className="mt-6">
        <DataTable value={items} emptyMessage="Потоков не найдено">
          <Column field="name" header="Название"></Column>
          <Column field="code" body={codeBodyTemplate} header="Ссылка"></Column>
          <Column field="createdAt" header="Создан" align="right"></Column>
          <Column header="Действия" body={codeBodyActions} align="right"></Column>
        </DataTable>
      </Card>
    </>
  )
}
