import Actions from "./components/Actions"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "22"

export const Course20: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>Советы по применению интервального голодания</h1>
      </div>

      <div className={style.content}>
        <div className={style.article}>
          <p>
            Приветствую тебя на очередном увлекательном занятии нашего курса! Сегодня мы раскроем тайные хитрости и
            дадим дельные советы о том, как сделать интервальное голодание не только эффективным, но и приятным
            процессом. Готов узнать, как превратить интервальное голодание из испытания в увлекательное приключение?
            Тогда вперёд!
          </p>

          <div className={style.divider} />

          <h3>1. Начни с малого</h3>
          <p>
            Если ты новичок в интервальном голодании, начинай постепенно. Не нужно сразу погружаться в самые строгие
            режимы. Начни, например, с метода 12/12 (12 часов еды, 12 часов голодания) и постепенно увеличивай
            продолжительность голодания. Это поможет твоему организму адаптироваться без стресса.
          </p>

          <div className={style.divider} />

          <h3>2. Выбери подходящее для себя время</h3>
          <p>
            Важно, чтобы твоё расписание голодания совпадало с твоим образом жизни. Если ты — "жаворонок", возможно,
            тебе будет легче закончить приём пищи рано вечером. Если ты — "сова", начни свой день позже и продолжай есть
            до позднего вечера. Главное, чтобы это было удобно именно для тебя.
          </p>

          <div className={style.divider} />

          <h3>3. Пей достаточно воды</h3>
          <p>
            Во время голодания очень важно поддерживать водный баланс. Вода помогает бороться с голодом и поддерживает
            организм в тонусе. Также можно пить чёрный кофе, зелёный чай и другие некалорийные напитки, чтобы помочь
            себе пережить периоды без еды.
          </p>

          <div className={style.divider} />

          <h3>4. Планируй свои приёмы пищи</h3>
          <p>
            Когда у тебя есть ограниченное время для еды, важно убедиться, что ты потребляешь все необходимые
            питательные вещества. Планирование помогает избежать соблазна перекусывать вредной пищей. Составляй меню
            заранее, включая белки, сложные углеводы и здоровые жиры, чтобы максимизировать пользу от твоего питания.
          </p>

          <div className={style.divider} />

          <h3>5. Слушай своё тело</h3>
          <p>
            Важно отметить, что интервальное голодание — это не конкурс выносливости. Если ты чувствуешь себя
            некомфортно или испытываешь сильный голод, возможно, стоит пересмотреть подход или метод. Здоровье и
            самочувствие всегда должны быть на первом месте.
          </p>

          <div className={style.divider} />

          <h3>6. Будь готов к препятствиям</h3>
          <p>
            Как и в любом путешествии, на пути могут возникнуть препятствия. Социальные мероприятия, праздники или
            просто дни, когда всё идёт не по плану, могут поставить тебя перед выбором. Не бойся иногда отступать и
            адаптироваться. Главное — общее направление, а не исключительная строгость.
          </p>

          <div className={style.divider} />

          <h3>7. Отмечай свои успехи</h3>
          <p>
            Не забывай отмечать маленькие и большие победы на своём пути. Достижение целей по весу, улучшение
            самочувствия, более качественный сон — всё это поводы для гордости. Помни, что каждый шаг вперёд приближает
            тебя к твоей идеальной версии.
          </p>
          <p>
            Интервальное голодание — это не только ограничения, но и возможность узнать себя, свои нужды и возможности.
            При правильном подходе это может стать не просто методикой питания, а стилем жизни, который принесёт
            здоровье, радость и удовлетворение. Удачи на твоём пути к лучшей версии себя!
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
