import React, { type FC } from "react"
import cn from "classnames"

import { InputText } from "primereact/inputtext"
import { InputMask } from "primereact/inputmask"
import { InputTextarea } from "primereact/inputtextarea"

import style from "./style.module.scss"

export type RefundFormData = {
  email: string
  fio: string
  date_transaction: string
  refund_reason: string
  first_card_num: string
  last_card_num: string
}

type Props = {
  formData: RefundFormData
  setFormData: (formData: RefundFormData) => void
}

export const FormBody: FC<Props> = ({ formData, setFormData }) => {
  return (
    <>
      <div className="flex gap-2 flex-col">
        <label htmlFor="email">Ваше фио</label>
        <InputText
          id="fio"
          value={formData.fio}
          placeholder="Укажите полностью ваше ФИО"
          onChange={e => setFormData({ ...formData, fio: e.target.value })}
          className={style.input}
          maxLength={150}
        />
      </div>
      <div className={style.twoSections}>
        <div className={style.cardNumbers}>
          <div className="flex gap-2 flex-col">
            <label htmlFor="firstCardNum">Номер карты</label>
            <InputMask
              id="firstCardNum"
              value={formData.first_card_num}
              placeholder="0000 00"
              mask="9999 99"
              onChange={e => setFormData({ ...formData, first_card_num: String(e.target.value) })}
              className={style.input}
              aria-describedby="first-card-num-help"
            />
            <small id="first-card-num-help">Первые 6 цифр карты</small>
          </div>
          <div className={style.divider} />
          <div className="flex gap-2 flex-col">
            <InputMask
              id="lastCardNum"
              value={formData.last_card_num}
              placeholder="0000"
              mask="9999"
              onChange={e => setFormData({ ...formData, last_card_num: String(e.target.value) })}
              className={style.input}
              aria-describedby="last-card-num-help"
            />
            <small id="last-card-num-help">Последние 4 цифры карты</small>
          </div>
        </div>
        <div className="flex gap-2 flex-col w-36">
          <label htmlFor="dateTransaction">Дата транзации</label>
          <InputMask
            id="dateTransaction"
            value={formData.date_transaction}
            placeholder="ММ/ГГ"
            mask="99/99"
            onChange={e => setFormData({ ...formData, date_transaction: String(e.target.value) })}
            className={style.input}
          />
        </div>
      </div>
      <div className="flex gap-2 flex-col">
        <label htmlFor="email">Ваша электронная почта</label>
        <InputText
          id="email"
          value={formData.email}
          placeholder="mail@mail.ru"
          keyfilter="email"
          onChange={e => setFormData({ ...formData, email: e.target.value })}
          className={style.input}
          maxLength={70}
        />
      </div>
      <div className="flex gap-2 flex-col">
        <label htmlFor="refundReason">Причина возврата средств</label>
        <InputTextarea
          id="refundReason"
          value={formData.refund_reason}
          placeholder="Опишите подробно причину возврата средств"
          onChange={e => setFormData({ ...formData, refund_reason: e.target.value })}
          className={cn(style.input, style.textarea)}
          maxLength={500}
          rows={5}
        />
      </div>
    </>
  )
}
