import cn from "classnames"

import style from "../style.module.scss"

import type { FC } from "react"
import type { AdminPayoutsTotal } from "state/types/admin"

type Props = {
  total: AdminPayoutsTotal
}

export const Total: FC<Props> = ({ total }) => {
  return (
    <div className={cn(style.total)}>
      <p>Доступно на вывод:</p>
      <p className="font-bold text-lg amount">{Number(total?.amount)?.toFixed(2) || "0.00"} ₽</p>
    </div>
  )
}
