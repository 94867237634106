import { useEffect, useState } from "react"
import { Button } from "primereact/button"

import { useAppSelector, useAppDispatch } from "hooks/dispatch"
import { getStreams } from "state/dispatchers/admin"

import { Table } from "./Table"
import { CreateStream } from "./Create"

import type { FC } from "react"

const AdminStreams: FC = () => {
  const dispatch = useAppDispatch()

  const { streams } = useAppSelector(state => state.admin)

  const [visible, setVisible] = useState(false)

  const getData = () => {
    dispatch(getStreams())
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <CreateStream visible={visible} onHide={() => setVisible(false)} refetch={getData} />

      <div className="flex justify-end">
        <Button label="Добавить" size="small" onClick={() => setVisible(true)} />
      </div>

      <Table items={streams} refetch={getData} />
    </>
  )
}

export default AdminStreams
