import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "17"

export const Course15: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Интеграция Похудения в Социальную Жизнь</h1>
      </div>

      <Ration kkal={360} portion="6 - 7" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти здоровые закуски и легкие коктейли станут отличным дополнением к любой вечеринке, позволяя вам
        придерживаться здорового рациона и получать удовольствие от общения с друзьями и семьей.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Сохранение прогресса в социальных ситуациях</h3>
          <p>
            Поддержание прогресса в похудении в социальных ситуациях может быть сложной задачей. Обеды вне дома,
            праздники и социальные собрания часто предлагают искушения, которые могут отвлечь от целей похудения. Однако
            с правильными стратегиями можно сохранить прогресс и наслаждаться социальной жизнью.
          </p>
          <p>
            <b>Планирование заранее:</b> Если вы знаете о предстоящем мероприятии, планируйте свой рацион так, чтобы
            учитывать возможные искушения. Например, если предстоит ужин в ресторане, легкий обед поможет сбалансировать
            дневной калораж.
          </p>
          <p>
            <b>Умные выборы в ресторане:</b> Когда заказываете еду вне дома, выбирайте блюда с большим количеством
            овощей и белков. Избегайте жареного, копченого и слишком жирного. Не стесняйтесь спрашивать о составе блюда
            или просить о замене некоторых ингредиентов.
          </p>
          <p>
            <b>Контроль порций:</b> Даже самые здоровые блюда могут быть высококалорийными, если порции слишком большие.
            Практикуйте контроль порций, останавливайтесь, когда чувствуете сытость, даже если в тарелке еще осталась
            еда.
          </p>
          <p>
            <b>Умеренность в алкоголе:</b> Алкогольные напитки часто содержат много калорий и могут снижать сдержанность
            в еде. Ограничьте потребление алкоголя или выбирайте напитки с меньшим содержанием калорий, например, сухое
            вино или легкие коктейли.
          </p>
          <p>
            <b>Сосредоточение на социализации, а не на еде:</b> Помните, что основная цель социальных событий – общение
            с друзьями и семьей, а не еда. Сосредоточьтесь на разговорах и активностях, а не на том, что подается на
            столе.
          </p>
          <p>
            <b>Не критикуйте себя за мелкие отступления:</b> Все мы иногда допускаем ошибки. Важно не давать им
            полностью сбить вас с пути. Примите исключение как часть процесса и продолжайте следовать своему плану.
          </p>
          <p>
            Соблюдение этих рекомендаций поможет сохранить прогресс в похудении и наслаждаться социальной жизнью без
            чувства вины или стресса.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Выбор здоровых блюд в ресторанах и на вечеринках</h3>
          <p>
            Выбор здоровых блюд в ресторанах и на вечеринках может стать вызовом, особенно когда вы стремитесь
            поддерживать здоровый образ жизни или соблюдать режим похудения. Однако с правильным подходом и немного
            планирования можно с легкостью наслаждаться социальными мероприятиями, не отступая от своих целей.
          </p>
          <p>
            <b>В ресторане:</b>
          </p>
          <p>
            <b>1. Прочитайте меню заранее:</b> Большинство ресторанов имеют меню в интернете. Заранее ознакомьтесь с
            ним, чтобы спланировать, что вы будете заказывать.
          </p>
          <p>
            <b>2. Выбирайте блюда, приготовленные здоровым способом:</b> Предпочтите блюда, приготовленные на пару,
            запеченные или жареные на гриле, избегайте жареных или панированных.
          </p>
          <p>
            <b>3. Спросите о замене:</b> Не стесняйтесь просить заменить некоторые ингредиенты. Например, попросите
            заменить жареный картофель на запеченные овощи или белый рис на коричневый.
          </p>
          <p>
            <b>4. Контроль порций:</b> Ресторанные порции часто очень большие. Можно попросить упаковать половину блюда
            с собой или разделить его с кем-то.
          </p>
          <p>
            <b>На вечеринке:</b>
          </p>
          <p>
            <b>1. Не идите голодным:</b> Перед вечеринкой съешьте легкий и здоровый перекус. Это поможет избежать
            переедания на мероприятии.
          </p>
          <p>
            <b>2. Выбирайте овощи и белки:</b> Начните с овощей или белков, таких как курица или рыба. Это поможет вам
            быстрее насытиться и избежать высококалорийных закусок.
          </p>
          <p>
            <b>3. Ограничьте алкоголь и сладкие напитки:</b> Алкогольные напитки и соки содержат много калорий. Лучше
            выбирать воду или негазированные напитки.
          </p>
          <p>
            <b>4. Будьте внимательны к порциям:</b> Используйте маленькие тарелки и контролируйте размер порций.
          </p>
          <p>
            Помните, что здоровый образ жизни – это не о лишениях, а о сбалансированном подходе к питанию. Выбирая
            здоровые блюда в ресторанах и на вечеринках, вы сможете наслаждаться социальной жизнью, не отказывая себе в
            удовольствиях.
          </p>

          <div className={style.divider} />

          <h3>Рецепты: Здоровые закуски для вечеринок, легкие коктейли</h3>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
