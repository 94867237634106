import { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router"

import { useAppSelector } from "hooks/dispatch"

import { TabMenu } from "primereact/tabmenu"

import { tabsItems, tabsRoutes } from "./helper"

import type { TabsItem } from "./helper"
import type { FC } from "react"

import style from "./style.module.scss"

export const AdminTabs: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const accessLevel = useAppSelector(state => state.app.profile.accessLevel)

  const [activeTab, setActiveTab] = useState<number>(-1)

  const onTabChange = (index: number) => {
    const route: TabsItem | undefined = tabsRoutes.find(el => el.index === index)

    if (route?.path) {
      setActiveTab(route.index)
      navigate(route.path)
    }
  }

  useEffect(() => {
    const route: TabsItem | undefined = tabsRoutes.find(el => el.path === pathname)

    if (route?.path) {
      setActiveTab(route.index)
    }
  }, [pathname])

  return (
    <div className={style.adminTabs}>
      <div className="container">
        <TabMenu model={tabsItems(accessLevel)} activeIndex={activeTab} onTabChange={e => onTabChange(e.index)} />
      </div>
    </div>
  )
}
