import axios from "axios"

import { getBaseUrl, getToken } from "lib/helper"

import type { InternalAxiosRequestConfig } from "axios"

const instance = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    Accept: "application/json",
  },
})

instance.interceptors.request.use(data => {
  const token = getToken()

  const headers = {
    ...data.headers,
  }

  if (!headers.Authorization) {
    headers.Authorization = token ? `Bearer ${token}` : ""
  }
  headers["Fallback-Watch"] = !(data.params?.fallback === false)

  const newData = { ...data, params: {} }
  return { ...newData, headers } as InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>
})

instance.interceptors.response.use(
  response => response,
  error => {
    const fallback = error.response ? error.response.config.headers["Fallback-Watch"] : false

    if (fallback) {
      let errorMessage: string = ""

      if (typeof error.response.data.error === "string") {
        errorMessage = error.response.data.error
      }

      if (!errorMessage && typeof error.response.data.message === "string") {
        errorMessage = error.response.data.message
      }

      const notificationEvent = new CustomEvent("notification", {
        detail: {
          type: "error",
          message: errorMessage,
        },
      })

      window.dispatchEvent(notificationEvent)
    }

    return Promise.reject(error)
  }
)

export default instance
