import type { Profile, UserStatus } from "./app"

export type AdminUsersItem = Profile

export enum PaymentType {
  Bill = "bill",
  Rebill = "rebill",
}

export enum PaymentStatus {
  Success = 0,
  Fail = 1,
  Pending = 2,
}

export enum PaymentVariant {
  CloudPayments = "cloudpayments",
  PaySelection = "payselection",
}

export type AdminRebillsQuery = {
  username: string
  stream: string
  status: string
  from: string
  to: string
}

export type RebillDetails = {
  date: string
  amount: string
  type: PaymentType
  is_fail: PaymentStatus
}

export type AdminRebillsItem = {
  username: string
  stream: string
  status: UserStatus
  interval: number
  pay: PaymentVariant
  amount: string
  start_rebill_date: string
  next_pay_date: string | null
  details: RebillDetails[]
}

export type SelectedRebill = {
  username: string
  details: Array<RebillDetails>
}

export type AdminStreamsItem = {
  id: string
  name: string
  code: string
  createdAt?: string
}

export type AdminPaymentsItem = {
  username: string
  amount: string
  transactionId: string
  bank: string
  stream: string
  createdAt: string
}

export type AdminLeadsItem = {
  username: string
  stream: string
  createdAt: string
}

export enum PayoutStatus {
  New = "new",
  Paid = "paid",
}

export type AdminPayoutsItem = {
  id: string
  amount: string
  status: PayoutStatus
  createdAt: string
}

export type AdminPayoutsTotal = {
  amount: string
}

export type AdminPaymentsTotal = {
  amount: string
}

export type AdminProfitTotal = {
  amount: string
}

export type AdminLeadsTotal = {
  leads: number
}

export type AdminPaymentsQuery = {
  stream: string
  from: string
  to: string
}

export type AdminProfitQuery = {
  from: string
  to: string
}

export type AdminPayoutsQuery = {
  date: string
}

export type AdminStatsQuery = {
  stream: string
  from: string
  to: string
}

export type AdminFunFactsQuery = {
  stream: string
  from: string
  to: string
}

export type AdminLeadsQuery = {
  stream: string
  from: string
  to: string
}

export type AdminStatsData = {
  clicks: number
  regs: number
  bills: number
  rebills: string
  rebillsCount: number
  amount: string
  active: number
  unsubscribed: number
  epc: string
  cr: number
  ltv: string
  lite: number
}

export type AdminFunFactsData = {
  bills: number
  billsDate: string
  rebills: string
  rebillsDate: string
  rebillsCount: number
  amount: string
  amountDate: string
  ltv: string
  ltvDate: string
  profit: string
  profitDate: string
}

export type AdminUsersQuery = {
  username: string
}

export type AdminState = {
  isUsersLoading: boolean
  isStreamsListLoading: boolean
  isRebillsLoading: boolean
  users: Array<AdminUsersItem>
  streams: Array<AdminStreamsItem>
  rebills: Array<AdminRebillsItem>
  selectedRebill: SelectedRebill | null
}
