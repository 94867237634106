type Item = {
  header: string
  content: string
}

export const faqItems: Array<Item> = [
  {
    header: "Мне не пришли доступы в личный кабинет",
    content:
      'Если Вы не получили письмо с подтверждением подписки, содержащее ссылку, логин и пароль, Вам нужно: Проверьте папку "Спам" или "Нежелательная почта". Иногда автоматически отправляемые письма могут попасть туда. Убедитесь, что Вы указали правильный адрес электронной почты при оформлении подписки. Если вы все еще не получили письмо, пожалуйста, свяжитесь с нами снова, указав полные детали вашей подписки (имя пользователя, адрес электронной почты, дата подписки, данные карты, с которой было произведено списание).',
  },
  {
    header: "Вижу проблему с отображением материалов в личном кабинете",
    content:
      "Обновите страницу в браузере. Если это не помогает, очистите кэш и куки в настройках вашего браузера и попробуйте еще раз. Если возможно, попробуйте войти в личный кабинет с другого устройства или браузера. Если проблема все еще не решена, пожалуйста, предоставьте нам больше информации о проблеме. Скриншоты и информация о браузере и устройстве, с которого вы пытаетесь войти, могут быть очень полезны для наших специалистов.",
  },
  {
    header: "Не получается войти в личный кабинет",
    content:
      'Проверьте, не активирован ли на вашем устройстве "Caps Lock" или режим ввода на другом языке. Логин и пароль чувствительны к регистру и должны быть введены точно так, как вы их получили. Если после проверки вы все равно не можете войти, попробуйте сбросить пароль. Для этого перейдите на наш сайт и нажмите "Забыли пароль?" или перейдите напрямую по ссылке на страницу сброса пароля добавив /reset-password Следуйте инструкциям на странице, чтобы сбросить пароль. Вы должны получить письмо с дальнейшими инструкциями на указанную при регистрации почту. Если вы проделали все эти шаги и проблема все еще не решена, пожалуйста, свяжитесь с нами снова, и мы обязательно поможем вам.',
  },
  {
    header: "Не сохранился доступ на оплаченный период после отключения подписки",
    content:
      "При отмене подписки доступ к нашему сервису должен сохраняться до конца уже оплаченного периода. Выйдите из своего аккаунта и войдите снова. Иногда это может помочь обновить статус вашей подписки на наших серверах. Если Вы все еще не можете получить доступ, пожалуйста, предоставьте нам подробную информацию о Вашей подписке (имя пользователя, адрес электронной почты, данные карты, с которой были произведены списания), чтобы мы могли более подробно изучить Вашу ситуацию.",
  },
  {
    header: "При переходе на сайт или личный кабинет браузер предупреждает об угрозе и недостоверности сайта",
    content:
      "Сообщения о небезопасности сайта могут появляться по разным причинам, и это не обязательно означает, что сайт является мошенническим. Шаги для решения проблемы: Обновите браузер до последней версии. Устаревшие версии браузера могут не полностью поддерживать новейшие стандарты безопасности, что может привести к появлению таких предупреждений. Проверьте, что у вас установлены последние обновления операционной системы. Попробуйте открыть сайт в другом браузере или на другом устройстве. Если проблема все еще сохраняется, пожалуйста, предоставьте нам скриншот сообщения об ошибке и информацию о браузере и операционной системе, которые вы используете. Это поможет нам быстрее разобраться в ситуации и предоставить вам нужную помощь.",
  },
  {
    header: "Применения и рекомендации по плану питания",
    content:
      "Мы настоятельно рекомендуем проконсультироваться с вашим врачом или квалифицированным диетологом перед тем, как применять любое новое питание или режим диеты, включая наше меню питания. Это связано с тем, что у каждого человека есть индивидуальные потребности в питательных веществах, основанные на различных факторах, таких как возраст, пол, уровень активности, состояние здоровья и многое другое. Некоторые продукты или блюда могут не подходить вам из-за аллергий, индивидуальной непереносимости или особых медицинских состояний.",
  },
  {
    header: "Что я получу при покупке курса",
    content:
      "Оформляя подписку вы получаете доступ к платформе, личный кабинет, информационные материалы, меню со способом приготовления, а также раздел с вашими личными результатами.",
  },
  {
    header: "Правовые документы проекта",
    content:
      "Чтобы ознакомиться с документами, перейдите в самый низ главной страницы сайта. Здесь вы найдете все текущие документы, которые регулируют использование нашего сервиса. Оферта, которая включает условия использования, политику конфиденциальности, тарифы с подробной информацией о стоимости/продолжительности/особенностях подписки, и другие важные условия, также находится в этом разделе.",
  },
]
