import cn from "classnames"

import { Card } from "primereact/card"
import { Divider } from "primereact/divider"
import { Skeleton } from "primereact/skeleton"

import style from "../style.module.scss"

import type { FC } from "react"
import type { AdminStatsData } from "state/types/admin"

type Props = {
  isLoading: boolean
  data: AdminStatsData
}

export const Content: FC<Props> = ({ isLoading, data }) => {
  return (
    <Card className={style.content}>
      {isLoading ? (
        <div className={style.skeleton}>
          {Array.from({ length: 16 }).map((_, index) => (
            <Skeleton width="100%" height="72px" borderRadius="16px" key={index.toString()}></Skeleton>
          ))}
        </div>
      ) : (
        <>
          <div className={style.contentItem}>
            <h3>Трафик</h3>
            <div className={cn(style.list, style.traffic)}>
              <div>
                <span>Клики</span>
                <b>{data.clicks}</b>
              </div>
              <div>
                <span>Реги</span>
                <b>{data.regs}</b>
              </div>
            </div>
          </div>

          <Divider />

          <div className={style.contentItem}>
            <h3>Действия</h3>
            <div className={cn(style.list, style.actions)}>
              <div>
                <span>Билл</span>
                <b>{data.bills}</b>
              </div>
              <div>
                <span>Ребилл</span>
                <b>
                  {data.rebillsCount}{" "}
                  {!!Number(data.rebills) && <span className={style.rebills}>({data.rebills}₽)</span>}
                </b>
              </div>
              <div>
                <span>Активных</span>
                <b>
                  {data.active} {!!Number(data.lite) && <span className={style.rebills}>({data.lite} лайт)</span>}
                </b>
              </div>
              <div>
                <span>Отписки</span>
                <b>{data.unsubscribed}</b>
              </div>
            </div>
          </div>

          <Divider />

          <div className={style.contentItem}>
            <h3>Коэффициенты</h3>
            <div className={cn(style.list, style.kefs)}>
              <div>
                <span>EPC, ₽</span>
                <b>{data.epc}</b>
              </div>
              <div>
                <span>CR, %</span>
                <b>{data.cr ? data.cr.toFixed(2) : 0}</b>
              </div>
              <div>
                <span>LTV, ₽</span>
                <b>{data.ltv}</b>
              </div>
            </div>
          </div>

          <Divider />

          <div className={style.contentItem}>
            <h3>Финансы</h3>
            <div className={style.list}>
              <div>
                <span>Итого, ₽</span>
                <b>{data.amount}</b>
              </div>
            </div>
          </div>
        </>
      )}
    </Card>
  )
}
