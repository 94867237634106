import React, { useRef, useEffect } from "react"
import { Toast } from "primereact/toast"

import type { FC } from "react"

import style from "./style.module.scss"

type EventDetail = {
  detail?: {
    type: "error" | "success"
    message: string
  }
}

const eventSummary = {
  error: "Ошибка",
  success: "Успешно",
}

export const Notification: FC = () => {
  const toastRef = useRef<Toast | null>(null)

  const listenCustomEvents = () => {
    window.addEventListener("notification", (event: Event & EventDetail) => {
      if (toastRef.current && event.detail) {
        toastRef.current.show({
          severity: event.detail.type,
          summary: eventSummary[event.detail.type],
          detail: event.detail.message,
          life: 3000,
        })
      }
    })
  }

  useEffect(() => {
    listenCustomEvents()
  }, [])

  return <Toast ref={toastRef} className={style.notification} />
}
