import { UserStatus } from "state/types/app"

export const rebillsStatuses = [
  {
    value: "unset",
    label: "Все статусы",
  },
  {
    value: UserStatus.Active,
    label: "Активная",
  },
  {
    value: UserStatus.Lite,
    label: "Лайт",
  },
  {
    value: UserStatus.Unsubscribed,
    label: "Отменена",
  },
]
