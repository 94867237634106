import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "8"

export const Course6: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Снижение Сахара для Быстрого Похудения</h1>
      </div>

      <Ration kkal={600} portion="4 - 5" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти рецепты помогут снизить потребление сахара и обеспечат организм необходимыми питательными веществами,
        способствуя похудению и улучшению общего состояния здоровья.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Влияние сахара на вес</h3>
          <p>
            Сахар может оказывать значительное влияние на вес и общее состояние здоровья. Продукты с высоким содержанием
            сахара часто богаты калориями, но мало содержат питательных веществ, что может привести к увеличению веса.
            Сахар способствует накоплению жира, особенно в области живота, и может вызывать колебания уровня глюкозы в
            крови, что приводит к перепадам энергии и чувству голода. Эти колебания могут привести к чрезмерному
            потреблению пищи и выбору нездоровых закусок.
          </p>
          <p>
            Избыточное потребление сахара также связано с рядом заболеваний, включая ожирение, диабет 2-го типа,
            сердечно-сосудистые заболевания и даже некоторые виды рака. Снижение потребления сахара не только помогает в
            похудении, но и улучшает общее состояние здоровья, уровень энергии и самочувствие.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Безсахарные завтраки, обеды и ужины</h3>
          <p>
            Выбор блюд без добавленного сахара для завтраков, обедов и ужинов помогает контролировать потребление
            калорий и поддерживать стабильный уровень сахара в крови. Это способствует похудению и улучшению общего
            состояния здоровья. Вместо сахара можно использовать натуральные подсластители, такие как стевия или мед, но
            их также следует употреблять умеренно. Важно включать в рацион больше цельных продуктов, таких как овощи,
            фрукты, белки и цельнозерновые, которые обеспечивают организм необходимыми питательными веществами и
            способствуют длительному чувству сытости.
          </p>
          <p>
            Избегание продуктов с "скрытым" сахаром, таких как обработанные снеки, газированные напитки и фаст-фуд,
            также важно для снижения общего потребления сахара. Чтение этикеток на продуктах поможет идентифицировать
            добавленный сахар и сделать более осознанный выбор.
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
