import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "3"

export const Course1: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Введение в питание для похудения</h1>
      </div>

      <Ration kkal={1050} portion="4 - 5" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти рецепты помогут вам сбалансировать рацион и сделать ваш путь к похудению не только эффективным, но и
        вкусным.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Основы: Понимание калорий и макроэлементов</h3>
          <p>
            Правильное питание для похудения начинается с глубокого понимания калорий и макроэлементов. Калории – это
            мера энергии, которую наше тело получает из пищи. Каждый продукт содержит определенное количество калорий, и
            для похудения важно контролировать их общее количество, потребляемое в течение дня. Основная концепция
            заключается в том, что для снижения веса необходимо потреблять меньше калорий, чем тратит организм.
          </p>
          <p>
            Макроэлементы – это белки, жиры и углеводы, каждый из которых играет уникальную роль в питании и
            метаболизме. Белки важны для построения и восстановления тканей, включая мышцы, и могут способствовать
            чувству сытости. Жиры необходимы для поддержания здоровья клеток и гормонального баланса. Углеводы являются
            основным источником энергии для организма, но их избыток может привести к накоплению жировых запасов.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Легкий завтрак, салат на обед, ужин с высоким содержанием белка, здоровые перекусы</h3>
          <p>
            Легкий завтрак, такой как овсянка или йогурт с фруктами, помогает запустить метаболизм и обеспечивает
            энергией на первую половину дня. Салат на обед – это отличный способ употребить необходимые питательные
            вещества без лишних калорий. Включение в салат разнообразных овощей, богатых клетчаткой, и источника белка,
            например, куриной грудки или тунца, сделает обед не только полезным, но и сытным.
          </p>
          <p>
            Ужин с высоким содержанием белка, такой как стейк из тунца или куриная грудка, помогает в восстановлении и
            росте мышечной ткани, особенно если вы занимаетесь физическими упражнениями. Белок также способствует
            длительному чувству сытости, что помогает избежать перекусов поздно вечером.
          </p>
          <p>
            Здоровые перекусы, такие как орехи, свежие фрукты или йогурт, помогают поддерживать уровень энергии и
            метаболизм в течение дня, а также предотвращают переедание во время основных приемов пищи. Важно выбирать
            перекусы, которые содержат сочетание белков, здоровых жиров и углеводов для оптимального питания.
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
