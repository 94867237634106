import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "5"

export const Course3: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Углеводы и Похудение</h1>
      </div>

      <Ration kkal={600} portion="2 - 3" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти рецепты не только вкусные и питательные, но и помогают поддерживать стабильный уровень энергии и
        способствуют похудению благодаря здоровому балансу белков и углеводов.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Выбор правильных углеводов</h3>
          <p>
            Выбор правильных углеводов играет критическую роль в процессе похудения. Важно отдавать предпочтение сложным
            углеводам, которые медленно перевариваются и обеспечивают длительное чувство сытости. Это помогает избежать
            резких скачков уровня сахара в крови, которые могут вызывать чувство голода и приводить к перееданию.
            Сложные углеводы содержат больше питательных веществ и клетчатки, что полезно для пищеварения и общего
            состояния здоровья. Примеры здоровых источников углеводов включают цельнозерновые продукты, такие как
            овсянка, коричневый рис, киноа, а также овощи, бобовые и фрукты.
          </p>
          <p>
            В отличие от сложных углеводов, простые углеводы, содержащиеся в продуктах с высоким содержанием сахара и
            обработанных зернах, быстро усваиваются, вызывая всплеск и последующее падение уровня глюкозы в крови. Это
            не только способствует накоплению жира, но и может привести к усталости и раздражительности.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Омлет с овощами, киноа с овощами, фрукты</h3>
          <p>
            Включение в рацион блюд, богатых сложными углеводами и белками, таких как омлет с овощами и киноа с овощами,
            помогает поддерживать стабильный уровень энергии и способствует похудению. Омлет с овощами не только
            обеспечивает белки, но и включает в себя клетчатку и микроэлементы из овощей. Киноа, будучи одним из
            немногих растительных продуктов, содержащих все девять незаменимых аминокислот, является отличным источником
            белка и сложных углеводов. Фрукты, хотя и содержат простые углеводы, также богаты клетчаткой, витаминами и
            минералами, что делает их полезным дополнением к рациону.
          </p>
          <p>
            Включение этих продуктов в ежедневный рацион помогает обеспечить сбалансированное питание, поддерживает
            длительное чувство сытости и способствует здоровому похудению.
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
