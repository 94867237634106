import { useEffect, useContext, useState } from "react"
import cn from "classnames"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import dayjs from "dayjs"

import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"

import { RESET_PASSWORD_ROUTE, MATERIALS_ROUTE } from "routes"
import config from "config"
import API from "lib/api"
import { removeTokens, setToken } from "lib/helper"
import { useAppDispatch } from "hooks/dispatch"
import { setAppLoading } from "state/slices/app"

import style from "./style.module.scss"

import type { FC, Context } from "react"
import type { AuthFormData } from "state/types/auth"

type Props = {
  isLogin: boolean
  loginContext: Context<{ onLoginStateChange: (value: boolean) => void }>
}

const defaultFormData: AuthFormData = {
  username: "",
  password: "",
}

const Auth: FC<Props> = ({ isLogin, loginContext }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { onLoginStateChange } = useContext(loginContext)

  const [formData, setFormData] = useState<AuthFormData>(defaultFormData)
  const [isLoading, setLoading] = useState(false)

  const isPasswordShort = formData.password && formData.password.length < 8
  const isDisabled = !formData.username || !formData.password || !!isPasswordShort

  const onSubmit = () => {
    setLoading(true)

    API.post(config.paths.auth.signin, formData)
      .then(({ data }) => {
        if (data.refreshToken && data.accessToken) {
          const { accessToken, refreshToken } = data

          setToken("access_token", accessToken, {
            expires: dayjs().add(7, "day").toDate(),
          })
          setToken("refresh_token", refreshToken, {
            expires: dayjs().add(7, "day").toDate(),
          })
          onLoginStateChange(true)

          dispatch(setAppLoading(true))
          navigate(MATERIALS_ROUTE, { replace: true })
        }
      })
      .catch(() => {
        setLoading(false)
        removeTokens()
      })
  }

  const footer = (
    <div className={style.buttons}>
      <Link to={RESET_PASSWORD_ROUTE}>Забыли пароль?</Link>

      <Button label="Войти" onClick={onSubmit} disabled={isDisabled} loading={isLoading} />
    </div>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    isLogin && navigate(MATERIALS_ROUTE)
  }, [isLogin])

  if (isLogin) {
    return null
  }

  return (
    <div className={style.wrapper}>
      <div className="container">
        <Card className={style.form} title="Вход в личный кабинет" footer={footer}>
          <span className="p-float-label">
            <InputText
              id="username"
              value={formData.username}
              onChange={e => setFormData({ ...formData, username: e.target.value })}
              className={style.input}
              maxLength={70}
            />
            <label htmlFor="username">E-mail</label>
          </span>
          <span className="p-float-label">
            <InputText
              id="password"
              value={formData.password}
              onChange={e => setFormData({ ...formData, password: e.target.value })}
              className={cn(style.input, { "p-invalid": isPasswordShort })}
              type="password"
              maxLength={30}
            />
            <label htmlFor="password">Введите пароль</label>
          </span>
        </Card>
      </div>
    </div>
  )
}

export default Auth
