export const MATERIALS_ROUTE = "/"
export const AUTH_ROUTE = "/auth"
export const RESET_PASSWORD_ROUTE = "/reset-password"
export const NEW_PASSWORD_ROUTE = "/new-password"
export const FAQ_ROUTE = "/faq"
export const CABINET_ROUTE = "/cabinet"
export const CANCEL_SUB_ROUTE = "/cancel"
export const CANCEL_LITE_ROUTE = "/cancel-lite"
export const REFUND_ROUTE = "/refund"
export const SETTINGS_ROUTE = "/settings"

export const TOPIC_ROUTE = "/courses/:topicId"
export const getTopicRoute = (topicId: number | null) => `/courses/${topicId}`

export const ADMIN_USERS_ROUTE = "/admin/users"
export const ADMIN_REBILLS_ROUTE = "/admin/rebills"
export const ADMIN_PAYMENTS_ROUTE = "/admin/payments"
export const ADMIN_STREAMS_ROUTE = "/admin/streams"
export const ADMIN_STATS_ROUTE = "/admin/stats"
export const ADMIN_PAYOUTS_ROUTE = "/admin/payouts"
export const ADMIN_LEADS_ROUTE = "/admin/leads"
export const ADMIN_PROFIT_ROUTE = "/admin/profit"
export const ADMIN_FUN_ROUTE = "/admin/fun"
