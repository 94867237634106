import cn from "classnames"

import { Card } from "primereact/card"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"

import { PayoutStatus } from "state/types/admin"

import style from "../style.module.scss"

import type { FC } from "react"
import type { AdminPayoutsItem } from "state/types/admin"

type Props = {
  items: Array<AdminPayoutsItem>
  isLoading: boolean
  onApprove: (id: string) => void
}

export const Table: FC<Props> = ({ items, isLoading, onApprove }) => {
  const bodyTemplateAmount = (item: AdminPayoutsItem) => {
    return `${item.amount} ₽`
  }

  const getStatus = (status: PayoutStatus) => {
    switch (status) {
      case PayoutStatus.New:
        return <div className={style.statusNew}>Новая</div>
      case PayoutStatus.Paid:
        return <div className={style.statusPaid}>Выплачено</div>
      default:
        return "-"
    }
  }

  const bodyTemplateStatus = (item: AdminPayoutsItem) => {
    return getStatus(item.status)
  }

  const bodyTemplateActions = (item: AdminPayoutsItem) => {
    return item.status === PayoutStatus.New ? (
      <Button label="Получено" link onClick={() => onApprove(item.id)} size="small" />
    ) : (
      <div>-</div>
    )
  }

  return (
    <Card className="mt-6">
      <DataTable value={items} loading={isLoading} emptyMessage="Выводов не найдено">
        <Column field="createdAt" header="Время" align="left"></Column>
        <Column field="status" header="Статус заявки" body={bodyTemplateStatus} align="right"></Column>
        <Column field="amount" header="Сумма" align="right" className="amount" body={bodyTemplateAmount}></Column>
        <Column header="Действия" align="center" body={bodyTemplateActions}></Column>
      </DataTable>
    </Card>
  )
}
