import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import API from "lib/api"
import config from "config"
import { buildParams } from "lib/helper"

import { Filters } from "./Filters"
import { Table } from "./Table"

import type { FC } from "react"
import type { AdminPaymentsItem, AdminPaymentsQuery } from "state/types/admin"

const AdminPayments: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [isLoading, setLoading] = useState(false)
  const [payments, setPayments] = useState<Array<AdminPaymentsItem>>([])

  const filters: AdminPaymentsQuery = {
    stream: searchParams.get("stream") || "unset",
    from: searchParams.get("from") || "",
    to: searchParams.get("to") || "",
  }

  const setFilters = (filters: AdminPaymentsQuery) => {
    setSearchParams(filters)
  }

  const getData = (isLive?: boolean) => {
    if (!isLive) {
      setLoading(true)
    }

    const query = {
      ...filters,
      from: filters.from || "",
      to: filters.to || "",
    }

    const url = `${config.paths.admin.payments}?${buildParams(query)}`

    API.get(url)
      .then(({ data }) => {
        setPayments(data.items)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  return (
    <>
      <Filters filters={filters} setFilters={setFilters} />

      <Table items={payments} isLoading={isLoading} />
    </>
  )
}

export default AdminPayments
