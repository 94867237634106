type Item = {
  header: string
  content: string
}

export const faqItems: Array<Item> = [
  {
    header: "Что это за место?",
    content: "Это страница по управлению подписками, а также мы ответим на самые распространенные вопросы",
  },
  {
    header: "Почему оплата произошла без моего ведома?",
    content:
      "На сайте Вы оформили пробную подписку, которая продлевается автоматически на несколько дней или неделю с помощью рекуррентного платежа, с которым вы согласились при оплате.",
  },
  {
    header: "С помощью чего происходит списание?",
    content:
      "Мы используем рекуррентные платежи, также известные как рекарринговые платежи или «автоплатежи» — это возможность выполнять регулярные списания денег с банковской карты покупателя или электронного кошелька без повторного ввода реквизитов карты и без участия плательщика для инициации очередного платежа. Рекуррентные платежи используют так же такие компании как Яндекс, Google, Netflix, IVI, Amazon и многие другие.",
  },
]
