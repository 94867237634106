import { createSlice } from "@reduxjs/toolkit"

import { AccessLevel, Role, UserStatus } from "state/types/app"

import type { AppState, Profile } from "state/types/app"
import type { PayloadAction } from "@reduxjs/toolkit"

const initialState: AppState = {
  isLoading: false,

  profile: {
    id: "",
    username: "",
    uuid: "",
    role: Role.User,
    daysActive: 0,
    daysRemaining: 0,
    age: "",
    isActive: false,
    activeTill: "",
    accessLevel: AccessLevel.Zero,
    firstSixCardNumbers: "",
    lastFourCardNumbers: "",
    unsubscribedAt: null,
    unsubscribedLiteAt: null,
    status: UserStatus.Active,
  },
}

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<Profile>) => {
      state.profile = action.payload
    },

    clearProfile: state => {
      state.profile = initialState.profile
    },

    setUserAge: (state, action: PayloadAction<string>) => {
      state.profile = {
        ...state.profile,
        age: action.payload,
      }
    },

    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
})

export const { setProfile, clearProfile, setUserAge, setAppLoading } = appSlice.actions

export default appSlice.reducer
