import cn from "classnames"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { Dialog } from "primereact/dialog"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import { useAppSelector, useAppDispatch } from "hooks/dispatch"
import { getRebills } from "state/dispatchers/admin"
import { clearRebills, setSelectedRebill } from "state/slices/admin"

import { Filters } from "./Filters"
import { Table } from "./Table"

import style from "./style.module.scss"

import type { FC } from "react"
import { PaymentType, type AdminRebillsQuery, type RebillDetails, PaymentStatus } from "state/types/admin"

const AdminRebills: FC = () => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const { rebills, isRebillsLoading, selectedRebill } = useAppSelector(state => state.admin)

  const filters: AdminRebillsQuery = {
    username: searchParams.get("username") || "",
    stream: searchParams.get("stream") || "unset",
    status: searchParams.get("status") || "unset",
    from: searchParams.get("from") || "",
    to: searchParams.get("to") || "",
  }

  const setFilters = (filters: AdminRebillsQuery) => {
    setSearchParams(filters)
  }

  const getData = () => {
    const query = {
      ...filters,
      from: filters.from || "",
      to: filters.to || "",
    }

    dispatch(getRebills(query))
  }

  const handleHideDialog = () => {
    dispatch(setSelectedRebill(null))
  }

  const getTypeColumn = (item: RebillDetails) => {
    if (item.type === PaymentType.Bill) {
      return "Билл"
    }

    return "Ребилл"
  }

  const getStatusColumn = (item: RebillDetails) => {
    if (item.is_fail === PaymentStatus.Success) {
      return <span className={cn(style.status, style.active)}>Успешно</span>
    }

    if (item.is_fail === PaymentStatus.Pending) {
      return <span className={cn(style.status, style.pending)}>Ожидается</span>
    }

    return <span className={cn(style.status, style.declined)}>Отклонено</span>
  }

  const getAmountColumn = (item: RebillDetails) => {
    return <b>{item.amount}</b>
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  useEffect(() => {
    return () => {
      dispatch(clearRebills())
    }
  }, [])

  return (
    <>
      <Dialog
        header={selectedRebill?.username || ""}
        visible={!!selectedRebill?.username}
        style={{ width: "calc(100% - 32px)", maxWidth: "860px" }}
        onHide={handleHideDialog}
        baseZIndex={9999}
        blockScroll
        draggable={false}
        dismissableMask
      >
        <DataTable value={selectedRebill?.details} emptyMessage="Нет платежей">
          <Column field="type" header="Тип" body={getTypeColumn}></Column>
          <Column field="is_fail" header="Статус" body={getStatusColumn}></Column>
          <Column field="date" header="Дата"></Column>
          <Column field="amount" header="Сумма" align="right" body={getAmountColumn}></Column>
        </DataTable>
      </Dialog>

      <Filters filters={filters} setFilters={setFilters} />

      <Table items={rebills} isLoading={isRebillsLoading} />
    </>
  )
}

export default AdminRebills
