import React, { useState, useEffect } from "react"

import { Card } from "primereact/card"

import { Weight, WEIGHT_HISTORY } from "./Weight"
import { WeightChart } from "./WeightChart"
import { Water, GLASSES_HISTORY, GLASSES, defaultGlasses } from "./Water"
import { WaterBar } from "./WaterBar"
import { WaterQuote } from "./WaterQuote"

import type { FC } from "react"
import type { WeightHistoryItem, GlassItem } from "./types"

import style from "./style.module.scss"

const Cabinet: FC = () => {
  const localWeightHistory: Array<WeightHistoryItem> = JSON.parse(localStorage.getItem(WEIGHT_HISTORY) || "[]")
  const localGlassesHistory: Array<GlassItem> = JSON.parse(localStorage.getItem(GLASSES_HISTORY) || "[]")
  const localGlasses: GlassItem = JSON.parse(localStorage.getItem(GLASSES) || "{}")

  const [weightHistory, setWeightHistory] = useState<Array<WeightHistoryItem>>(localWeightHistory)
  const [glassesHistory, setGlassesHistory] = useState<Array<GlassItem>>(localGlassesHistory)
  const [glassesData, setGlassesData] = useState<GlassItem>({
    date: localGlasses.date || defaultGlasses.date,
    glasses: localGlasses.glasses || defaultGlasses.glasses,
  })

  useEffect(() => {
    localStorage.setItem("weightHistory", JSON.stringify(weightHistory))
  }, [weightHistory])

  return (
    <div className={style.wrapper}>
      <div className="container">
        <Card title="Ваши параметры">
          <Weight setWeightHistory={setWeightHistory} />
          <WeightChart weightHistory={weightHistory} />
          <Water glassesData={glassesData} setGlassesData={setGlassesData} setGlassesHistory={setGlassesHistory} />
          <WaterBar glassesHistory={glassesHistory} />
          <WaterQuote glasses={glassesData.glasses} />
        </Card>
      </div>
    </div>
  )
}

export default Cabinet
