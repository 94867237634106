import course1Ration1 from "images/course1/ration1.jpg"
import course1Ration2 from "images/course1/ration2.jpg"
import course1Ration3 from "images/course1/ration3.jpg"

import course2Ration1 from "images/course2/ration1.jpg"
import course2Ration2 from "images/course2/ration2.jpg"

import course3Ration1 from "images/course3/ration1.jpg"
import course3Ration2 from "images/course3/ration2.jpg"

import course4Ration1 from "images/course4/ration1.jpg"
import course4Ration2 from "images/course4/ration2.jpg"
import course4Ration3 from "images/course4/ration3.jpg"

import course5Ration1 from "images/course5/ration1.jpg"
import course5Ration2 from "images/course5/ration2.jpg"

import course6Ration1 from "images/course6/ration1.jpg"
import course6Ration2 from "images/course6/ration2.jpg"

import course7Ration1 from "images/course7/ration1.jpg"
import course7Ration2 from "images/course7/ration2.jpg"
import course7Ration3 from "images/course7/ration3.jpg"

import course8Ration1 from "images/course8/ration1.jpeg"
import course8Ration2 from "images/course8/ration2.jpg"

import course9Ration1 from "images/course9/ration1.jpg"
import course9Ration2 from "images/course9/ration2.jpg"

import course10Ration1 from "images/course10/ration1.jpg"
import course10Ration2 from "images/course10/ration2.jpg"

import course11Ration1 from "images/course11/ration1.jpg"
import course11Ration2 from "images/course11/ration2.jpg"

import course12Ration1 from "images/course12/ration1.jpg"
import course12Ration2 from "images/course12/ration2.jpg"

import course13Ration1 from "images/course13/ration1.jpg"
import course13Ration2 from "images/course13/ration2.jpg"

import course14Ration1 from "images/course14/ration1.jpg"
import course14Ration2 from "images/course14/ration2.jpg"

import course15Ration1 from "images/course15/ration1.jpg"
import course15Ration2 from "images/course15/ration2.jpg"
import course15Ration3 from "images/course15/ration3.jpg"
import course15Ration4 from "images/course15/ration4.jpg"

import course16Ration1 from "images/course16/ration1.jpg"
import course16Ration2 from "images/course16/ration2.jpg"

import type { RationsList } from "./types"

export const rationsData: RationsList = {
  3: [
    {
      name: "Овсянка с ягодами",
      mealTime: "Завтрак",
      imgPath: course1Ration1,
      kkal: 300,
      protein: 10,
      fats: 5,
      carbs: 55,
      ingredients: [
        {
          name: "Овсяные хлопья",
          count: "50 гр.",
        },
        {
          name: "Обезжиренное молоко или вода",
          count: "200 мл.",
        },
        {
          name: "Свежие ягоды",
          count: "100 гр.",
        },
        {
          name: "Мед",
          count: "1 ч.л.",
        },
      ],
      cooking: "Варите овсяные хлопья на молоке или воде до готовности. Добавьте свежие ягоды и мед перед подачей.",
    },
    {
      name: "Куриный салат",
      mealTime: "Обед",
      imgPath: course1Ration2,
      kkal: 350,
      protein: 30,
      fats: 15,
      carbs: 20,
      ingredients: [
        {
          name: "Куриная грудка",
          count: "150 гр.",
        },
        {
          name: "Зеленый салат",
        },
        {
          name: "Помидор",
        },
        {
          name: "Огурец",
        },
        {
          name: "Оливковое масло",
        },
        {
          name: "Лимонны сок",
        },
      ],
      cooking:
        "Отварите куриную грудку и нарежьте её. Смешайте нарезанные овощи, добавьте курицу. Заправьте салат оливковым маслом и лимонным соком.",
    },
    {
      name: "Стейк из тунца",
      mealTime: "Ужин",
      imgPath: course1Ration3,
      kkal: 400,
      protein: 40,
      fats: 20,
      carbs: 10,
      ingredients: [
        {
          name: "Филе тунца",
          count: "200 гр.",
        },
        {
          name: "Оливковое масло",
          count: "1 ст.л.",
        },
        {
          name: "Соль, перец",
          count: "по вкусу",
        },
      ],
      cooking:
        "Натрите филе тунца солью и перцем. Обжарьте на сковороде с оливковым маслом с каждой стороны по 3-4 минуты.",
    },
  ],

  4: [
    {
      name: "Смузи из ягод и протеина",
      mealTime: "Завтрак/Перекус",
      imgPath: course2Ration1,
      kkal: 250,
      protein: 20,
      fats: 2,
      carbs: 30,
      ingredients: [
        {
          name: "Ванильный протеиновый порошок",
          count: "1 мер.л.",
        },
        {
          name: "Замороженные ягоды",
          count: "1 чашка",
        },
        {
          name: "Обезжиренное молоко",
          count: "1 стакан",
        },
        {
          name: "Мед",
          count: "1 ст.л.",
        },
      ],
      cooking: "Все ингредиенты поместите в блендер и взбейте до однородной консистенции.",
    },
    {
      name: "Салат с тунцом и авокадо",
      mealTime: "Ужин",
      imgPath: course2Ration2,
      kkal: 400,
      protein: 30,
      fats: 20,
      carbs: 15,
      ingredients: [
        {
          name: "Консервированный тунец",
          count: "1 банка",
        },
        {
          name: "Спелый авокадо",
          count: "1 шт.",
        },
        {
          name: "Свежий салат (латук, руккола)",
        },
        {
          name: "Помидоры черри",
        },
        {
          name: "Оливковое масло",
        },
        {
          name: "Лимонный сок",
        },
        {
          name: "Соль, перец",
          count: "по вкусу",
        },
      ],
      cooking:
        "Тунец откиньте на дуршлаг, чтобы стекла лишняя жидкость. Авокадо нарежьте кубиками. Смешайте тунец, авокадо, помидоры черри и зеленый салат. Заправьте салат оливковым маслом, лимонным соком, солью и перцем по вкусу.",
    },
  ],

  5: [
    {
      name: "Омлет с шпинатом и помидорами",
      mealTime: "Завтрак",
      imgPath: course3Ration1,
      kkal: 250,
      protein: 18,
      fats: 15,
      carbs: 8,
      ingredients: [
        {
          name: "Яйца",
          count: "2 шт.",
        },
        {
          name: "Помидор",
          count: "1 шт.",
        },
        {
          name: "Оливковое масло",
          count: "1 ст.л.",
        },
        {
          name: "Соль, перец",
          count: "по вкусу",
        },
      ],
      cooking:
        "Взбейте яйца в миске. На сковороде на среднем огне разогрейте оливковое масло, добавьте шпинат и помидоры, затем залейте взбитые яйца. Готовьте до желаемой степени готовности, приправьте солью и перцем.",
    },
    {
      name: "Киноа с брокколи и морковью",
      mealTime: "Ужин/Перекус",
      imgPath: course3Ration2,
      kkal: 350,
      protein: 12,
      fats: 5,
      carbs: 60,
      ingredients: [
        {
          name: "Киноа",
          count: "1 чашка",
        },
        {
          name: "Вода",
          count: "2 чашки",
        },
        {
          name: "Брокколи",
          count: "1 чашка",
        },
        {
          name: "Морковь",
          count: "1 шт.",
        },
        {
          name: "Оливковое масло",
          count: "1 ст.л.",
        },
        {
          name: "Соль, перец",
          count: "по вкусу",
        },
      ],
      cooking:
        "Промойте киноа под проточной водой. В кастрюле доведите воду до кипения, добавьте киноа, уменьшите огонь и варите под крышкой 15 минут. Отдельно на пару приготовьте брокколи и нарезанную морковь до мягкости. Смешайте овощи с готовой киноа, добавьте оливковое масло, соль и перец по вкусу.",
    },
  ],

  6: [
    {
      name: "Тост с авокадо",
      mealTime: "Завтрак",
      imgPath: course4Ration1,
      kkal: 300,
      protein: 5,
      fats: 20,
      carbs: 25,
      ingredients: [
        {
          name: "Цельнозерновой хлеб",
          count: "1 кусок",
        },
        {
          name: "Спелый авокадо",
          count: "1/2 шт.",
        },
        {
          name: "Лимонный сок",
        },
        {
          name: "Соль, перец",
          count: "по вкусу",
        },
      ],
      cooking:
        "Поджарьте хлеб до золотистой корочки. Разомните авокадо вилкой, добавьте соль, перец и несколько капель лимонного сока. Намажьте авокадо на тост.",
    },
    {
      name: "Греческий салат",
      mealTime: "Ужин/Перекус",
      imgPath: course4Ration2,
      kkal: 200,
      protein: 4,
      fats: 15,
      carbs: 10,
      ingredients: [
        {
          name: "Свежий огурец",
          count: "1 шт.",
        },
        {
          name: "Помидоры",
          count: "2 шт.",
        },
        {
          name: "Луковица",
          count: "1/2 шт.",
        },
        {
          name: "Морковь",
          count: "1 шт.",
        },
        {
          name: "Фета",
          count: "50 гр.",
        },
        {
          name: "Оливковое масло",
          count: "1 ст.л.",
        },
        {
          name: "Соль",
          count: "по вкусу",
        },
      ],
      cooking:
        "Нарежьте огурец, помидоры и лук. Смешайте овощи, добавьте измельченную фету и оливки. Заправьте салат оливковым маслом, солью и перцем.",
    },
    {
      name: "Запеченный лосось",
      mealTime: "Обед/Ужин",
      imgPath: course4Ration3,
      kkal: 400,
      protein: 35,
      fats: 25,
      carbs: 0,
      ingredients: [
        {
          name: "Филе лосося",
          count: "200 гр.",
        },
        {
          name: "Лимон",
          count: "1 шт.",
        },
        {
          name: "Оливковое масло",
          count: "1 ст.л.",
        },
        {
          name: "Соль, перец",
          count: "по вкусу",
        },
      ],
      cooking:
        "Предварительно разогрейте духовку до 180°C. Филе лосося посолите, поперчите и сбрызните лимонным соком. Смажьте рыбу оливковым маслом и запекайте в духовке около 20 минут.",
    },
  ],

  7: [
    {
      name: "Инфузированная вода с огурцом и лимоном",
      mealTime: "Завтрак/Перекус",
      imgPath: course5Ration1,
      kkal: 4,
      protein: 0,
      fats: 0,
      carbs: 1,
      ingredients: [
        {
          name: "Вода",
          count: "1 л.",
        },
        {
          name: "Огурец",
          count: "1/2 шт.",
        },
        {
          name: "Лимон",
          count: "1 шт.",
        },
      ],
      cooking:
        "Нарежьте огурец и лимон тонкими ломтиками. Добавьте их в кувшин с водой. Оставьте инфузироваться в холодильнике на несколько часов или на ночь для насыщения вкуса.",
    },
    {
      name: "Домашний апельсиновый сок",
      mealTime: "Перекус",
      imgPath: course5Ration2,
      kkal: 110,
      protein: 2,
      fats: 0,
      carbs: 26,
      ingredients: [
        {
          name: "Спелые апельсины",
          count: "3-4 шт.",
        },
      ],
      cooking:
        "Тщательно вымойте апельсины. Разрежьте их пополам и выжмите сок с помощью соковыжималки. Подавайте сок свежим, желательно сразу после приготовления.",
    },
  ],

  8: [
    {
      name: "Яичница с овощами",
      mealTime: "Завтрак",
      imgPath: course6Ration1,
      kkal: 250,
      protein: 15,
      fats: 18,
      carbs: 8,
      ingredients: [
        {
          name: "Яйца",
          count: "2 шт.",
        },
        {
          name: "Красный перец",
          count: "1/2 шт.",
        },
        {
          name: "Зеленый перец",
          count: "1/2 шт.",
        },
        {
          name: "Маленький лук",
          count: "1 шт.",
        },
        {
          name: "Оливковое масло",
          count: "1 ст.л.",
        },
        {
          name: "Соль, перец",
          count: "по вкусу",
        },
      ],
      cooking:
        "Нарежьте овощи. На сковороде разогрейте оливковое масло, добавьте овощи и обжарьте их до мягкости. Взбейте яйца, добавьте их к овощам. Готовьте, помешивая, до желаемой степени готовности яиц. Приправьте солью и перцем.",
    },
    {
      name: "Курица с гриль-овощами",
      mealTime: "Ужин",
      imgPath: course6Ration2,
      kkal: 350,
      protein: 40,
      fats: 15,
      carbs: 10,
      ingredients: [
        {
          name: "Куриная грудка",
          count: "200 гр.",
        },
        {
          name: "Кабачок",
          count: "1 шт.",
        },
        {
          name: "Болгарский перец",
          count: "1 шт.",
        },
        {
          name: "Маленькая луковица",
          count: "1 шт.",
        },
        {
          name: "Оливковое масло",
          count: "1 ст.л.",
        },
        {
          name: "Соль, перец, травы",
          count: "по вкусу",
        },
      ],
      cooking:
        "Куриную грудку посолите и поперчите. На гриле или на сковороде-гриль обжарьте курицу до готовности. Овощи нарежьте, сбрызните оливковым маслом, посолите и поперчите. Обжарьте овощи на гриле или в духовке до мягкости. Подавайте курицу вместе с гриль-овощами.",
    },
  ],

  9: [
    {
      name: "Куриная грудка на гриле с киноа и овощами",
      mealTime: "Обед",
      imgPath: course7Ration1,
      kkal: 350,
      protein: 30,
      fats: 10,
      carbs: 35,
      ingredients: [
        {
          name: "Куриная грудка",
          count: "150 гр.",
        },
        {
          name: "Киноа",
          count: "50 гр.",
        },
        {
          name: "Смесь овощей (брокколи, морковь, красный перец)",
        },
      ],
      cooking:
        "Курицу приправить и обжарить на гриле. Киноа отварить в подсоленной воде. Овощи бланшировать или приготовить на пару. Смешать киноа с овощами, подать с курицей.",
    },
    {
      name: "Салат с тунцом и авокадо",
      mealTime: "Ужин/Перекус",
      imgPath: course7Ration2,
      kkal: 400,
      protein: 25,
      fats: 25,
      carbs: 20,
      ingredients: [
        {
          name: "Тунец",
          count: "1 банка",
        },
        {
          name: "Авокадо",
          count: "1 шт.",
        },
        {
          name: "Зеленый салат",
        },
        {
          name: "Оливковое масло",
          count: "1 ст.л.",
        },
        {
          name: "Лимонный сок",
        },
      ],
      cooking: "Тунец смешать с нарезанным авокадо и зеленым салатом. Заправить оливковым маслом и лимонным соком.",
    },
    {
      name: "Овощной стир-фрай с тофу",
      mealTime: "Перекус",
      imgPath: course7Ration3,
      kkal: 300,
      protein: 18,
      fats: 15,
      carbs: 25,
      ingredients: [
        {
          name: "Тофу",
          count: "100 гр.",
        },
        {
          name: "Cмесь овощей (баклажан, сладкий перец, шпинат)",
        },
        {
          name: "Соевый соус",
        },
        {
          name: "Чеснок",
        },
        {
          name: "Имбирь",
        },
      ],
      cooking:
        "Тофу обжарить до золотистой корочки. Овощи нарезать и обжарить на сильном огне с чесноком и имбирем. Добавить тофу, заправить соевым соусом.",
    },
  ],

  10: [
    {
      name: "Теплый салат с куриной грудкой и киноа",
      mealTime: "Ужин/Перекус",
      imgPath: course8Ration1,
      kkal: 350,
      protein: 30,
      fats: 10,
      carbs: 35,
      ingredients: [
        {
          name: "Куриная грудка",
          count: "100 гр.",
        },
        {
          name: "Киноа",
          count: "50 гр.",
        },
        {
          name: "Смесь зеленых листовых овощей",
        },
        {
          name: "Помидоры черри",
        },
        {
          name: "Огурец",
        },
        {
          name: "Оливковое масло",
        },
        {
          name: "Лимонный сок",
        },
      ],
      cooking:
        "Куриную грудку приправить, запечь в духовке до готовности и нарезать. Киноа отварить в подсоленной воде. Смешать киноа с нарезанными овощами, добавить кусочки курицы. Заправить салат оливковым маслом и лимонным соком.",
    },
    {
      name: "Рыба, запеченная с овощами",
      mealTime: "Обед/Ужин",
      imgPath: course8Ration2,
      kkal: 400,
      protein: 35,
      fats: 20,
      carbs: 15,
      ingredients: [
        {
          name: "Филе белой рыбы (например, треска)",
          count: "200 гр.",
        },
        {
          name: "Брокколи",
        },
        {
          name: "Морковь",
        },
        {
          name: "Сладкий перец",
        },
        {
          name: "Оливковое масло",
          count: "1 ст.л.",
        },
        {
          name: "Специи",
          count: "по вкусу",
        },
      ],
      cooking:
        "Филе рыбы посолить, поперчить и сбрызнуть лимонным соком. Овощи нарезать, смешать с оливковым маслом и специями. Рыбу и овощи выложить на противень и запечь в духовке до готовности.",
    },
  ],

  11: [
    {
      name: "Энергетические батончики",
      mealTime: "Перекус",
      imgPath: course9Ration1,
      kkal: 220,
      protein: 6,
      fats: 11,
      carbs: 26,
      ingredients: [
        {
          name: "Овсяные хлопья",
          count: "1 стакан",
        },
        {
          name: "Смешаныне орехи (нарезанные)",
          count: "1/2 стакана",
        },
        {
          name: "Мед",
          count: "1/4 стакана",
        },
        {
          name: "Арахисовое масло",
          count: "1/4 стакана",
        },
        {
          name: "Темный шоколад",
          count: "1/2 стакана",
        },
        {
          name: "Семена льна",
          count: "1/4 стакана",
        },
      ],
      cooking: [
        "Смешайте овсяные хлопья и орехи в большой миске",
        "В маленькой кастрюле на медленном огне растопите мед с арахисовым маслом",
        "Смешайте медовую смесь с овсяными хлопьями и орехами",
        "Добавьте шоколад и семена льна",
        "Выложите смесь в форму, выстланную пергаментной бумагой, и охладите в холодильнике",
        "Нарежьте охлажденную смесь на батончики",
      ],
    },
    {
      name: "Смузи для восстановления",
      mealTime: "Перекус",
      imgPath: course9Ration2,
      kkal: 350,
      protein: 20,
      fats: 5,
      carbs: 55,
      ingredients: [
        {
          name: "Замороженный банан",
          count: "1 шт.",
        },
        {
          name: "Несладкое миндальное молоко",
          count: "1 стакан",
        },
        {
          name: "Ванильный протеиновый порошок",
          count: "1 мер.л.",
        },
        {
          name: "Арахисовое масло",
          count: "1 ст.л.",
        },
        {
          name: "Мед",
          count: "1 ст.л.",
        },
        {
          name: "Щепотка корицы",
        },
      ],
      cooking: [
        "Поместите все ингредиенты в блендер",
        "Взбивайте до гладкой консистенции",
        "Подавайте сразу же, украсив сверху щепоткой корицы",
      ],
    },
  ],

  12: [
    {
      name: "Шоколадный банановый смузи",
      mealTime: "Перекус",
      imgPath: course10Ration1,
      kkal: 230,
      protein: 6,
      fats: 3,
      carbs: 45,
      ingredients: [
        {
          name: "Спелый банан",
          count: "1 шт.",
        },
        {
          name: "Обезжиренное молоко",
          count: "1 стакан",
        },
        {
          name: "Какао-порошок",
          count: "2 ст.л.",
        },
        {
          name: "Мед",
          count: "1 ч.л.",
        },
        {
          name: "Щепотка корицы",
        },
        {
          name: "Несколько кубиков льда",
        },
      ],
      cooking: [
        "Поместите все ингредиенты в блендер",
        "Взбивайте до гладкой консистенции",
        "Подавайте сразу, украшая сверху щепоткой корицы для аромата",
      ],
      comment:
        "Этот смузи - идеальный выбор для заряда энергией утром или восстановления после тренировки. Бананы обеспечивают естественную сладость и углеводы, какао добавляет антиоксиданты, а молоко обеспечивает необходимый белок.",
    },
    {
      name: "Ореховые снэки",
      mealTime: "Перекус",
      imgPath: course10Ration2,
      kkal: 180,
      protein: 6,
      fats: 15,
      carbs: 7,
      ingredients: [
        {
          name: "Смешанные орехи",
          count: "1 стакан",
        },
        {
          name: "Кленовый сироп",
          count: "2 стакана",
        },
        {
          name: "Ванильный экстракт",
          count: "1 ч.л.",
        },
        {
          name: "Щепотка морской соли",
        },
      ],
      cooking: [
        "Предварительно разогрейте духовку до 180°C",
        "В большой миске смешайте орехи, кленовый сироп, ваниль и морскую соль",
        "Распределите смесь на противне, застеленном пергаментной бумагой",
        "Выпекайте в течение 10-15 минут, пока орехи не станут золотистыми",
        "Охладите перед подачей",
      ],
      comment:
        "Эти ореховые снэки - отличный источник здоровых жиров и белка. Они идеально подходят для перекуса в течение дня, помогая поддерживать энергию и удовлетворять голод.",
    },
  ],

  13: [
    {
      name: "Теплое молоко с медом для сна",
      mealTime: "Ужин",
      imgPath: course11Ration1,
      kkal: 160,
      protein: 8,
      fats: 3,
      carbs: 27,
      ingredients: [
        {
          name: "Молоко",
          count: "1 стакан",
        },
        {
          name: "Мед",
          count: "1 ч.л.",
        },
        {
          name: "Щепотка мускатного ореха или корицы (по желанию)",
        },
      ],
      cooking: [
        "Нагрейте молоко до теплого состояния, но не доводите до кипения",
        "Влейте молоко в чашку и добавьте мед, перемешивая до полного растворения",
        "Посыпьте сверху мускатным орехом или корицей для аромата",
      ],
      comment:
        "Этот напиток идеален для расслабления перед сном. Молоко, богатое триптофаном и кальцием, способствует расслаблению мышц и нервов, а мед слегка повышает уровень инсулина, что улучшает доступность триптофана для мозга.",
    },
    {
      name: "Травяной чай для сна",
      mealTime: "Ужин",
      imgPath: course11Ration2,
      kkal: 5,
      protein: 0,
      fats: 0,
      carbs: 1,
      ingredients: [
        {
          name: "Камомильный или мелиссовый чай",
          count: "1 пакетик",
        },
        {
          name: "Мед (по желанию)",
          count: "1 ч.л.",
        },
        {
          name: "Горячая вода",
          count: "1 стакан",
        },
      ],
      cooking: [
        "Заварите чайный пакетик в горячей воде на 5-10 минут",
        "Удалите чайный пакетик и добавьте мед для сладости",
        "Выпейте за 30 минут до сна",
      ],
      comment:
        "Этот чай помогает успокоить и расслабить тело и ум перед сном. Камомиль и мелисса давно известны своими успокаивающими свойствами, которые способствуют более крепкому и спокойному сну.",
    },
  ],

  14: [
    {
      name: "Смузи со шпинатом и бананом",
      mealTime: "Завтрак/Перекус",
      imgPath: course12Ration1,
      kkal: 180,
      protein: 4,
      fats: 1,
      carbs: 42,
      ingredients: [
        {
          name: "Спелый банан",
          count: "1 шт.",
        },
        {
          name: "Свежий шпинат",
          count: "1 стакан",
        },
        {
          name: "Несладкое миндальное молоко",
          count: "1 стакан",
        },
        {
          name: "Мед",
          count: "1 ч.л.",
        },
        {
          name: "Щепотка корицы",
        },
        {
          name: "Несколько кубиков льда",
        },
      ],
      cooking: [
        "Поместите банан, шпинат, миндальное молоко, мед и лед в блендер",
        "Взбейте до однородной консистенции",
        "Подавайте, посыпав сверху корицей для аромата",
      ],
      comment:
        "Этот смузи – отличный источник питательных веществ и энергии. Шпинат богат антиоксидантами и микроэлементами, а банан добавляет естественную сладость и энергию, делая напиток одновременно полезным и вкусным.",
    },
    {
      name: "Чай с лавандой",
      mealTime: "Перекус",
      imgPath: course12Ration2,
      kkal: 5,
      protein: 0,
      fats: 0,
      carbs: 1,
      ingredients: [
        {
          name: "Сушеные цветки лаванды",
          count: "1 ст.л.",
        },
        {
          name: "Зеленый чай",
          count: "1 пакетик",
        },
        {
          name: "Мед",
          count: "1 ч.л.",
        },
        {
          name: "Горячая вода",
          count: "1 стакан",
        },
      ],
      cooking: [
        "Заварите зеленый чай и лаванду в горячей воде на 5-7 минут",
        "Процедите чай и добавьте мед для сладости",
        "Выпейте перед сном или во время стрессовой ситуации для расслабления",
      ],
      comment:
        "Лаванда известна своими успокаивающими свойствами, которые могут помочь снизить стресс и улучшить сон. Зеленый чай содержит антиоксиданты, которые способствуют общему благополучию. Этот чай – прекрасный способ расслабиться после напряженного дня.",
    },
  ],

  15: [
    {
      name: "Салат с киноа и авокадо",
      mealTime: "Ужин/Перекус",
      imgPath: course13Ration1,
      kkal: 320,
      protein: 10,
      fats: 15,
      carbs: 40,
      ingredients: [
        {
          name: "Киноа",
          count: "1/2 стакана",
        },
        {
          name: "Спелый авокадо, нарезанный кубиками",
          count: "1 шт.",
        },
        {
          name: "Маленький огурец, нарезанный",
          count: "1 шт.",
        },
        {
          name: "Красный перец, нарезанный",
          count: "1/2 шт.",
        },
        {
          name: "Нарезанный красный лук",
          count: "1/4 стакана",
        },
        {
          name: "Оливковое масло",
          count: "2 ст.л.",
        },
        {
          name: "Сок лимона",
        },
        {
          name: "Соль и перец по вкусу",
        },
        {
          name: "Несколько листьев свежего базилика или петрушки",
        },
      ],
      cooking: [
        "Сварите киноа согласно инструкции на упаковке и дайте остыть",
        "В большой миске смешайте остывшую киноа, авокадо, огурец, перец и лук",
        "Заправьте салат оливковым маслом и лимонным соком. Посолите и поперчите по вкусу",
        "Украсьте свежими травами перед подачей",
      ],
      comment:
        "Этот салат идеально подходит для обеда или легкого ужина. Киноа и авокадо обеспечивают белок и полезные жиры, что делает блюдо питательным и сытным.",
    },
    {
      name: "Протеиновые панкейки",
      mealTime: "Завтрак/Перекус",
      imgPath: course13Ration2,
      kkal: 290,
      protein: 20,
      fats: 5,
      carbs: 45,
      ingredients: [
        {
          name: "Овсяная мука",
          count: "1/2 стакана",
        },
        {
          name: "Ванильный протеиновый порошок",
          count: "1 мер.л.",
        },
        {
          name: "Яйцо",
          count: "1 шт.",
        },
        {
          name: "Молоко или растительное молоко",
          count: "1/2 стакана",
        },
        {
          name: "Разрыхлитель",
          count: "1 ч.л.",
        },
        {
          name: "Мед или кленовый сироп",
          count: "1 ст.л.",
        },
        {
          name: "Щепотка соли",
        },
      ],
      cooking: [
        "В миске смешайте овсяную муку, протеиновый порошок, разрыхлитель и соль",
        "В другой миске взбейте яйцо, добавьте молоко и мед",
        "Смешайте сухие и влажные ингредиенты до однородной консистенции",
        "Выпекайте на среднем огне на сковороде с антипригарным покрытием до золотистого цвета",
      ],
      comment:
        "Эти панкейки – отличный способ начать день с богатого белками и низкокалорийного завтрака, который даст энергию и поможет сохранить чувство сытости на долгое время.",
    },
  ],

  16: [
    {
      name: "Ягодный йогурт с орехами",
      mealTime: "Завтрак/Перекус",
      imgPath: course14Ration1,
      kkal: 190,
      protein: 10,
      fats: 8,
      carbs: 24,
      ingredients: [
        {
          name: "Натуральный йогурт",
          count: "1 стакан",
        },
        {
          name: "Свежие ягоды",
          count: "1/2 стакана.",
        },
        {
          name: "Мед",
          count: "1 ст.л.",
        },
        {
          name: "Грецкие орехи, разьмелченные",
          count: "2 ст.л.",
        },
      ],
      cooking: [
        "В миске смешайте йогурт с медом",
        "Добавьте ягоды и перемешайте",
        "Посыпьте сверху измельченными грецкими орехами",
      ],
      comment:
        "Этот ягодный йогурт идеально подходит для здорового завтрака или перекуса. Ягоды обогатят организм антиоксидантами, а йогурт и орехи добавят белок и здоровые жиры.",
    },
    {
      name: "Легкий овощной суп",
      mealTime: "Обед/Ужин",
      imgPath: course14Ration2,
      kkal: 120,
      protein: 3,
      fats: 1,
      carbs: 25,
      ingredients: [
        {
          name: "Морковь, нарезанная",
          count: "2 шт.",
        },
        {
          name: "Стебель сельдерея, нарезанный",
          count: "2 шт.",
        },
        {
          name: "Луковица, мелко нарезанная",
          count: "1 шт.",
        },
        {
          name: "Зубчик чеснока, измельченный",
          count: "1 шт.",
        },
        {
          name: "Овощной бульон",
          count: "1 литр",
        },
        {
          name: "Зеленый горошек",
          count: "1 стакан",
        },
        {
          name: "Соль и перец",
          count: "по вкусу",
        },
        {
          name: "Укроп или петрушка",
          count: "для украшения",
        },
      ],
      cooking: [
        "Обжарьте лук и чеснок на слабом огне до мягкости",
        "Добавьте морковь и сельдерей, готовьте еще 5 минут",
        "Влейте овощной бульон и доведите до кипения",
        "Уменьшите огонь и варите до готовности овощей",
        "Добавьте зеленый горошек и варите еще несколько минут",
        "Подавайте, украсив зеленью",
      ],
      comment:
        "Этот суп отличается легкостью и питательностью, при этом содержит мало калорий. Овощи обеспечат организм необходимыми витаминами и минералами, а бульон сделает блюдо особенно удовлетворительным.",
    },
  ],

  17: [
    {
      name: "Овощные палочки с хумусом",
      mealTime: "Завтрак/Перекус",
      imgPath: course15Ration1,
      kkal: 150,
      protein: 6,
      fats: 8,
      carbs: 12,
      ingredients: [
        {
          name: "Разноцветные овощи (морковь, сельдерей, огурцы), нарезанные длинными палочками",
        },
        {
          name: "Хумус",
          count: "1/2 стакана.",
        },
      ],
      cooking: ["Нарежьте овощи и разложите их на сервировочном блюде", "Подавайте с хумусом в качестве дипа"],
      comment: "Это прекрасная альтернатива традиционным чипсам и соусам, предоставляя витамины и клетчатку.",
    },
    {
      name: "Фруктовые шашлычки",
      mealTime: "Ужин",
      imgPath: course15Ration2,
      kkal: 100,
      protein: 1,
      fats: 0.5,
      carbs: 25,
      ingredients: [
        {
          name: "Ассорти фруктов (ананас, клубника, киви, яблоки), нарезанные кубиками",
        },
        {
          name: "Деревянные шпажки",
        },
      ],
      cooking: ["Нанизывайте фрукты на шпажки, чередуя их", "Подавайте в качестве легкой и освежающей закуски"],
    },
    {
      name: 'Коктейль "Мохито" без алкоголя',
      mealTime: "Перекус",
      imgPath: course15Ration3,
      kkal: 50,
      protein: 0,
      fats: 0,
      carbs: 13,
      ingredients: [
        {
          name: "Лайм, нарезанный кубиками",
          count: "1/2 шт.",
        },
        {
          name: "Несколько листьев мяты",
        },
        {
          name: "Сахар",
          count: "1 ч.л.",
        },
        {
          name: "Лед",
        },
        {
          name: "Газированная вода",
        },
      ],
      cooking: [
        "В стакане смешайте лайм, мяту и сахар",
        "Добавьте лед и заполните стакан газированной водой",
        "Перемешайте и украсьте веточкой мяты",
      ],
    },
    {
      name: "Ягодный спрайцер",
      mealTime: "Перекус",
      imgPath: course15Ration4,
      kkal: 60,
      protein: 0,
      fats: 0,
      carbs: 15,
      ingredients: [
        {
          name: "Свежие ягоды (черника, малина)",
          count: "1/2 стакана",
        },
        {
          name: "Лед",
        },
        {
          name: "Газированная вода",
        },
      ],
      cooking: [
        "Размять ягоды в стакане",
        "Добавить лед и заполнить стакан газированной водой",
        "Перемешать и подавать сразу",
      ],
    },
  ],

  18: [
    {
      name: "Тушеная курица с овощами",
      mealTime: "Обед/Ужин",
      imgPath: course16Ration1,
      kkal: 350,
      protein: 25,
      fats: 10,
      carbs: 35,
      ingredients: [
        {
          name: "Куриные грудки без кожи",
          count: "2 шт.",
        },
        {
          name: "Морковь, нарезанная кубиками",
          count: "1 шт.",
        },
        {
          name: "Кабачок, нарезанный кубиками",
          count: "1 шт.",
        },
        {
          name: "Луковица, мелко нарезанная",
          count: "1 шт.",
        },
        {
          name: "Зубчики чеснока, измельченные",
          count: "2 шт.",
        },
        {
          name: "Томатный соус",
          count: "1 стакан",
        },
        {
          name: "Куриный бульон",
          count: "1 стакан",
        },
        {
          name: "Соль, перец и травы по вкусу",
        },
      ],
      cooking: [
        "Обжарьте курицу до золотистой корочки и выложите на тарелку",
        "В том же сковороде обжарьте лук и чеснок, затем добавьте морковь и кабачок",
        "Верните курицу в сковороду, добавьте томатный соус и бульон",
        "Тушите под крышкой на медленном огне 20-30 минут",
      ],
    },
    {
      name: "Салат с киноа и авокадо",
      mealTime: "Ужин/Перекус",
      imgPath: course16Ration2,
      kkal: 300,
      protein: 8,
      fats: 15,
      carbs: 35,
      ingredients: [
        {
          name: "Киноа",
          count: "1/2 стакана",
        },
        {
          name: "Авокадо, нарезанный кубиками",
          count: "1 шт.",
        },
        {
          name: "Черри, разрезанные пополам",
          count: "1/2 стакана",
        },
        {
          name: "Красный лук, мелко нарезанный",
          count: "1/4 стакана",
        },
        {
          name: "Листья шпината или рукколы",
        },
        {
          name: "Оливковое масло",
          count: "2 ст.л.",
        },
        {
          name: "Сок 1 лимона",
        },
        {
          name: "Соль и перец по вкусу",
        },
      ],
      cooking: [
        "Сварите киноа согласно инструкции на упаковке и остудите",
        "В большой миске смешайте киноа, авокадо, черри, лук и зеленые листья",
        "Заправьте оливковым маслом, лимонным соком, солью и перцем",
      ],
    },
  ],
}
