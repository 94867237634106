import { useState, useEffect } from "react"
import { Routes, Route, Navigate, useLocation, useSearchParams } from "react-router-dom"
import { useFirstMountState } from "react-use"
import dayjs from "dayjs"

import Auth from "app/sections/Auth"
import RestorePassword from "app/sections/RestorePassword"
import NewPassword from "app/sections/NewPassword"
import Materials from "app/sections/Materials"
import Topic from "app/sections/Topic"
import Cabinet from "app/sections/Cabinet"
import CancelSub from "app/sections/Cancel"
import CancelLite from "app/sections/CancelLite"
import Refund from "app/sections/Refund"
import Settings from "app/sections/Settings"
import Faq from "app/sections/Faq"
import AdminRebills from "app/sections/Admin/Rebills"
import AdminPayments from "app/sections/Admin/Payments"
import AdminStreams from "app/sections/Admin/Streams"
import AdminStats from "app/sections/Admin/Stats"
import AdminPayouts from "app/sections/Admin/Payouts"
import AdminFun from "app/sections/Admin/Fun"
import AdminLeads from "app/sections/Admin/Leads"
import AdminProfit from "app/sections/Admin/Profit"

import { PrivateRoute } from "app/components/PrivateRoute"
import { AdminRoute } from "app/components/AdminRoute"
import { AdminLayout } from "app/components/AdminLayout"
import { AuthLayout } from "app/components/AuthLayout"
import { PrivateLayout } from "app/components/PrivateLayout"
import { MainLayout } from "app/components/MainLayout"
import { Notification } from "app/components/Notification"

import { Role } from "state/types/app"
import { useAppDispatch, useAppSelector } from "hooks/dispatch"
import { setAppLoading, setProfile } from "state/slices/app"
import config from "config"
import {
  FAQ_ROUTE,
  AUTH_ROUTE,
  RESET_PASSWORD_ROUTE,
  NEW_PASSWORD_ROUTE,
  CABINET_ROUTE,
  MATERIALS_ROUTE,
  CANCEL_SUB_ROUTE,
  TOPIC_ROUTE,
  REFUND_ROUTE,
  SETTINGS_ROUTE,
  CANCEL_LITE_ROUTE,
} from "routes"
import { getRefreshToken, getToken, removeToken, removeTokens, setToken } from "lib/helper"
import API from "lib/api"

import { LoginContext } from "./LoginContext"
import { getTopicsData } from "state/dispatchers/topics"
import { ProgressSpinner } from "primereact/progressspinner"

import style from "./style.module.scss"

const TOKEN_UPDATE_INTERVAL_SEC = 60000 * 4
const PROFILE_UPDATE_INTERVAL = 60000 * 5

export const App = () => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const token = getToken()
  const refresh = getRefreshToken()
  const isFirstMount = useFirstMountState()

  const [isLogin, setLoginState] = useState(!!token)
  const [isTokenRefreshed, setTokenRefreshed] = useState(false)
  const [isProfileLoaded, setProfileLoaded] = useState(false)
  const [updateTimeout, setUpdateTimeout] = useState<number>(0)
  const [updateUserTimeout, setUpdateUserTimeout] = useState<number>(0)
  const role = useAppSelector(state => state.app.profile.role)
  const isAppLoading = useAppSelector(state => state.app.isLoading)

  const isAdmin = isLogin && role === Role.Admin

  const getProfile = () => {
    API.get(config.paths.profile, { params: { fallback: false } })
      .then(({ data }) => {
        dispatch(setProfile(data))
      })
      .catch(() => signOut())
      .finally(() => {
        dispatch(setAppLoading(false))
        setProfileLoaded(true)
      })
  }

  const updateToken = async (newToken?: string) => {
    const refreshToken = newToken || getRefreshToken()

    try {
      const response = await API.get(config.paths.auth.refresh, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })

      if (response.data.accessToken && response.data.refreshToken) {
        removeToken()
        setToken("access_token", response.data.accessToken, {
          expires: dayjs().add(7, "day").toDate(),
        })
        setToken("refresh_token", response.data.refreshToken, {
          expires: dayjs().add(7, "day").toDate(),
        })
        setTokenRefreshed(true)
        setLoginState(true)

        // const updateTimer = window.setTimeout(updateToken, TOKEN_UPDATE_INTERVAL_SEC)
        // setUpdateTimeout(updateTimer)

        dispatch(setAppLoading(false))
      } else {
        signOut()
        dispatch(setAppLoading(false))
      }
    } catch (err) {
      signOut()
      dispatch(setAppLoading(false))
    }
  }

  const onChangeLoginState = (isLoginState: boolean) => {
    setLoginState(isLoginState)

    if (isLoginState) {
      setTokenRefreshed(true)

      // const updateTimer = window.setTimeout(updateToken, TOKEN_UPDATE_INTERVAL_SEC)
      // setUpdateTimeout(updateTimer)
    } else {
      // window.clearTimeout(updateTimeout)
    }
  }

  const signOut = () => {
    removeTokens()
    setLoginState(false)
    setTokenRefreshed(true)
  }

  const spinner = (
    <div className={style.appSpinner}>
      <ProgressSpinner style={{ width: "120px", height: "120px" }} />
    </div>
  )

  useEffect(() => {
    if (!token && isLogin && refresh) {
      updateToken()
    }
  }, [pathname])

  useEffect(() => {
    if (isFirstMount && refresh) {
      setAppLoading(true)
      updateToken()
    }

    if (isFirstMount && !refresh) {
      signOut()
    }
  }, [])

  useEffect(() => {
    if (searchParams.has("token")) {
      dispatch(setAppLoading(true))
      updateToken(searchParams.get("token") || "")
    }
  }, [searchParams])

  useEffect(() => {
    // const profileTimer = window.setInterval(() => {
    //   const refreshToken = getRefreshToken()

    //   if (!isLogin || !refreshToken) return
    //   getProfile()
    //   getTopicsData()
    // }, PROFILE_UPDATE_INTERVAL)
    // setUpdateUserTimeout(profileTimer)

    if (isLogin && refresh) {
      getProfile()
      getTopicsData()
    }

    return () => {
      // window.clearInterval(updateUserTimeout)
    }
  }, [isLogin])

  if ((refresh && !isTokenRefreshed) || isAppLoading) {
    return spinner
  }

  return (
    <LoginContext.Provider value={{ onLoginStateChange: onChangeLoginState }}>
      <Notification />

      <Routes>
        <Route element={<PrivateRoute isLogin={isLogin} />}>
          <Route element={<PrivateLayout isLogin={isLogin} signOut={signOut} />}>
            <Route index element={<Materials />} />
            <Route path={NEW_PASSWORD_ROUTE} element={<NewPassword />} />
            <Route path={CABINET_ROUTE} element={<Cabinet />} />
            <Route path={TOPIC_ROUTE} element={<Topic />} />
            <Route path={SETTINGS_ROUTE} element={<Settings />} />
          </Route>
        </Route>

        <Route path="admin" element={<AdminRoute isAdmin={isAdmin} isProfileLoaded={isProfileLoaded} />}>
          <Route element={<AdminLayout isLogin={isLogin} signOut={signOut} />}>
            <Route path="rebills" element={<AdminRebills />} />
            <Route path="payments" element={<AdminPayments />} />
            <Route path="streams" element={<AdminStreams />} />
            <Route path="stats" element={<AdminStats />} />
            <Route path="payouts" element={<AdminPayouts />} />
            <Route path="leads" element={<AdminLeads />} />
            <Route path="profit" element={<AdminProfit />} />
            <Route path="fun" element={<AdminFun />} />
          </Route>
        </Route>

        <Route element={<AuthLayout />}>
          <Route path={RESET_PASSWORD_ROUTE} element={<RestorePassword isLogin={isLogin} />} />
          <Route path={AUTH_ROUTE} element={<Auth isLogin={isLogin} loginContext={LoginContext} />} />
        </Route>

        <Route element={<MainLayout isLogin={isLogin} signOut={signOut} />}>
          <Route path={FAQ_ROUTE} element={<Faq />} />
          <Route path={CANCEL_SUB_ROUTE} element={<CancelSub />} />
          <Route path={CANCEL_LITE_ROUTE} element={<CancelLite />} />
          <Route path={REFUND_ROUTE} element={<Refund />} />
        </Route>

        <Route path="*" element={<Navigate to={MATERIALS_ROUTE} />} />
      </Routes>
    </LoginContext.Provider>
  )
}
