import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "12"

export const Course10: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Психология Похудения</h1>
      </div>

      <Ration kkal={410} portion="3 - 4" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Включение этих продуктов в ваш рацион поможет не только улучшить физическое здоровье, но и оказать положительное
        влияние на ваше эмоциональное состояние, помогая справляться со стрессом и улучшая настроение.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Понимание влияния психологии на процесс похудения</h3>
          <p>
            Процесс похудения – это не только физический вызов, но и психологический. Понимание влияния психологии на
            усилия по снижению веса может значительно увеличить шансы на успех.
          </p>
          <p>
            Прежде всего, важно признать влияние установок и убеждений на пищевое поведение. Многие люди едят не из-за
            физического голода, а чтобы справиться с эмоциональным дискомфортом. Распознавание эмоционального объедания
            и разработка альтернативных способов справиться с чувствами – ключевой шаг на пути к более здоровому
            пищевому поведению.
          </p>
          <p>
            Самодисциплина и мотивация также играют решающую роль. Однако мотивация может колебаться, поэтому важно
            установить прочные привычки и рутины. Создание поддерживающей среды, такой как здоровые закуски в
            доступности и регулярные тренировки, помогает поддерживать фокус, даже когда мотивация ослабевает.
          </p>
          <p>
            Психологический аспект важен и в отношении самовосприятия. Негативное отношение к собственному телу и
            самокритика могут приводить к стрессу и демотивации. Важно учиться принимать свое тело и праздновать каждый
            шаг на пути к здоровью.
          </p>
          <p>
            Кроме того, целеполагание в процессе похудения должно быть реалистичным. Нереалистичные цели могут привести
            к разочарованию и отказу от усилий. Установление достижимых, поэтапных целей помогает поддерживать мотивацию
            и чувство достижения.
          </p>
          <p>
            В конечном счете, успешное похудение требует гармонии ума и тела. Понимание и применение психологических
            принципов может значительно повысить шансы на долгосрочный успех и благополучие.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Пища, способствующая улучшению настроения и снижению стресса</h3>
          <p>
            Правильное питание играет важную роль не только в поддержании физического здоровья, но и в улучшении
            настроения и снижении уровня стресса. Некоторые продукты содержат питательные вещества, способствующие
            выработке серотонина, известного как «гормон счастья», и других нейротрансмиттеров, которые положительно
            влияют на наше эмоциональное состояние.
          </p>
          <p>
            <b>Богатые омега-3 жирные кислоты продукты,</b> такие как лосось, сардины и льняное масло, могут помочь в
            борьбе со стрессом. Омега-3 способствуют уменьшению воспалений и поддержанию здоровья мозга, что влияет на
            улучшение настроения.
          </p>
          <p>
            <b>Комплексные углеводы,</b> такие как цельнозерновые продукты, овощи и фрукты, обеспечивают стабильный
            уровень сахара в крови и продолжительное ощущение сытости, что помогает избежать внезапных скачков
            настроения.
          </p>
          <p>
            <b>Богатые магнием продукты,</b> например, шпинат, тыквенные семечки и бананы, помогают регулировать уровни
            стресса. Магний играет ключевую роль в релаксации мышц и нервной системы.
          </p>
          <p>
            <b>Богатые антиоксидантами продукты,</b> такие как ягоды, орехи и темный шоколад, способствуют снижению
            оксидативного стресса в организме, что может улучшить общее самочувствие и настроение.
          </p>
          <p>
            <b>Продукты, богатые витаминами группы B,</b> включая авокадо, орехи и молочные продукты, важны для
            поддержания здоровья нервной системы и могут помочь улучшить настроение.
          </p>

          <p>
            <b>Богатые триптофаном продукты,</b> такие как индейка, курица и яйца, способствуют выработке серотонина,
            который улучшает настроение и качество сна.
          </p>

          <p>
            Включение этих продуктов в ваш рацион поможет не только улучшить физическое здоровье, но и оказать
            положительное влияние на ваше эмоциональное состояние, помогая справляться со стрессом и улучшая настроение.
          </p>

          <div className={style.divider} />

          <h3>Рецепты: Шоколадный банановый смузи, ореховые снэки</h3>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
