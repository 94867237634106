import axios, { CancelTokenSource } from "axios"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import API from "lib/api"
import config from "config"
import { buildParams } from "lib/helper"

import { Filters } from "./Filters"
import { Total } from "./Total"

import type { FC } from "react"
import type { AdminProfitQuery, AdminProfitTotal } from "state/types/admin"

const CancelToken = axios.CancelToken
let source: CancelTokenSource

const AdminProfit: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [total, setTotal] = useState<AdminProfitTotal>({
    amount: "",
  })

  const filters: AdminProfitQuery = {
    from: searchParams.get("from") || "",
    to: searchParams.get("to") || "",
  }

  const setFilters = (filters: AdminProfitQuery) => {
    setSearchParams(filters)
  }

  const getData = () => {
    if (source) {
      source.cancel()
    }

    const query = {
      from: filters.from || "",
      to: filters.to || "",
    }

    const url = `${config.paths.admin.profit}?${buildParams(query)}`

    source = CancelToken.source()

    API.get(url, {
      cancelToken: source.token,
      params: { fallback: false },
    }).then(({ data }) => {
      setTotal(data.total)
    })
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  return (
    <>
      <Filters filters={filters} setFilters={setFilters} />

      <Total total={total} />
    </>
  )
}

export default AdminProfit
