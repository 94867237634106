import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "14"

export const Course12: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Управление Стрессом для Похудения</h1>
      </div>

      <Ration kkal={185} portion="3 - 4" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Включение этих продуктов в ваш рацион может помочь справиться со стрессом и улучшить общее самочувствие, что в
        свою очередь благоприятно скажется на усилиях по похудению.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Связь между стрессом и весом</h3>
          <p>
            Стресс - это не просто психологическое состояние, он также может оказывать значительное влияние на ваш вес и
            усилия по его снижению. Понимание связи между стрессом и весом является ключевым аспектом в борьбе с лишними
            килограммами.
          </p>
          <p>
            Под воздействием стресса организм выделяет гормон кортизол, который влияет на метаболизм и способствует
            накоплению жировой ткани, особенно в области живота. Кроме того, кортизол увеличивает аппетит, особенно на
            сладкое и жирное, что приводит к перееданию и набору веса.
          </p>
          <p>
            Стресс также может нарушать здоровый сон, который критически важен для поддержания нормального веса.
            Недостаток сна увеличивает чувство голода и уменьшает чувство сытости, что приводит к увеличению потребления
            пищи и набору веса.
          </p>
          <p>
            Под влиянием стресса многие люди прибегают к так называемому «эмоциональному едению», когда пища
            используется как способ справиться с негативными чувствами. Это часто приводит к выбору нездоровых продуктов
            и перееданию.
          </p>
          <p>
            Кроме того, стресс может снижать мотивацию к занятиям физическими упражнениями. Усталость и апатия,
            вызванные стрессом, делают трудным поддержание регулярных тренировок, которые необходимы для сжигания
            калорий и поддержания здорового веса.
          </p>
          <p>
            В конечном счете, управление стрессом - важный элемент в процессе похудения. Научиться справляться со
            стрессом через медитацию, йогу, глубокое дыхание, здоровое питание и достаточный сон может помочь не только
            улучшить ваше психическое состояние, но и способствовать снижению веса.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Продукты, снижающие уровень стресса</h3>
          <p>
            В современном мире, где стресс является частым явлением, правильное питание может стать мощным инструментом
            для его снижения. Некоторые продукты содержат питательные вещества, которые помогают регулировать уровни
            стресса и улучшать настроение.
          </p>
          <p>
            <b>1. Жирная рыба, такая как лосось и сардины:</b> Они богаты омега-3 жирными кислотами, которые были
            связаны с снижением уровня стресса и улучшением настроения. Омега-3 помогает уменьшать воспаления и
            способствует нормализации уровней серотонина и дофамина.
          </p>
          <p>
            <b>2. Орехи, особенно грецкие:</b> Орехи содержат магний, который помогает бороться со стрессом, снижая
            уровень кортизола, гормона стресса. Грецкие орехи также богаты альфа-линоленовой кислотой, разновидностью
            омега-3.
          </p>
          <p>
            <b>3. Цитрусовые фрукты и клубника:</b> Эти фрукты богаты витамином C, который помогает снижать уровень
            кортизола и кровяное давление в стрессовых ситуациях.
          </p>
          <p>
            <b>4. Шоколад, особенно темный:</b> Шоколад содержит флавоноиды, которые могут уменьшить нейровоспаление и
            снизить уровни стресса, а также улучшить кровообращение в мозге.
          </p>
          <p>
            <b>5. Авокадо:</b> Этот плод богат витаминами группы B, которые помогают улучшить здоровье нервной системы.
            Авокадо также содержит полезные жиры, которые помогают снижать стресс.
          </p>
          <p>
            <b>6. Цельнозерновые продукты, такие как овсянка и коричневый рис:</b> Богаты магнием, фосфором и витаминами
            группы B, которые помогают улучшить настроение и уменьшить чувство усталости и стресса.
          </p>
          <p>
            <b>7. Чай из зеленого чая и камомиля:</b> Оба напитка содержат антиоксиданты и фитохимические вещества,
            которые помогают снижать стресс и улучшать сон.
          </p>
          <p>
            Включение этих продуктов в ваш рацион может помочь справиться со стрессом и улучшить общее самочувствие, что
            в свою очередь благоприятно скажется на усилиях по похудению.
          </p>

          <div className={style.divider} />

          <h3>Рецепты: Смузи с шпинатом и бананом, чай с лавандой</h3>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
