const config = {
  paths: {
    profile: "/profile",
    auth: {
      signin: "/auth/signin",
      restorePassword: "/auth/restore-password",
      resetPassword: "/auth/reset-password",
      newPassword: "/auth/new-password",
      logout: "/auth/logout",
      refresh: "/auth/refresh",
    },
    cancelSub: "/subscriptions/cancel",
    cancelSubLite: "/subscriptions/cancel-lite",
    refund: "/subscriptions/refund",
    admin: {
      users: "/admin/users",
      streams: "/admin/streams",
      payments: "/admin/payments",
      stats: "/admin/stats",
      payouts: "/admin/payouts",
      leads: "/admin/leads",
      profit: "/admin/profit",
      rebills: "/admin/rebills",
      funFacts: "/admin/fun-facts",
    },
    topics: {
      data: "/topics",
      setActive: "/topics/set-active",
    },
  },
}

export default config
