import { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper/modules"
import { Dialog } from "primereact/dialog"

import style from "./style.module.scss"

import "swiper/css"
import "swiper/css/autoplay"

import type { FC } from "react"
import type { RationData } from "../../types"

type Props = {
  kkal: number
  portion: string
  rationItems: Array<RationData>
}

export const Ration: FC<Props> = ({ kkal, portion, rationItems }) => {
  const [selectedRation, setSelectedRation] = useState<RationData | null>(null)

  const isModalVisible = !!selectedRation?.name

  const portionText = Number(portion.slice(0, 1)) > 3 ? "порций" : "порции"

  return (
    <>
      <Dialog
        header={selectedRation?.name}
        visible={isModalVisible}
        className={style.dialog}
        maskClassName={style.dialogWrapper}
        contentClassName={style.dialogContent}
        onHide={() => setSelectedRation(null)}
        draggable={false}
        blockScroll
        dismissableMask
      >
        <h3>Ингредиенты:</h3>
        <div className={style.list}>
          {selectedRation?.ingredients?.map((item, index) => (
            <div className={style.listItem}>
              <p>{index + 1}.</p>
              <p>
                {item.name} <b>{item.count && ` - ${item.count}`}</b>
              </p>
            </div>
          ))}
        </div>
        <img src={selectedRation?.imgPath} alt={selectedRation?.name} />
        <h3>Способ приготовления:</h3>
        {Array.isArray(selectedRation?.cooking) ? (
          <div className={style.list}>
            {selectedRation?.cooking?.map((item, index) => (
              <div key={index} className={style.listItem}>
                <p>{index + 1}.</p>
                <p>{item}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>{selectedRation?.cooking}</p>
        )}
        {selectedRation?.comment && <p className={style.dialogComment}>{selectedRation?.comment}</p>}
      </Dialog>

      <div className={style.ration}>
        <h2>Включите в рацион:</h2>

        <div className={style.kkal}>
          <div>Общее кол-во калорий</div>
          <div>{kkal} ккал</div>
        </div>

        <div className={style.portion}>
          <div>Кол-во порций</div>
          <div>
            {portion} {portionText}
          </div>
        </div>

        <Swiper
          modules={[Autoplay]}
          spaceBetween={30}
          autoplay
          slidesPerView={1}
          breakpoints={{
            600: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
        >
          {rationItems.map((item, index) => (
            <SwiperSlide className={style.swiperItem} key={item.name}>
              <img src={item.imgPath} alt={`ration${index}`} />
              <div className={style.content}>
                <div className={style.mealTime}>{item.mealTime}</div>
                <div className={style.title}>{item.name}</div>
                <div className={style.info}>
                  <div>
                    <span>Ккал</span>
                    <br />
                    <b>{item.kkal}</b>
                  </div>
                  <div className={style.divider} />
                  <div>
                    <span>Белки</span>
                    <br />
                    <b>{item.protein}</b>
                  </div>
                  <div className={style.divider} />
                  <div>
                    <span>Жиры</span>
                    <br />
                    <b>{item.fats}</b>
                  </div>
                  <div className={style.divider} />
                  <div>
                    <span>Углеводы</span>
                    <br />
                    <b>{item.carbs}</b>
                  </div>
                </div>

                <div className={style.link} onClick={() => setSelectedRation(item)}>
                  Смотреть
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}
