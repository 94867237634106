import { createSlice } from "@reduxjs/toolkit"

import type { AdminRebillsItem, AdminState, AdminStreamsItem, AdminUsersItem, SelectedRebill } from "state/types/admin"
import type { PayloadAction } from "@reduxjs/toolkit"

const initialState: AdminState = {
  isUsersLoading: true,
  isStreamsListLoading: true,
  isRebillsLoading: true,

  users: [],
  streams: [],

  rebills: [],
  selectedRebill: null,
}

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    getUsersRequest: state => {
      state.isUsersLoading = true
    },

    getUsersSuccess: (state, action: PayloadAction<Array<AdminUsersItem>>) => {
      state.isUsersLoading = false
      state.users = action.payload
    },

    getUsersFailure: state => {
      state.isUsersLoading = false
    },

    getStreamsRequest: state => {
      state.isStreamsListLoading = true
    },

    getStreamsSuccess: (state, action: PayloadAction<Array<AdminStreamsItem>>) => {
      state.isStreamsListLoading = false
      state.streams = action.payload
    },

    getStreamsFailure: state => {
      state.isStreamsListLoading = false
    },

    getRebillsRequest: state => {
      state.isRebillsLoading = true
    },

    getRebillsSuccess: (state, action: PayloadAction<Array<AdminRebillsItem>>) => {
      state.isRebillsLoading = false
      state.rebills = action.payload
    },

    getRebillsFailure: state => {
      state.isRebillsLoading = false
    },

    setSelectedRebill: (state, action: PayloadAction<SelectedRebill | null>) => {
      state.selectedRebill = action.payload
    },

    clearRebills: state => {
      state.rebills = initialState.rebills
      state.selectedRebill = initialState.selectedRebill
    },
  },
})

export const {
  getUsersFailure,
  getUsersRequest,
  getUsersSuccess,
  getStreamsFailure,
  getStreamsRequest,
  getStreamsSuccess,
  getRebillsFailure,
  getRebillsRequest,
  getRebillsSuccess,
  setSelectedRebill,
  clearRebills,
} = adminSlice.actions

export default adminSlice.reducer
