import {
  getUsersFailure,
  getUsersRequest,
  getUsersSuccess,
  getStreamsFailure,
  getStreamsRequest,
  getStreamsSuccess,
  getRebillsRequest,
  getRebillsFailure,
  getRebillsSuccess,
} from "state/slices/admin"

import { buildParams } from "lib/helper"
import config from "config"
import API from "lib/api"

import type { AdminRebillsQuery, AdminUsersQuery } from "state/types/admin"
import type { Dispatch } from "redux"

export const getUsers = (filters: AdminUsersQuery) => (dispatch: Dispatch) => {
  dispatch(getUsersRequest())

  const url = `${config.paths.admin.users}?${buildParams(filters)}`

  API.get(url)
    .then(({ data }) => {
      dispatch(getUsersSuccess(data.items))
    })
    .catch(() => dispatch(getUsersFailure()))
}

export const getRebills = (filters: AdminRebillsQuery) => (dispatch: Dispatch) => {
  dispatch(getRebillsRequest())

  const url = `${config.paths.admin.rebills}?${buildParams(filters)}`

  API.get(url)
    .then(({ data }) => {
      dispatch(getRebillsSuccess(data.items))
    })
    .catch(() => dispatch(getRebillsFailure()))
}

export const getStreams = () => (dispatch: Dispatch) => {
  dispatch(getStreamsRequest())

  API.get(config.paths.admin.streams)
    .then(({ data }) => {
      dispatch(getStreamsSuccess(data.items))
    })
    .catch(() => dispatch(getStreamsFailure()))
}
