import React from "react"

import type { FC } from "react"

import style from "../style.module.scss"

export const CourseTitle: FC = () => {
  return (
    <div className={style.courseTitle}>
      <h1>Курс: План питания для похудения</h1>
    </div>
  )
}
