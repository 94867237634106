import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "9"

export const Course7: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Планирование Питания и Покупки</h1>
      </div>

      <Ration kkal={1050} portion="3 - 4" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти рецепты обеспечивают сбалансированное питание с хорошим сочетанием белков, жиров и углеводов, а также
        достаточным количеством клетчатки. Планирование питания на неделю помогает поддерживать здоровый рацион и
        избегать нездоровой пищи.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Как планировать еду и делать покупки</h3>
          <p>
            Планирование питания и покупок – это не только ключевой элемент успешного похудения, но и важная часть
            поддержания здорового образа жизни. Эффективное планирование помогает избежать импульсивных покупок
            нездоровой пищи, сокращает количество выброшенных продуктов и обеспечивает сбалансированное питание на
            протяжении всей недели. Планируя меню заранее, вы можете убедиться, что ваш рацион включает разнообразные
            белки, углеводы и жиры, а также достаточное количество фруктов и овощей. Это также позволяет лучше
            контролировать калорийность блюд и обеспечивает необходимое количество макро- и микроэлементов.
          </p>
          <p>
            При планировании питания полезно учитывать личные предпочтения, образ жизни и цели похудения. Например, если
            вы занимаетесь спортом, вам может потребоваться увеличить потребление белка для восстановления мышц. Если
            ваша цель - снижение веса, важно сосредоточиться на низкокалорийных, но питательных продуктах.
          </p>

          <div className={style.divider} />

          <h3>Рацион: План на неделю с разнообразными блюдами</h3>
          <p>
            Составление плана питания на неделю помогает не только сбалансировать питание, но и обеспечивает
            разнообразие вкусов и питательных веществ. Это также упрощает процесс покупок, так как вы точно знаете, что
            вам нужно, и можете избежать лишних трат. Планируя меню, старайтесь включать различные источники белка
            (мясо, рыба, бобовые), разнообразные овощи и фрукты, а также здоровые жиры (авокадо, орехи, оливковое
            масло). Также полезно планировать здоровые перекусы, чтобы избежать обращения к вредным закускам в течение
            дня.
          </p>
          <p>
            При составлении плана питания на неделю учитывайте свой график. Если у вас занятые будни, подготовьте или
            запланируйте блюда, которые легко и быстро готовить. В дни, когда у вас больше свободного времени, можно
            попробовать новые рецепты или приготовить более сложные блюда.
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
