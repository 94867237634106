import { getTopicsDataFailure, getTopicsDataRequest, getTopicsDataSuccess, setActiveTopic } from "state/slices/topics"

import config from "config"
import API from "lib/api"

import type { Dispatch } from "redux"

export const getTopicsData = () => (dispatch: Dispatch) => {
  dispatch(getTopicsDataRequest())

  API.get(config.paths.topics.data)
    .then(({ data }) => {
      dispatch(getTopicsDataSuccess(data))
    })
    .catch(() => dispatch(getTopicsDataFailure()))
}

export const putActiveTopic = (topicId: number) => (dispatch: Dispatch) => {
  const payload = {
    activeTopic: topicId,
  }

  API.put(config.paths.topics.setActive, payload).then(({ data }) => {
    dispatch(setActiveTopic(data.activeTopic))
  })
}
