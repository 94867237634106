import React from "react"
import { Outlet } from "react-router"

import { Header } from "app/components/Header"

import { AdminTabs } from "./AdminTabs"

import type { FC } from "react"

import style from "./style.module.scss"

type Props = {
  isLogin: boolean
  signOut: () => void
}

export const AdminLayout: FC<Props> = ({ isLogin, signOut }) => {
  return (
    <React.Fragment>
      <Header isLogin={isLogin} signOut={signOut} />

      <main className={style.pageWrapper}>
        <AdminTabs />

        <div className={style.content}>
          <div className="container">
            <Outlet />
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}
