import React, { useEffect } from "react"
import { Outlet } from "react-router"

import { Card } from "primereact/card"
import { ProgressSpinner } from "primereact/progressspinner"

import { Header } from "app/components/Header"
import { Footer } from "app/components/Footer"

import { useAppSelector } from "hooks/dispatch"

import type { FC } from "react"

import style from "./style.module.scss"

type Props = {
  isLogin: boolean
  signOut: () => void
}

export const PrivateLayout: FC<Props> = ({ isLogin, signOut }) => {
  const isActive = useAppSelector(state => state.app.profile.isActive)
  const isLoading = useAppSelector(state => state.app.isLoading)

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [isLoading])

  return (
    <>
      <Header isLogin={isLogin} signOut={signOut} />

      {!isLoading &&
        (isActive ? (
          <main className={style.pageWrapper}>
            <Outlet />
          </main>
        ) : (
          <div className={style.notActiveWrapper}>
            <div className="container">
              <Card title="Ошибка" className={style.content}>
                <p className="mb-2">Ваша подписка отключена.</p>
              </Card>
            </div>
          </div>
        ))}

      <Footer />
    </>
  )
}
