import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "11"

export const Course9: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Физическая Активность и Похудение</h1>
      </div>

      <Ration kkal={570} portion="3 - 4" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти рецепты не только вкусные, но и полезные, обеспечивая вас всеми необходимыми питательными веществами для
        поддержания энергии и восстановления мышц. Приятного аппетита!
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Важность упражнений в процессе похудения</h3>
          <p>
            В мире, где обилие информации о похудении может сбивать с толку, один аспект остается неизменно важным –
            физическая активность. Упражнения играют ключевую роль в процессе снижения веса, но их значимость гораздо
            глубже, чем простое сжигание калорий.
          </p>
          <p>
            Прежде всего, упражнения улучшают обмен веществ. Регулярная физическая активность ускоряет метаболизм, что
            способствует более эффективному сжиганию калорий даже в состоянии покоя. Кроме того, физическая нагрузка
            увеличивает мышечную массу. Мышцы потребляют больше энергии, чем жировая ткань, таким образом, чем больше
            мышц в вашем теле, тем выше ваш основной обмен веществ.
          </p>
          <p>
            Но физические упражнения важны не только для сжигания калорий. Они также оказывают огромное влияние на наше
            общее самочувствие. Регулярные тренировки улучшают настроение благодаря выработке эндорфинов – «гормонов
            счастья». Это помогает справиться со стрессом, который часто становится причиной переедания и набора веса.
          </p>
          <p>
            Кроме того, упражнения укрепляют сердечно-сосудистую систему и улучшают общее физическое состояние.
            Повышение выносливости, укрепление сердца и легких – все это делает организм более эффективным в
            повседневной жизни.
          </p>
          <p>
            Важно также понимать, что упражнения должны быть регулярными и разнообразными. Сочетание кардиоупражнений и
            силовых тренировок является идеальным вариантом для того, чтобы достичь лучших результатов в похудении.
          </p>
          <p>
            Физические упражнения – это не просто инструмент для снижения веса. Это способ улучшить качество жизни,
            здоровье и общее благополучие. Начать можно с малого, но главное – сделать физическую активность регулярной
            частью вашей жизни.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Питание, поддерживающее физическую активность</h3>
          <p>
            Правильное питание является неотъемлемой частью поддержки физической активности. Оно обеспечивает
            необходимую энергию и питательные вещества, улучшает результаты тренировок и способствует восстановлению.
            Основа такого рациона – это баланс белков, углеводов и жиров, а также достаточное потребление витаминов и
            минералов.
          </p>
          <p>
            Углеводы – это основной источник энергии для вашего тела, особенно во время высокоинтенсивных тренировок.
            Сложные углеводы, такие как цельнозерновые продукты, фрукты и овощи, обеспечивают длительное ощущение
            сытости и стабильный уровень сахара в крови, что помогает избежать внезапных скачков энергии и усталости.
          </p>
          <p>
            Белки важны для восстановления и роста мышц. Источники белка, такие как мясо, рыба, яйца, молочные продукты,
            бобовые и орехи, должны регулярно присутствовать в рационе, особенно после тренировок, для поддержания и
            восстановления мышечной ткани.
          </p>
          <p>
            Жиры также имеют важное значение, особенно полезные ненасыщенные жиры, содержащиеся в оливковом масле,
            авокадо и орехах. Они способствуют усвоению витаминов и поддержанию здоровья клеточных мембран.
          </p>
          <p>
            Не забывайте и о воде – она критически важна для поддержания гидратации, особенно во время и после
            физических упражнений. Адекватное потребление воды помогает оптимизировать физическую производительность,
            поддерживать нормальную температуру тела и облегчать транспортировку питательных веществ.
          </p>
          <p>
            Помните, питание, поддерживающее физическую активность, должно быть сбалансированным и разнообразным. Оно
            обеспечивает необходимую энергию и питательные вещества для достижения ваших спортивных целей, а также
            способствует общему здоровью и благополучию.
          </p>

          <div className={style.divider} />

          <h3>Рецепты: Батончики с энергией, смузи для восстановления</h3>
          <p>
            Поддержание активного образа жизни требует не только упорных тренировок, но и правильного питания. Вот два
            восхитительных рецепта – энергетические батончики и смузи для восстановления – которые помогут вам
            зарядиться энергией и эффективно восстановиться после физических нагрузок.
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
