import React, { useState } from "react"
import cn from "classnames"

import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"

import config from "config"
import API from "lib/api"

import style from "./style.module.scss"

import type { FC } from "react"
import type { NewPasswordFormData } from "state/types/auth"

const defaultFormData: Partial<NewPasswordFormData> = {
  new_password: "",
  new_password_confirm: "",
}

const NewPassword: FC = () => {
  const [formData, setFormData] = useState<Partial<NewPasswordFormData>>(defaultFormData)
  const [isLoading, setLoading] = useState(false)

  const isConfirmInvalid =
    formData.new_password &&
    formData.new_password_confirm &&
    !!(formData.new_password !== formData.new_password_confirm)
  const isShortPassword = !!(formData.new_password && formData.new_password.length < 8)
  const isDisabled = !formData.new_password || !formData.new_password_confirm || !!isConfirmInvalid || isShortPassword

  const onSubmit = () => {
    setLoading(true)

    const payload = {
      new_password: formData.new_password,
    }

    API.post(config.paths.auth.newPassword, payload)
      .then(({ data }) => {
        setLoading(false)
        setFormData(defaultFormData)

        if (data.success && data.message) {
          const notificationEvent = new CustomEvent("notification", {
            detail: {
              type: "success",
              message: data.message,
            },
          })

          window.dispatchEvent(notificationEvent)
        }
      })
      .catch(() => {
        setLoading(false)
        setFormData(defaultFormData)
      })
  }

  const footer = (
    <div className={style.buttons}>
      <Button label="Изменить" onClick={onSubmit} disabled={isDisabled} loading={isLoading} />
    </div>
  )

  return (
    <div className={style.wrapper}>
      <div className="container">
        <Card className={style.form} title="Изменение пароля" footer={footer}>
          <div className="flex gap-2 flex-col">
            <label htmlFor="newPassword">Введите новый пароль</label>
            <InputText
              id="newPassword"
              value={formData.new_password}
              onChange={e => setFormData({ ...formData, new_password: e.target.value })}
              className={cn(style.input, { "p-invalid": isShortPassword })}
              maxLength={30}
              type="password"
              aria-describedby="new-password-help"
            />
            <small id="new-password-help">Пароль должен быть от 8-ми символов.</small>
          </div>
          <div className="flex gap-2 flex-col">
            <label htmlFor="newPasswordConfirm">Повторите новый пароль</label>
            <InputText
              id="newPasswordConfirm"
              value={formData.new_password_confirm}
              onChange={e => setFormData({ ...formData, new_password_confirm: e.target.value })}
              className={cn(style.input, { "p-invalid": isConfirmInvalid })}
              maxLength={30}
              type="password"
              aria-describedby="new-password-confirm-help"
            />
            <small id="new-password-confirm-help">Пароли должны совпадать.</small>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default NewPassword
