import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "4"

export const Course2: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Роль Белков в Похудении</h1>
      </div>

      <Ration kkal={650} portion="2 - 3" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти рецепты не только вкусные и питательные, но и способствуют достижению целей в похудении благодаря высокому
        содержанию белка и низкому содержанию калорий.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Как белки помогают в снижении веса</h3>
          <p>
            Белки играют важнейшую роль в процессе похудения. Они не только способствуют наращиванию и восстановлению
            мышечной массы, что критически важно для поддержания высокого уровня метаболизма, но и увеличивают чувство
            сытости после еды. Это происходит благодаря тому, что белки медленнее усваиваются организмом, обеспечивая
            длительное ощущение насыщения и тем самым помогая контролировать аппетит. Это уменьшает вероятность
            переедания и чрезмерного потребления калорий.
          </p>
          <p>
            Кроме того, для переваривания белков организм тратит больше энергии, чем для усвоения жиров или углеводов.
            Этот процесс называется "термическим эффектом пищи" (ТЭП). Белки имеют самый высокий ТЭП среди всех
            макроэлементов, что означает, что организм тратит больше калорий на их переваривание. Это способствует
            ускорению метаболизма и увеличению сжигания калорий даже в состоянии покоя.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Протеиновый смузи, салат с тунцом, куриные грудки, йогурт</h3>
          <p>
            Включение в рацион блюд с высоким содержанием белка, таких как протеиновые смузи, салаты с тунцом, куриные
            грудки и йогурт, помогает поддерживать мышечную массу и ускоряет процесс похудения. Протеиновые смузи —
            отличный выбор для завтрака или перекуса, так как они быстро готовятся и могут быть богаты питательными
            веществами. Салаты с тунцом обеспечивают необходимые белки и омега-3 жирные кислоты, а также богаты
            клетчаткой, что способствует длительному чувству сытости. Куриные грудки — это низкокалорийный источник
            белка, который легко сочетается с различными овощами и злаками. Йогурт, особенно греческий, содержит большое
            количество белка и полезных бактерий, которые поддерживают здоровье кишечника.
          </p>
          <p>
            Включение этих продуктов в рацион помогает не только ускорить процесс похудения, но и обеспечивает организм
            всеми необходимыми питательными веществами, поддерживая общее здоровье и благополучие.
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
