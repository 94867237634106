import { Card } from "primereact/card"
import { Link } from "react-router-dom"

import { useAppSelector } from "hooks/dispatch"
import { CANCEL_LITE_ROUTE, CANCEL_SUB_ROUTE } from "routes"
import { UserStatus } from "state/types/app"

import style from "./style.module.scss"

import type { FC } from "react"

const Settings: FC = () => {
  const { firstSixCardNumbers, lastFourCardNumbers, username, activeTill, status, unsubscribedLiteAt } = useAppSelector(
    state => state.app.profile
  )

  const isActive = status !== UserStatus.Unsubscribed
  const isCardActive = !unsubscribedLiteAt

  const getTariff = () => {
    if (status === UserStatus.Active) {
      return "Стандартный"
    }

    if (status === UserStatus.Lite) {
      return "Лайт"
    }

    return "Подписка отменена"
  }

  return (
    <div className={style.wrapper}>
      <div className="container">
        <Card>
          <h1>Настройки {username}</h1>

          <div className="mt-6">Доступ до: {activeTill}</div>

          <h2 className="mt-6">
            Ваш тариф: <b className={isActive ? style.active : style.notActive}>{getTariff()}</b>
          </h2>

          {isCardActive ? (
            <div className="mt-6">
              Ваша карта: {`${firstSixCardNumbers?.slice(0, 4)} ${firstSixCardNumbers?.slice(4, 6)}`}** ****{" "}
              {lastFourCardNumbers} - <b className={isCardActive ? style.active : style.notActive}>Подключена</b>
            </div>
          ) : (
            <div className="mt-6">
              Ваша карта: <b className={isCardActive ? style.active : style.notActive}>Отключена</b>
            </div>
          )}

          <div className="mt-6">
            {status === UserStatus.Lite ? (
              <Link to={CANCEL_LITE_ROUTE} className="link">
                Отменить лайт подписку
              </Link>
            ) : (
              <Link to={CANCEL_SUB_ROUTE} className="link">
                Отменить подписку
              </Link>
            )}
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Settings
