import React from "react"
import cn from "classnames"
import { Chart } from "primereact/chart"

import type { FC } from "react"
import type { WeightHistoryItem } from "./types"

import style from "./style.module.scss"

type Props = {
  weightHistory: Array<WeightHistoryItem>
}

export const WeightChart: FC<Props> = ({ weightHistory }) => {
  const chartDates: Array<string> = weightHistory.map(el => el.date)
  const chartWeights: Array<number> = weightHistory.map(el => el.weight)

  const data = {
    labels: chartDates,
    datasets: [
      {
        label: "Вес",
        data: chartWeights,
        fill: false,
        borderColor: "#6366F1",
        color: "#6366F1",
        backgroundColor: "#6366F1",
      },
    ],
  }

  const options = {
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
  }

  return (
    <div className={cn(style.weightChart, "mt-6 h-52")}>
      <Chart type="line" options={options} data={data} />
    </div>
  )
}
