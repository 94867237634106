import { configureStore } from "@reduxjs/toolkit"

import appReducer from "./slices/app"
import adminReducer from "./slices/admin"
import topicsReducer from "./slices/topics"

export const store = configureStore({
  reducer: {
    app: appReducer,
    admin: adminReducer,
    topics: topicsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
