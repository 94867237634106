import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "13"

export const Course11: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Сон и Похудение</h1>
      </div>

      <Ration kkal={165} portion="4 - 5" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти напитки не только помогут улучшить качество вашего сна, но и являются здоровыми и низкокалорийными, что
        важно для поддержания оптимального веса.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Роль сна в регулировании веса</h3>
          <p>
            Качественный сон играет критическую роль в регулировании веса и общем здоровье. Недостаток сна может
            серьезно повлиять на ваши усилия по похудению, в то время как достаточный и качественный отдых может
            значительно ускорить процесс.
          </p>
          <p>
            Во-первых, недостаток сна нарушает гормональный баланс в организме, в частности, уровни гормонов грелина и
            лептина, которые контролируют чувство голода и насыщения. Недосыпание повышает уровень грелина (гормона
            голода) и снижает уровень лептина (гормона насыщения), что приводит к увеличению аппетита и тяге к
            высококалорийной пище.
          </p>
          <p>
            Также, когда вы не высыпаетесь, ваш организм ищет способы восполнить недостаток энергии, часто приводя к
            желанию съесть что-то сладкое или жирное. Это увеличивает риск переедания и выбора нездоровой пищи.
          </p>
          <p>
            Недостаток сна также влияет на метаболизм. Организм, страдающий от недостатка отдыха, более склонен к
            накоплению жира и снижению скорости обмена веществ. Это делает похудение более сложным и может привести к
            набору веса.
          </p>
          <p>
            Кроме того, недосыпание снижает физическую активность. Усталость и недостаток энергии приводят к меньшему
            желанию заниматься физическими упражнениями, что важно для сжигания калорий и поддержания здоровья.
          </p>
          <p>
            В заключение, качественный сон – это не роскошь, а необходимость для тех, кто стремится к снижению веса и
            поддержанию здорового образа жизни. Регулярный семи-девятичасовой ночной отдых помогает поддерживать
            гормональный баланс, контролировать аппетит, улучшать метаболизм и увеличивать физическую активность.
            Поэтому уделяйте сну столько же внимания, сколько и диете или тренировкам.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Продукты, способствующие лучшему сну</h3>
          <p>
            Для тех, кто стремится к похудению, качественный сон имеет решающее значение. Определенные продукты могут
            помочь улучшить качество сна благодаря своему составу, который способствует расслаблению и укреплению сна.
            Включение этих продуктов в вечерний рацион может быть ключом к более здоровому и восстанавливающему сну.
          </p>
          <p>
            <b>1. Теплое молоко:</b> Богато триптофаном, аминокислотой, которая помогает производить серотонин и
            мелатонин, гормоны, способствующие сну. Теплое молоко также ассоциируется с успокаивающими детскими
            воспоминаниями, что может помочь расслабиться перед сном.
          </p>
          <p>
            <b>2. Бананы:</b> Этот фрукт содержит магний и калий, которые помогают расслабить мышцы. Кроме того, бананы
            богаты углеводами, которые могут немного повысить уровень сахара в крови и вызвать сонливость.
          </p>
          <p>
            <b>3. Овсянка:</b> Она не только отличный источник медленных углеводов, но и содержит мелатонин и витамины
            группы B, способствующие расслаблению и улучшению качества сна.
          </p>
          <p>
            <b>4. Миндаль:</b> Источник здоровых жиров и магния, миндаль может помочь улучшить качество сна, особенно у
            людей, страдающих от бессонницы.
          </p>
          <p>
            <b>5. Камомильный чай:</b> Известен своими успокаивающими свойствами. Камомиль помогает расслабить нервную
            систему, что может облегчить засыпание.
          </p>

          <p>
            <b>6. Темный шоколад:</b> Хотя он содержит кофеин, темный шоколад также богат серотонином, который помогает
            расслабиться. Важно употреблять его в умеренных количествах и не слишком поздно вечером.
          </p>

          <p>
            Помните, что качество вашего сна напрямую связано с тем, что вы едите перед сном. Включение этих продуктов в
            ваш вечерний рацион может помочь вам не только лучше спать, но и более эффективно худеть.
          </p>

          <div className={style.divider} />

          <h3>Рецепты: Теплое молоко с медом, травяной чай для сна</h3>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
