import React from "react"

import { Course1 } from "./courses/1"
import { Course2 } from "./courses/2"
import { Course3 } from "./courses/3"
import { Course4 } from "./courses/4"
import { Course5 } from "./courses/5"
import { Course6 } from "./courses/6"
import { Course7 } from "./courses/7"
import { Course8 } from "./courses/8"
import { Course9 } from "./courses/9"
import { Course10 } from "./courses/10"
import { Course11 } from "./courses/11"
import { Course12 } from "./courses/12"
import { Course13 } from "./courses/13"
import { Course14 } from "./courses/14"
import { Course15 } from "./courses/15"
import { Course16 } from "./courses/16"
import { Course17 } from "./courses/17"
import { Course18 } from "./courses/18"
import { Course19 } from "./courses/19"
import { Course20 } from "./courses/20"
import { Training1 } from "./courses/training1"
import { Training2 } from "./courses/training2"

import topic1 from "images/topics/topic1.jpg"
import topic2 from "images/topics/topic2.jpg"
import topic3 from "images/topics/topic3.jpg"
import topic4 from "images/topics/topic4.jpg"
import topic5 from "images/topics/topic5.jpg"
import topic6 from "images/topics/topic6.jpg"
import topic7 from "images/topics/topic7.jpg"
import topic8 from "images/topics/topic8.jpg"
import topic9 from "images/topics/topic9.jpg"
import topic10 from "images/topics/topic10.jpg"
import topic11 from "images/topics/topic11.jpg"
import topic12 from "images/topics/topic12.jpg"
import topic13 from "images/topics/topic13.jpg"
import topic14 from "images/topics/topic14.jpg"
import topic15 from "images/topics/topic15.jpg"
import topic16 from "images/topics/topic16.jpg"
import topic17 from "images/topics/topic17.jpg"
import topic18 from "images/topics/topic18.jpg"
import topic19 from "images/topics/topic19.jpg"
import topic20 from "images/topics/topic20.jpg"
import topic21 from "images/topics/topic21.jpg"
import topic22 from "images/topics/topic22.jpg"
import topic23 from "images/topics/topic23.jpg"
import training1 from "images/topics/training1.jpg"
import training2 from "images/topics/training2.jpg"
import comingSoon from "images/topics/comingSoon.jpg"

type CourseContent = {
  id: number
  title: string
  subtitle?: string
  previewImage: string
  content?: React.JSX.Element
}

export const coursesContent: Array<CourseContent> = [
  {
    id: 1,
    title: "1. Тренировка",
    subtitle: "8 упражнений",
    previewImage: training1,
    content: <Training1 />,
  },
  {
    id: 2,
    title: "2. Тренировка",
    subtitle: "8 упражнений",
    previewImage: training2,
    content: <Training2 />,
  },
  {
    id: 3,
    title: "3. Введение в питание для похудения",
    subtitle: "Понимание калорий и макроэлементов",
    previewImage: topic1,
    content: <Course1 />,
  },
  {
    id: 4,
    title: "4. Роль белков в похудении",
    subtitle: "Как белки помогают в снижении веса",
    previewImage: topic2,
    content: <Course2 />,
  },
  {
    id: 5,
    title: "5. Углеводы и похудение",
    subtitle: "Выбор правильных углеводов",
    previewImage: topic3,
    content: <Course3 />,
  },
  {
    id: 6,
    title: "6. Здоровые жиры для похудения",
    subtitle: "Польза здоровых жиров",
    previewImage: topic4,
    content: <Course4 />,
  },
  {
    id: 7,
    title: "7. Гидратация и похудение",
    subtitle: "Важность воды и других жидкостей",
    previewImage: topic5,
    content: <Course5 />,
  },
  {
    id: 8,
    title: "8. Снижение сахара для быстрого похудения",
    subtitle: "Влияние сахара на вес",
    previewImage: topic6,
    content: <Course6 />,
  },
  {
    id: 9,
    title: "9. Планирование питания и покупки",
    subtitle: "Как планировать еду и делать покупки",
    previewImage: topic7,
    content: <Course7 />,
  },
  {
    id: 10,
    title: "10. Поддержание веса после похудения",
    subtitle: "Стратегии поддержания веса",
    previewImage: topic8,
    content: <Course8 />,
  },
  {
    id: 11,
    title: "11. Физическая активность и похудение",
    subtitle: "Важность упражнений в процессе похудения",
    previewImage: topic9,
    content: <Course9 />,
  },
  {
    id: 12,
    title: "12. Психология похудения",
    subtitle: "Понимание влияния психологии на процесс похудения",
    previewImage: topic10,
    content: <Course10 />,
  },
  {
    id: 13,
    title: "13. Сон и похудение",
    subtitle: "Роль сна в регулировании веса",
    previewImage: topic11,
    content: <Course11 />,
  },
  {
    id: 14,
    title: "14. Управление стрессом для похудения",
    subtitle: "Связь между стрессом и весом",
    previewImage: topic12,
    content: <Course12 />,
  },
  {
    id: 15,
    title: "15. Понимание и преодоление плато в похудении",
    subtitle: "Стратегии преодоления стагнации веса",
    previewImage: topic13,
    content: <Course13 />,
  },
  {
    id: 16,
    title: "16. Здоровые привычки на каждый день",
    subtitle: "Разработка здоровых привычек питания и образа жизни",
    previewImage: topic14,
    content: <Course14 />,
  },
  {
    id: 17,
    title: "17. Интеграция похудения в социальную жизнь",
    subtitle: "Сохранение прогресса в социальных ситуациях",
    previewImage: topic15,
    content: <Course15 />,
  },
  {
    id: 18,
    title: "18. Долгосрочное поддержание здорового веса",
    subtitle: "Стратегии для поддержания здорового веса на долгий срок",
    previewImage: topic16,
    content: <Course16 />,
  },
  {
    id: 19,
    title: 'Курс "Искусство Интервального Голодания"',
    previewImage: topic17,
    content: <Course17 />,
  },
  {
    id: 20,
    title: "Различные методы интервального голодания",
    previewImage: topic18,
    content: <Course18 />,
  },
  {
    id: 21,
    title: "Польза интервального голодания для здоровья",
    previewImage: topic19,
    content: <Course19 />,
  },
  {
    id: 22,
    title: "Советы по применению интервального голодания",
    previewImage: topic20,
    content: <Course20 />,
  },
  {
    id: 23,
    title: "Физические упражнения и интервальное голодание",
    previewImage: topic21,
    content: <Course19 />,
  },
  {
    id: 24,
    title: "Психологические аспекты интервального голодания",
    previewImage: topic22,
    content: <Course20 />,
  },
  {
    id: 25,
    title: "Рецепты и планы питания при интервальном голодании",
    previewImage: topic23,
    content: <Course20 />,
  },
  {
    id: 26,
    title: "Больше уроков и видео тренировок на подходе...",
    previewImage: comingSoon,
  },
]
