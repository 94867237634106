import { useEffect } from "react"

import { useAppDispatch, useAppSelector } from "hooks/dispatch"
import { getTopicsData } from "state/dispatchers/topics"

import { CourseTitle } from "./components/CourseTitle"
import { CourseProgress } from "./components/CourseProgress"
import { CurrentTopic } from "./components/CurrentTopic"
import { Topics } from "./components/Topics"

import type { FC } from "react"

import style from "./style.module.scss"

const Materials: FC = () => {
  const dispatch = useAppDispatch()

  const { data } = useAppSelector(state => state.topics)

  useEffect(() => {
    if (!data.totalTopicsActive) {
      dispatch(getTopicsData())
    }
  }, [data.totalTopicsActive])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={style.wrapper}>
      <div className="container">
        <CourseTitle />
        <CourseProgress />
        <CurrentTopic />
        <Topics />
        <p className={style.disclaimer}>
          Предлагаемые планы питания, видео тренировок, система интервального голодания представлены только для
          ознакомления. Не являются лечебным питанием, медицинским продуктом и не могут быть использованы в качестве
          лечебной диетотерапии/диетопрофилактики. Предлагаемые планы питания формируются автоматически и могут
          содержать неточности и ошибки, могут не учитывать ряд физических особенностей и ограничений пользователя.
          Представляемая информация является информацией общего характера (не является медицинской информацией) и не
          может заменить консультаций врача. Необходимо обязательно проконсультироваться с врачом перед возможным
          использованием продукта!
        </p>
      </div>
    </div>
  )
}

export default Materials
