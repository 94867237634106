import React from "react"
import { Outlet } from "react-router"

import { Footer } from "app/components/Footer"

import type { FC } from "react"

export const AuthLayout: FC = () => {
  return (
    <React.Fragment>
      <Outlet />

      <Footer />
    </React.Fragment>
  )
}
