import React from "react"
import cn from "classnames"

import { ReactComponent as Girl } from "images/girl.svg"

import type { FC } from "react"

import style from "./style.module.scss"

type Props = {
  glasses: number
}

export const WaterQuote: FC<Props> = ({ glasses }) => {
  const getQuote = (): string => {
    switch (glasses) {
      case 0:
        return "Нет ничего лучше, чем прожить жизнь в стремлении стать совершеннее"

      case 1:
        return "Тело может многое выдержать. Вам нужно только убедить свое сознание в этом"

      case 2:
        return "Никогда не думай, что ты проиграл соревнования, если у тебя ещё осталось последнее упражнение"

      case 3:
        return "Правильное питание — это не диета, а стиль жизни"

      case 4:
        return "Постановка цели — это то, что превращает невидимое в видимое"

      case 5:
        return "Цели никогда не должны быть простыми. Они должны быть неудобными, чтобы заставить вас работать"

      case 6:
        return "Начинайте представлять себя таким человеком, каким хотели бы видеть себя"

      case 7:
        return "Если чувствуешь, что сдаешься, вспомни, ради чего ты держался до этого"

      case 8:
        return "Нет ничего лучше, чем прожить жизнь в стремлении стать совершеннее"

      default:
        return "Нет ничего лучше, чем прожить жизнь в стремлении стать совершеннее"
    }
  }

  return (
    <div className={cn(style.quote, "mt-8")}>
      <span className={style.text}>«{getQuote()}»</span>
      <Girl className={style.image} />
    </div>
  )
}
