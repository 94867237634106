import React from "react"

import { ProgressBar } from "primereact/progressbar"

import { useAppSelector } from "hooks/dispatch"

import type { FC } from "react"

import style from "../style.module.scss"

export const CourseProgress: FC = () => {
  const { activeTopic, topicsCount } = useAppSelector(state => state.topics.data)

  const getProgressValue = (value: number | null): number => {
    if (value === 0 || !value || !topicsCount) {
      return 1
    }

    return (value / topicsCount) * 100
  }

  return (
    <div className={style.courseProgress}>
      <ProgressBar value={getProgressValue(activeTopic)} displayValueTemplate={() => ""} style={{ height: "6px" }} />
      <p>
        Пройдено {activeTopic} из {topicsCount}
      </p>
    </div>
  )
}
