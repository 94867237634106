import Actions from "./components/Actions"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "19"

export const Course17: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>Курс "Искусство Интервального Голодания"</h1>
      </div>

      <div className={style.content}>
        <div className={style.article}>
          <p>
            Добро пожаловать в уникальное путешествие к здоровью, благополучию и гармонии с собственным телом! Перед
            вами не просто курс, а целое приключение, которое откроет новые горизонты в вашем понимании питания,
            самочувствия и жизненной энергии.
          </p>
          <p>
            Интервальное голодание — это не очередная диета или модная тенденция. Это древняя практика, подтвержденная
            современной наукой, которая помогает восстановить естественный ритм вашего тела, улучшить здоровье и даже
            преобразить ваше отношение к еде и самому себе.
          </p>
          <p>
            В нашем курсе вы не найдете строгих запретов или однообразных меню. Здесь мы предлагаем вам исследовать свой
            индивидуальный путь, который сочетает в себе гибкость, понимание собственных потребностей и, конечно же,
            удовольствие от еды.
          </p>

          <div className={style.divider} />

          <h3>Что вас ждет?</h3>
          <p>
            <b>1. Различные методы интервального голодания:</b> Познакомьтесь с разнообразием подходов и выберите тот,
            который лучше всего подойдет именно вам.
          </p>
          <p>
            <b>2. Польза интервального голодания для здоровья:</b> От улучшения метаболизма до укрепления умственных
            способностей — мы рассмотрим все преимущества, подкрепленные научными исследованиями.
          </p>
          <p>
            <b>3. Советы по применению интервального голодания:</b> Как внедрить эту практику в свою жизнь? Мы дадим
            практические рекомендации и советы, чтобы ваш переход был максимально гладким и приятным.
          </p>

          <div className={style.divider} />

          <h3>Почему это важно?</h3>
          <p>
            В современном мире, где изобилие и переедание стали нормой, интервальное голодание предлагает
            оздоровительную паузу, позволяющую вашему телу восстановиться и обновиться. Это возможность перезагрузить не
            только свой метаболизм, но и взгляд на собственное здоровье и благополучие.
          </p>

          <div className={style.divider} />

          <h3>Кому подойдет этот курс?</h3>
          <p>
            Этот курс для всех, кто хочет улучшить свое здоровье, ищет эффективный и гибкий метод контроля веса, или
            просто стремится к более осознанному подходу к питанию. Независимо от вашего текущего уровня знаний или
            состояния здоровья, интервальное голодание может стать той самой изменяющей жизнь привычкой, которую вы
            искали.
          </p>
          <p>
            Присоединяйтесь к нам в этом захватывающем путешествии по исследованию тайн вашего тела и возможностей
            вашего ума. Откройте для себя силу интервального голодания и дайте себе шанс жить лучшей жизнью уже сегодня!
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
