import { useEffect, useRef, useState } from "react"
import dayjs from "dayjs"

import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext"
import { Calendar } from "primereact/calendar"
import { Dropdown } from "primereact/dropdown"

import API from "lib/api"
import config from "config"

import { rebillsStatuses } from "./helper"

import type { FC } from "react"
import type { AdminRebillsQuery } from "state/types/admin"

import style from "../style.module.scss"

type Props = {
  filters: AdminRebillsQuery
  setFilters: (filters: AdminRebillsQuery) => void
}

export const Filters: FC<Props> = ({ filters, setFilters }) => {
  const today = new Date()
  const calendarRef = useRef<Calendar | null>(null)

  const [searchValue, setSearchValue] = useState(filters.username)
  const [streams, setStreams] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [dates, setDates] = useState<Array<Date | null> | null | undefined>(
    filters.from && filters.to ? [new Date(filters.from), new Date(filters.to)] : null
  )

  const options = [
    {
      code: "unset",
      name: "Все потоки",
    },
    ...streams,
  ]

  const handleSearch = (value: string) => {
    setFilters({ ...filters, username: value })
  }

  const getStreams = () => {
    setLoading(true)

    API.get(config.paths.admin.streams)
      .then(({ data }) => setStreams(data.items))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getStreams()
  }, [])

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleSearch(searchValue)
    }, 600)

    return () => {
      clearTimeout(debounceTimer)
    }
  }, [searchValue])

  useEffect(() => {
    if (dates?.length && !!dates[0] && !!dates[1]) {
      setFilters({
        ...filters,
        from: !!dates[0] ? dayjs(dates[0]).format("MM/DD/YYYY") : "",
        to: !!dates[1] ? dayjs(dates[1]).format("MM/DD/YYYY") : "",
      })

      calendarRef.current?.hide()
    }

    if (!dates?.length) {
      setFilters({
        ...filters,
        from: "",
        to: "",
      })
    }
  }, [dates])

  return (
    <Card className={style.filters}>
      <div className="flex h-10 gap-4">
        <div className={style.item}>
          <InputText
            placeholder="Имя пользователя"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        </div>

        <div className={style.item}>
          <Calendar
            ref={calendarRef}
            value={dates}
            onChange={e => setDates(e.value)}
            readOnlyInput
            placeholder="Дата регистрации"
            className="w-full"
            dateFormat="dd/mm/yy"
            maxDate={today}
            showButtonBar
            selectionMode="range"
          />
        </div>
      </div>

      <div className="flex h-10 gap-4 mt-4">
        <div className={style.item}>
          <Dropdown
            value={filters.stream}
            onChange={e => setFilters({ ...filters, stream: e.value })}
            options={options}
            optionLabel="name"
            optionValue="code"
            placeholder="Выберите поток"
            className="w-full"
            emptyMessage="Нет потоков"
            loading={isLoading}
          />
        </div>

        <div className={style.item}>
          <Dropdown
            value={filters.status}
            onChange={e => setFilters({ ...filters, status: e.value })}
            options={rebillsStatuses}
            optionLabel="label"
            optionValue="value"
            placeholder="Статус"
            className="w-full"
          />
        </div>
      </div>
    </Card>
  )
}
