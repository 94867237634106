import {
  ADMIN_PAYMENTS_ROUTE,
  ADMIN_STATS_ROUTE,
  ADMIN_STREAMS_ROUTE,
  ADMIN_PAYOUTS_ROUTE,
  ADMIN_LEADS_ROUTE,
  ADMIN_PROFIT_ROUTE,
  ADMIN_REBILLS_ROUTE,
  ADMIN_FUN_ROUTE,
} from "routes"
import { AccessLevel } from "state/types/app"

export type TabsItem = {
  index: number
  path: string
}

export const tabsItems = (accessLevel?: AccessLevel) => [
  {
    id: "0",
    label: "Статистика",
    icon: "pi pi-chart-bar",
  },
  {
    id: "1",
    label: "Потоки",
    icon: "pi pi-link",
  },
  {
    id: "2",
    label: "Платежи",
    icon: "pi pi-money-bill",
  },
  {
    id: "3",
    label: "Выводы",
    icon: "pi pi-credit-card",
  },
  {
    id: "4",
    label: "Лиды",
    icon: "pi pi-users",
    visible: false,
  },
  {
    id: "5",
    label: "Подписки",
    icon: "pi pi-id-card",
  },
  {
    id: "6",
    label: "Фан факты",
    icon: "pi pi-gift",
    visible: false,
  },
  {
    id: "7",
    label: "Прибыль",
    icon: "pi pi-briefcase",
    visible: accessLevel === AccessLevel.Second,
  },
]

export const tabsRoutes: Array<TabsItem> = [
  {
    index: 0,
    path: ADMIN_STATS_ROUTE,
  },
  {
    index: 1,
    path: ADMIN_STREAMS_ROUTE,
  },
  {
    index: 2,
    path: ADMIN_PAYMENTS_ROUTE,
  },
  {
    index: 3,
    path: ADMIN_PAYOUTS_ROUTE,
  },
  {
    index: 4,
    path: ADMIN_LEADS_ROUTE,
  },
  {
    index: 5,
    path: ADMIN_REBILLS_ROUTE,
  },
  {
    index: 6,
    path: ADMIN_FUN_ROUTE,
  },
  {
    index: 7,
    path: ADMIN_PROFIT_ROUTE,
  },
]
