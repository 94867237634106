import { Card } from "primereact/card"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import type { FC } from "react"
import type { AdminLeadsItem } from "state/types/admin"

type Props = {
  items: Array<AdminLeadsItem>
  isLoading: boolean
}

export const Table: FC<Props> = ({ items, isLoading }) => {
  const bodyTemplateStream = (item: AdminLeadsItem) => {
    return item.stream || "-"
  }

  return (
    <Card className="mt-6">
      <DataTable value={items} loading={isLoading} emptyMessage="Лидов не найдено">
        <Column field="username" header="E-mail"></Column>
        <Column field="stream" header="Поток" body={bodyTemplateStream} align="right"></Column>
        <Column field="createdAt" header="Время" align="right"></Column>
      </DataTable>
    </Card>
  )
}
