import type { ExercisesList } from "./types"

export const exercisesData: ExercisesList = {
  1: {
    name: "Приседания с гантелями",
    videoUrl: "https://youtube.com/embed/QjMy2xOyEtM",
    reps: 10,
    tries: 2,
    method: [
      "Встаньте прямо, ноги поставив чуть шире чем ширина плеч.",
      "Возьмите гантели по бокам (ладони повернуты внутрь) и поднимите их до уровня плеч.",
      "Смотрите прямо, отведите плечи назад, держите спину ровно.",
      "Начинайте приседать, сгибая ноги.",
      "Не округляйте спину.",
      "Держите вес на пятках и не сводите колени.",
      "Продолжайте, пока не достигните позиции полного приседа.",
      "Сделайте паузу внизу движения.",
      "Разгибайте ноги, направляя усилия через пятки, чтобы вернуться в стартовую позицию.",
    ],
    technique: [
      "Сосредоточьтесь на использовании силы ног и ягодиц для подъема.",
      "Держите спину прямой и грудь поднятой во время выполнения упражнения.",
      "Не позволяйте коленям выходить за линию носков.",
    ],
  },

  2: {
    name: "Жим гантелей на грудь",
    videoUrl: "https://youtube.com/embed/pPYDmguJtKg",
    reps: 10,
    tries: 2,
    method: [
      "Лягте на скамью с гантелями, держа их в руках.",
      "Руки держите перпендикулярно полу, ладонями вперед.",
      "Поднимите гантели вверх, проталкивая их от груди, пока руки не выпрямятся.",
      "Затем плавно опустите гантели обратно на уровень груди.",
    ],
    technique: [
      "Сосредоточьтесь на использовании силы грудных мышц для подъема гантелей.",
      "Держите спину прямой и ноги устойчивыми на полу.",
      "Не забывайте дышать равномерно во время выполнения упражнения.",
    ],
  },

  3: {
    name: "Отжимания на трицес",
    videoUrl: "https://youtube.com/embed/Tj0xlan6FPg",
    reps: 10,
    tries: 2,
    method: [
      "Примите исходное положение в упоре на ладони и колени.",
      "Руки поставьте чуть шире плеч, на уровне груди.",
      "Опустите корпус вниз, сгибая руки в локтях, и сделайте вдох.",
      "С выдохом поднимите туловище вверх, выравнивая руки, и вернитесь в исходное положение.",
    ],
    technique: [
      "Сосредоточьтесь на сокращении трицепсов при подъеме тела.",
      "Держите корпус прямым и контролируйте движение.",
      "Не забывайте дышать равномерно во время выполнения упражнения.",
    ],
  },

  4: {
    name: "Болгарские приседания с гантелями",
    videoUrl: "https://youtube.com/embed/VNNBR-iP_us",
    reps: 12,
    tries: 2,
    method: [
      "Возьмите гантели в руки и поставьте одну ногу на платформу или на устойчивую поверхность за собой.",
      "Сделайте приседание, опуская тело вниз, пока передняя нога не будет согнута под прямым углом.",
      "Затем поднимитесь вверх, возвращаясь в исходное положение.",
    ],
    technique: [
      "Сосредоточьтесь на использовании силы ног и ягодиц для приседания и подъема в болгарских приседаниях.",
      "Держите спину прямой и контролируйте движение.",
      "Не забывайте дышать равномерно во время выполнения упражнения.",
      "Распределите вес равномерно на обе ноги и удерживайте стабильную позицию на платформе или устойчивой поверхности.",
      "Контролируйте глубину приседания, чтобы колено передней ноги оставалось за пределами пальцев стопы и не выходило за вертикальную линию над пяткой.",
      "Не расслабляйте мышцы ягодиц и ног, даже когда поднимаетесь вверх.",
    ],
  },

  5: {
    name: "Обратные выпады с переходом в реверанс",
    videoUrl: "https://youtube.com/embed/7blDjgEN4yA",
    reps: 10,
    tries: 3,
    method: [
      "Встаньте прямо, ноги на ширине плеч.",
      "Сделайте шаг назад с одной ноги, опуская колено к полу.",
      "Оттолкнитесь от пола и одновременно перенесите вес на переднюю ногу, выполняя реверанс.",
      "Затем вернитесь в исходное положение, сделав шаг вперед.",
      "Повторите упражнение, выполнив заданное количество повторов для каждой ноги.",
    ],
    technique: [
      "Поддерживайте правильную стойку, спину прямую и ягодицы сжатыми во время выполнения упражнения.",
      "Контролируйте движение, не позволяйте колену превысить позицию стопы и избегайте резких движений.",
    ],
  },

  6: {
    name: `Планка "паук"`,
    videoUrl: "https://youtube.com/embed/7HZpanaeIEM",
    reps: 30,
    tries: 3,
    isSeconds: true,
    method: [
      "Встаньте в планку на прямых руках, поддерживая прямую линию от головы до пяток.",
      "Подтяните одно колено к боку, направляя его в сторону локтя.",
      "Вернитесь в исходную позицию и повторите с другой ногой.",
      "Повторите упражнение, выполнив заданное количество повторов для каждой ноги.",
    ],
    technique: [
      "Поддерживайте прямую линию тела, не допуская прогиба спины или подъема ягодиц.",
      "Контролируйте дыхание, не забывайте равномерно дышать во время выполнения упражнения.",
    ],
  },

  7: {
    name: `Упражнение "Мертвый жук"`,
    videoUrl: "https://youtube.com/embed/-i1YyF9JDMk",
    reps: 10,
    tries: 2,
    method: [
      "Лягте на спину на пол или фитнес-коврик.",
      "Руки вытяните вверх, ноги согните в коленях под углом 90 градусов.",
      "Опустите одновременно противоположные руку и ногу почти до уровня пола, выпрямив их.",
      "Повторите другой рукой и ногой.",
    ],
    technique: [
      "Сосредоточьтесь на использовании силы ягодиц для подъема таза.",
      "Держите спину прямой и контролируйте движение.",
      "Не забывайте дышать равномерно во время выполнения упражнения.",
    ],
  },

  8: {
    name: `Подъемы гантелей "вокруг света"`,
    videoUrl: "https://youtube.com/embed/lHn5zms2kzg",
    reps: 10,
    tries: 2,
    method: [
      "Возьмите гантели в руки и держите их на уровне бедер ладонями вперёд.",
      "Поднимите гантели вверх круговым движением, пока они не окажутся над головой.",
      "Плавно вернитесь в исходное положение.",
    ],
    technique: [
      "Сосредоточьтесь на использовании силы плеч и рук для подъема гантелей.",
      "Держите спину прямой и контролируйте движение.",
      "Не забывайте дышать равномерно во время выполнения упражнения.",
    ],
  },

  9: {
    name: `Лягушачьи подъемы с гантелями`,
    videoUrl: "https://youtube.com/embed/AwtbS4xKJgE",
    reps: 10,
    tries: 2,
    method: ["Встаньте ровно.", "Поднимайте гантели вверх.", "Тянитесь вверх именно локтями."],
    technique: [
      "Сосредоточьтесь на использовании силы плеч и рук для подъема гантелей.",
      "Держите спину прямой и контролируйте движение.",
      "Не забывайте дышать равномерно во время выполнения упражнения.",
    ],
  },

  10: {
    name: `Русский твист`,
    videoUrl: "https://youtube.com/embed/LW3IioDkmu0",
    reps: 10,
    tries: 2,
    method: [
      "Сядьте на пол, согнув колени.",
      "Держите корпус под углом 45 градусов.",
      "Не отрывайте стопы от пола во время выполнения упражнения.",
      "Делайте скручивание из стороны в сторону, поворачивая вместе с корпусом руки.",
    ],
    technique: [
      "Сосредоточьтесь на использовании силы корпуса для поворота.",
      "Держите спину прямой и контролируйте движение.",
      "Не забывайте дышать равномерно во время выполнения упражнения.",
    ],
  },

  11: {
    name: `Приседания с гантелью у груди`,
    videoUrl: "https://youtube.com/embed/hUs7ARUBrx4",
    reps: 10,
    tries: 2,
    method: [
      "Встаньте прямо, ноги поставив чуть шире чем ширина плеч.",
      "Возьмите гантелю и поднимите ее до уровня плеч.",
      "Смотрите прямо, отведите плечи назад, держите спину ровно.",
      "Начинайте приседать, сгибая ноги.",
      "Не округляйте спину.",
      "Держите вес на пятках и не сводите колени.",
      "Продолжайте, пока не достигните позиции полного приседа.",
      "Сделайте паузу внизу движения.",
      "Разгибайте ноги, направляя усилия через пятки, чтобы вернуться в стартовую позицию.",
    ],
    technique: [
      "Сосредоточьтесь на использовании силы ног и ягодиц для подъема.",
      "Держите спину прямой и грудь поднятой во время выполнения упражнения.",
      "Не позволяйте коленям выходить за линию носков.",
    ],
  },

  12: {
    name: `Подъем гантелей на бицепс c разворотом`,
    videoUrl: "https://youtube.com/embed/0a9rjMyT60s",
    reps: 12,
    tries: 2,
    method: [
      "Возьмите гантели в руки с нейтральным хватом (ладони обращены друг к другу), руки должны быть растянуты вниз.",
      "Поднимите гантели к плечам, сгибая руки в локтях и разворачивая ладони к верху.",
      "Опустите руки в исходное положение.",
    ],
    technique: [
      "Сосредоточьтесь на использовании силы бицепсов для подъема гантелей и на контроле движения.",
      "Контролируйте скорость движения гантелей, чтобы максимально задействовать бицепсы и предотвратить использование инерции.",
    ],
  },

  13: {
    name: `Подъем на бицепс "молот"`,
    videoUrl: "https://youtube.com/embed/QmcTejQDtLA",
    reps: 12,
    tries: 2,
    method: [
      "Возьмите гантели в руки с нейтральным хватом (ладони обращены друг к другу), руки должны быть растянуты вниз.",
      "Поднимите гантели к плечам, сгибая руки в локтях.",
      "Опустите руки в исходное положение.",
    ],
    technique: [
      "Сосредоточьтесь на использовании силы бицепсов для подъема гантелей молотком и на контроле движения.",
      "Контролируйте скорость движения гантелей, чтобы максимально задействовать бицепсы и предотвратить использование инерции.",
    ],
  },

  14: {
    name: `Выпады`,
    videoUrl: "https://youtube.com/embed/dnn28HUF1HM",
    reps: 12,
    tries: 2,
    method: [
      "Встаньте спиной к тренировочной скамье и сделайте максимально широкий шаг вперед.",
      "Нога, оставшаяся сзади, помещается на поверхность возвышенности и фиксируется в неподвижном положении.",
      "Руки можно держать сложенными возле груди либо зафиксировать ладони на поясе.",
      "Проследите за ровным положением корпуса, удерживая естественный прогиб в спине.",
      "Теперь начинайте медленно присаживаться, полагаясь на силу впереди стоящей ноги. Она является опорной.",
      "Садитесь до уровня, пока бедро опорной ноги не встанет в параллель поверхности пола.",
      "Колено опорной ноги не выходит за носок, пятку от пола не отрывайте. Чтобы лучше понять технику, держите во внимании то, что центр тяжести приходится на пятку опорной ноги.",
      "Колено сзади стоящей конечности опускается максимально низко, но не касается пола в нижней амплитуде.",
      "Финальная часть выпада заключается в том, что вы с усилием отталкиваетесь обратно, опираясь на пятку опорной ноги.",
      "Вернувшись в исходное положение, готовьтесь к очередному повторению.",
    ],
    technique: [
      "Сосредоточьтесь на использовании силы ног и ягодиц для приседания и подъема.",
      "Держите спину прямой и контролируйте движение.",
      "Не забывайте дышать равномерно во время выполнения упражнения.",
    ],
  },

  15: {
    name: `Толчки бедра с гантелями`,
    videoUrl: "https://youtube.com/embed/YJb3cmmPrws",
    reps: 10,
    tries: 2,
    method: [
      "Лягте спиной на скамью, упритесь в неё сведенными лопатками и плечами.",
      "Поставьте стопы по ширине таза, образовав в коленях прямой угол.",
      "Гантели расположите на тазовом изгибе.",
      "Поднимите таз в верхнюю точку – параллельно полу, в этом положении колени, таз и туловище должны находиться на одном уровне.",
      "На вдохе плавно опустите таз, не касаясь пола.",
      "С выдохом поднимите таз, отталкиваясь пятками от пола, тем самым включая ягодицы и бицепсы бедра в работу.",
      "В верхней точке не поднимайте таз слишком высоко, оставляйте ровную линию всего тела.",
    ],
    technique: [
      "Сосредоточьтесь на активации ягодиц и бедер для выполнения толчков бедра.",
      "Удерживайте стабильное положение корпуса и контролируйте движение ноги, чтобы максимально задействовать ягодицы и бедра.",
      "Не используйте слишком тяжелые гантели, чтобы сохранить правильную технику выполнения и избежать перенапряжения.",
      "Сосредоточьтесь на ощущении работы ягодиц и бедер во время толчков и контролируйте каждое движение.",
      "При выполнении упражнения, удерживайте равновесие и стабильность, не позволяйте себе покачивать или отклоняться в стороны.",
    ],
  },

  16: {
    name: `Выпады со степа`,
    videoUrl: "https://youtube.com/embed/kVgylX-uD0k",
    reps: 12,
    tries: 2,
    method: [
      "Встаньте спиной к степу и сделайте максимально широкий шаг вперед.",
      "Нога, оставшаяся сзади, помещается на поверхность возвышенности и фиксируется в неподвижном положении.",
      "Руки можно держать сложенными возле груди либо зафиксировать ладони на поясе.",
      "Проследите за ровным положением корпуса, удерживая естественный прогиб в спине.",
      "Теперь начинайте медленно присаживаться, полагаясь на силу впереди стоящей ноги. Она является опорной.",
      "Садитесь до уровня, пока бедро опорной ноги не встанет в параллель поверхности пола.",
      "Колено опорной ноги не выходит за носок, пятку от пола не отрывайте. Чтобы лучше понять технику, держите во внимании то, что центр тяжести приходится на пятку опорной ноги.",
      "Колено сзади стоящей конечности опускается максимально низко, но не касается пола в нижней амплитуде.",
      "Финальная часть выпада со степа заключается в том, что вы с усилием отталкиваетесь обратно, опираясь на пятку опорной ноги.",
      "Вернувшись в исходное положение, готовьтесь к очередному повторению.",
    ],
    technique: [
      "Сосредоточьтесь на использовании силы ног и ягодиц для приседания и подъема.",
      "Держите спину прямой и контролируйте движение.",
      "Не забывайте дышать равномерно во время выполнения упражнения.",
    ],
  },
}
