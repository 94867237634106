import { useState } from "react"

import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"

import { PayoutStatus } from "state/types/admin"

import API from "lib/api"
import config from "config"

import type { FC } from "react"

type Props = {
  visible: boolean
  total: string
  onHide: () => void
  refetch: () => void
}

export const CreatePayout: FC<Props> = ({ visible, total, onHide, refetch }) => {
  const [formData, setFormData] = useState({
    amount: "",
  })
  const [isLoading, setLoading] = useState(false)

  const onSubmit = () => {
    const { amount } = formData

    if (!amount || Number(amount) > Number(total)) {
      return
    }

    const payload = {
      ...formData,
      status: PayoutStatus.New,
    }

    setLoading(true)

    API.post(config.paths.admin.payouts, payload)
      .then(({ data }) => {
        if (data.success) {
          setFormData({ amount: "" })
          refetch()
          onHide()
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (!visible) {
    return null
  }

  return (
    <Dialog
      header="Создать заявку на выплату"
      visible={visible}
      style={{ width: "320px" }}
      onHide={onHide}
      draggable={false}
      blockScroll
      dismissableMask
      baseZIndex={9000}
    >
      <InputText
        id="amount"
        style={{ width: "100%" }}
        placeholder="Сумма выплаты"
        value={formData.amount}
        onChange={e => setFormData({ ...formData, amount: e.target.value })}
        pattern="d+"
        maxLength={30}
      />

      <div className="flex justify-end mt-4">
        <Button label="Создать" onClick={onSubmit} disabled={isLoading} />
      </div>
    </Dialog>
  )
}
