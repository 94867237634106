import React, { useState, useEffect } from "react"
import cn from "classnames"

import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"

import { getDateNowFormatted } from "helper/dates"
import { useAppSelector } from "hooks/dispatch"
import { useAppDispatch } from "hooks/dispatch"
import { setUserAge } from "state/slices/app"
import API from "lib/api"
import config from "config"

import type { Dispatch } from "react"
import type { FC, SetStateAction } from "react"
import type { WeightHistoryItem } from "./types"

import style from "./style.module.scss"

type Props = {
  setWeightHistory: Dispatch<SetStateAction<Array<WeightHistoryItem>>>
}

const CURRENT_WEIGHT = "currentWeight"
const TARGET_WEIGHT = "targetWeight"
const START_WEIGHT = "startWeight"
export const WEIGHT_HISTORY = "weightHistory"

export const Weight: FC<Props> = ({ setWeightHistory }) => {
  const dispatch = useAppDispatch()

  const userAge = useAppSelector(state => state.app.profile.age)
  const userUuid = useAppSelector(state => state.app.profile.uuid)

  const [currentModalOpen, setCurrentModalOpen] = useState(false)
  const [targetModalOpen, setTargetModalOpen] = useState(false)
  const [startModalOpen, setStartModalOpen] = useState(false)

  const currentWeight = localStorage.getItem(CURRENT_WEIGHT)
  const targetWeight = localStorage.getItem(TARGET_WEIGHT)
  const startWeight = localStorage.getItem(START_WEIGHT)

  const [age, setAge] = useState<string>(userAge || "")
  const [currentWeightData, setCurrentWeightData] = useState<string>(currentWeight || "")
  const [targetWeightData, setTargetWeightData] = useState<string>(targetWeight || "")

  const isCurrentWeightNegative = Number(currentWeightData) < 1
  const isTargetWeightNegative = Number(targetWeightData) < 1
  const isAgeNegative = Number(age) < 1

  const addWeightToHistory = (weight: number) => {
    const weightHistory: Array<WeightHistoryItem> = JSON.parse(localStorage.getItem(WEIGHT_HISTORY) || "[]")

    weightHistory.push({
      weight,
      date: getDateNowFormatted(),
    })

    setWeightHistory(weightHistory)
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const handleCurrentWeightChange = () => {
    localStorage.setItem(CURRENT_WEIGHT, currentWeightData || "")
    setCurrentModalOpen(false)
    addWeightToHistory(Number(currentWeightData))
    setCurrentWeightData("")
    scrollToTop()
  }

  const handleTargetWeightChange = () => {
    localStorage.setItem(TARGET_WEIGHT, targetWeightData || "")
    setTargetModalOpen(false)
    setTargetWeightData("")
    scrollToTop()
  }

  const handleStartWeightChange = () => {
    localStorage.setItem(CURRENT_WEIGHT, currentWeightData || "")
    localStorage.setItem(TARGET_WEIGHT, targetWeightData || "")
    addWeightToHistory(Number(currentWeightData))

    const payload = {
      age,
    }

    API.patch(config.paths.profile, payload)
      .then(() => {
        dispatch(setUserAge(payload.age))
        setAge("")

        scrollToTop()
        setStartModalOpen(false)
      })
      .catch(() => {
        scrollToTop()
        setStartModalOpen(false)
      })
      .finally(() => {
        setCurrentWeightData("")
        setTargetWeightData("")
      })
  }

  const currentFooterContent = (
    <div>
      <Button
        label="Применить"
        icon="pi pi-check"
        onClick={handleCurrentWeightChange}
        autoFocus
        disabled={isCurrentWeightNegative || !currentWeightData}
      />
    </div>
  )

  const targetFooterContent = (
    <div>
      <Button
        label="Применить"
        icon="pi pi-check"
        onClick={handleTargetWeightChange}
        autoFocus
        disabled={isTargetWeightNegative || !targetWeightData}
      />
    </div>
  )

  const startFooterContent = (
    <div>
      <Button
        label="Применить"
        icon="pi pi-check"
        onClick={handleStartWeightChange}
        autoFocus
        disabled={
          isTargetWeightNegative ||
          isCurrentWeightNegative ||
          !targetWeightData ||
          !currentWeightData ||
          !age ||
          isAgeNegative
        }
      />
    </div>
  )

  useEffect(() => {
    if (userUuid && !startWeight) {
      setStartModalOpen(true)
    }

    if (currentWeight && !startWeight) {
      localStorage.setItem(START_WEIGHT, currentWeight)
    }
  }, [currentWeight, startWeight, targetWeight, userAge, userUuid])

  return (
    <>
      <h2 className="text-2xl mb-3">Вес</h2>

      <div className={style.weight}>
        <Dialog
          header="Укажите ваш вес сегодня"
          visible={currentModalOpen}
          style={{ maxWidth: "380px", width: "90%" }}
          maskClassName={style.dialogMask}
          onHide={() => setCurrentModalOpen(false)}
          footer={currentFooterContent}
          draggable={false}
        >
          <div className="flex gap-2 flex-col">
            <label htmlFor="currentWeight">Вес сегодня, кг</label>
            <InputText
              id="currentWeight"
              value={currentWeightData}
              onChange={e => setCurrentWeightData(e.target.value)}
              maxLength={5}
              min={0}
              className={cn({ "p-invalid": isCurrentWeightNegative })}
              type="number"
              aria-describedby="current-weight-help"
            />
            <small id="current-weight-help">Впишите сюда свой вес.</small>
          </div>
        </Dialog>
        <Dialog
          header="Укажите вашу цель"
          visible={targetModalOpen}
          style={{ maxWidth: "380px", width: "90%" }}
          maskClassName={style.dialogMask}
          onHide={() => setTargetModalOpen(false)}
          footer={targetFooterContent}
          draggable={false}
        >
          <div className="flex gap-2 flex-col">
            <label htmlFor="targetWeight">Ваша цель, кг</label>
            <InputText
              id="targetWeight"
              value={targetWeightData}
              onChange={e => setTargetWeightData(e.target.value)}
              maxLength={5}
              min={0}
              className={cn({ "p-invalid": isTargetWeightNegative })}
              type="number"
              aria-describedby="target-weight-help"
            />
            <small id="target-weight-help">Впишите сюда свою цель.</small>
          </div>
        </Dialog>
        <Dialog
          header="Укажите ваш вес сегодня"
          visible={startModalOpen}
          style={{ maxWidth: "380px", width: "90%" }}
          maskClassName={style.dialogMask}
          onHide={() => setStartModalOpen(false)}
          closable={false}
          footer={startFooterContent}
          draggable={false}
        >
          <div className="flex gap-2 flex-col mb-5">
            <label htmlFor="age">Ваш возраст, лет</label>
            <InputText
              id="age"
              value={age}
              onChange={e => setAge(e.target.value)}
              maxLength={5}
              min={0}
              className={cn({ "p-invalid": isTargetWeightNegative })}
              type="number"
              aria-describedby="age-help"
            />
            <small id="age-help">Укажите свой возраст, в полных годах.</small>
          </div>
          <div className="flex gap-2 flex-col mb-5">
            <label htmlFor="currentWeight">Вес сегодня, кг</label>
            <InputText
              id="currentWeight"
              value={currentWeightData}
              onChange={e => setCurrentWeightData(e.target.value)}
              maxLength={5}
              min={0}
              className={cn({ "p-invalid": isCurrentWeightNegative })}
              type="number"
              aria-describedby="current-weight-help"
            />
            <small id="current-weight-help">Впишите сюда свой вес.</small>
          </div>
          <div className="flex gap-2 flex-col">
            <label htmlFor="targetWeight">Ваша цель, кг</label>
            <InputText
              id="targetWeight"
              value={targetWeightData}
              onChange={e => setTargetWeightData(e.target.value)}
              maxLength={5}
              min={0}
              className={cn({ "p-invalid": isTargetWeightNegative })}
              type="number"
              aria-describedby="target-weight-help"
            />
            <small id="target-weight-help">Впишите сюда свою цель.</small>
          </div>
        </Dialog>
        <div className={style.start}>
          <h4>Укажите ваш сегодняшний вес</h4>
          <div className={style.content}>
            <div className={style.badge}>
              <span>Начальный вес</span>
              <p>{startWeight || 0} кг</p>
            </div>
            <div className={style.badge}>
              <span>Текущий вес</span>
              <p>{currentWeight || 0} кг</p>
            </div>
            <Button
              icon="pi pi-file-edit"
              rounded
              outlined
              severity="secondary"
              aria-label="Edit"
              size="large"
              onClick={() => setCurrentModalOpen(true)}
            />
          </div>
        </div>
        <div className={style.target}>
          <h4>Идите к своей цели!</h4>
          <div className={style.content}>
            <div className={style.badge}>
              <span>Ваш возраст</span>
              <p>{userAge || 0} лет</p>
            </div>
            <div className={style.badge}>
              <span>Ваша цель</span>
              <p>{targetWeight || 0} кг</p>
            </div>
            <Button
              icon="pi pi-file-edit"
              rounded
              outlined
              severity="secondary"
              aria-label="Edit"
              size="large"
              onClick={() => setTargetModalOpen(true)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
