import React from "react"
import cn from "classnames"
import { useNavigate } from "react-router"

import { BlockUI } from "primereact/blockui"
import { Tooltip } from "primereact/tooltip"

import { coursesContent } from "app/sections/Topic/helper"
import { useAppSelector } from "hooks/dispatch"
import { getTopicRoute } from "routes"
import { UserStatus } from "state/types/app"

import type { FC } from "react"

import style from "../style.module.scss"

export const Topics: FC = () => {
  const navigate = useNavigate()

  const { totalTopicsActive, topicsCount } = useAppSelector(state => state.topics.data)
  const { status } = useAppSelector(state => state.app.profile)

  const isLite = status === UserStatus.Lite

  const goToTopic = (topicId: number) => {
    navigate(getTopicRoute(topicId))
  }

  return (
    <div className={style.topicsWrapper}>
      <h2 className={style.topicsTitle}>Программа курса</h2>

      <div className={style.topics}>
        {topicsCount &&
          Array.from({ length: topicsCount + 1 }).map((el, index) => {
            const isBlocked = isLite ? index + 1 > 6 : index + 1 > Number(totalTopicsActive)
            const course = coursesContent[index]

            if (!course?.title) return null

            return (
              <React.Fragment key={index.toString()}>
                {isBlocked && <Tooltip target={`#topic-card-${index + 1}`} />}
                <BlockUI
                  blocked={isBlocked}
                  key={index.toString()}
                  containerClassName={cn(isBlocked && style.blockedCard)}
                  id={`topic-card-${index + 1}`}
                >
                  <div className={style.card}>
                    <h4 className={style.title}>{course.title}</h4>
                    {course.subtitle && <p className={style.text}>{course.subtitle}</p>}
                    <span className={cn(style.readMoreLink, "link")} onClick={() => goToTopic(index + 1)}>
                      Читать
                    </span>
                    <div className={style.img}>
                      <img src={course.previewImage} alt="topic1" />
                    </div>
                  </div>
                </BlockUI>
              </React.Fragment>
            )
          })}
      </div>
    </div>
  )
}
