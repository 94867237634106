import cn from "classnames"

import { Card } from "primereact/card"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"

import { useAppDispatch } from "hooks/dispatch"
import { setSelectedRebill } from "state/slices/admin"
import { UserStatus } from "state/types/app"

import style from "../style.module.scss"

import type { FC } from "react"
import { PaymentVariant, type AdminRebillsItem } from "state/types/admin"

type Props = {
  items: Array<AdminRebillsItem>
  isLoading: boolean
}

export const Table: FC<Props> = ({ items, isLoading }) => {
  const dispatch = useAppDispatch()

  const getStatusColumn = (item: AdminRebillsItem) => {
    if (item.status === UserStatus.Active) {
      return <span className={cn(style.status, style.active)}>Активная</span>
    }

    if (item.status === UserStatus.Lite) {
      return <span className={cn(style.status, style.active)}>Лайт</span>
    }

    return <span className={cn(style.status, style.unsubscribed)}>Отменена</span>
  }

  const getStreamColumn = (item: AdminRebillsItem) => {
    if (!item.stream) {
      return "-"
    }

    return item.stream
  }

  const getPayColumn = (item: AdminRebillsItem) => {
    if (item.pay === PaymentVariant.CloudPayments) {
      return "CloudPayments"
    }

    return "PaySelection"
  }

  const getIntervalColumn = (item: AdminRebillsItem) => {
    if (!item.interval) {
      return "-"
    }

    return item.interval
  }

  const getStartRebillColumn = (item: AdminRebillsItem) => {
    if (!item.start_rebill_date) {
      return "-"
    }

    return item.start_rebill_date
  }

  const getNextPayColumn = (item: AdminRebillsItem) => {
    if (!item.next_pay_date) {
      return "-"
    }

    return item.next_pay_date
  }

  const onSelectRebill = (item: AdminRebillsItem) => {
    dispatch(
      setSelectedRebill({
        username: item.username,
        details: item.details,
      })
    )
  }

  const codeBodyActions = (item: AdminRebillsItem) => {
    return <Button size="small" label="Детали" onClick={() => onSelectRebill(item)} />
  }

  return (
    <Card className="mt-6">
      <DataTable value={items} emptyMessage="Нет подписок" size="small" showGridlines loading={isLoading}>
        <Column field="username" header="E-mail"></Column>
        <Column field="status" header="Статус" body={getStatusColumn}></Column>
        <Column field="stream" header="Поток" body={getStreamColumn} align="center"></Column>
        <Column field="pay" header="Платежка" body={getPayColumn}></Column>
        <Column field="start_rebill_date" header="Старт подписки" body={getStartRebillColumn}></Column>
        <Column field="next_pay_date" header="Следующий платеж" body={getNextPayColumn}></Column>
        <Column field="interval" header="Интервал" body={getIntervalColumn}></Column>
        <Column field="amount" header="Сумма (всего)" align="right"></Column>
        <Column header="Действия" body={codeBodyActions} align="right"></Column>
      </DataTable>
    </Card>
  )
}
