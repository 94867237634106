import { useEffect } from "react"

import { Accordion, AccordionTab } from "primereact/accordion"
import { Card } from "primereact/card"

import { faqItems } from "./helper"

import type { FC } from "react"

import style from "./style.module.scss"

const Faq: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={style.wrapper}>
      <div className="container">
        <Card title="Частые вопросы">
          <Accordion multiple>
            {faqItems.map(item => (
              <AccordionTab header={item.header} key={item.header}>
                <p className="m-0">{item.content}</p>
              </AccordionTab>
            ))}
          </Accordion>
        </Card>

        <Card title="Поддержка">
          <p className="m-0">
            Если у Вас возникли какие-то вопросы, напишите нам на почту:{" "}
            <a href="mailto: support@dietgolodplan.ru" className="whitespace-nowrap">
              support@dietgolodplan.ru
            </a>
            {/* или в телеграмм:{" "}
            <a href="https://t.me/dietsupport_bot" className="whitespace-nowrap">
              TG: t.me/dietsupport_bot
            </a> */}
          </p>
        </Card>
      </div>
    </div>
  )
}

export default Faq
