import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { Button } from "primereact/button"

import API from "lib/api"
import config from "config"
import { buildParams } from "lib/helper"

import { Table } from "./Table"
import { Total } from "./Total"
import { CreatePayout } from "./Create"

import { PayoutStatus, type AdminPayoutsItem, type AdminPayoutsQuery, type AdminPayoutsTotal } from "state/types/admin"
import type { FC } from "react"

const AdminPayouts: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [isLoading, setLoading] = useState(false)
  const [payouts, setPayouts] = useState<Array<AdminPayoutsItem>>([])
  const [total, setTotal] = useState<AdminPayoutsTotal>({
    amount: "",
  })
  const [visible, setVisible] = useState(false)

  const filters: AdminPayoutsQuery = {
    date: searchParams.get("date") || "",
  }

  const setFilters = (filters: AdminPayoutsQuery) => {
    setSearchParams(filters)
  }

  const getData = (isLive?: boolean) => {
    if (!isLive) {
      setLoading(true)
    }

    const query = {
      ...filters,
      date: filters.date || "",
    }

    const url = `${config.paths.admin.payouts}?${buildParams(query)}`

    API.get(url)
      .then(({ data }) => {
        setPayouts(data.items)
        setTotal(data.total)
      })
      .finally(() => setLoading(false))
  }

  const handleApprove = (id: string) => {
    API.put(`${config.paths.admin.payouts}/${id}`, {
      status: PayoutStatus.Paid,
    }).finally(() => {
      getData()
    })
  }

  useEffect(() => {
    getData()
  }, [searchParams])

  return (
    <>
      <CreatePayout visible={visible} total={total.amount} onHide={() => setVisible(false)} refetch={getData} />

      {/* <Filters filters={filters} setFilters={setFilters} /> */}

      <div className="flex h-10 justify-between mt-6">
        <Total total={total} />

        <Button label="Вывести" size="small" onClick={() => setVisible(true)} />
      </div>

      <Table items={payouts} isLoading={isLoading} onApprove={handleApprove} />
    </>
  )
}

export default AdminPayouts
