import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "10"

export const Course8: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Поддержание Веса после Похудения</h1>
      </div>

      <Ration kkal={750} portion="3 - 4" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти рецепты обеспечивают сбалансированное сочетание белков, жиров и углеводов, что помогает поддерживать вес
        после похудения. Регулярное включение таких блюд в рацион, в сочетании с активным образом жизни, способствует
        поддержанию здоровья и благополучия.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Стратегии поддержания веса</h3>
          <p>
            Поддержание веса после похудения требует долгосрочного и осознанного подхода к питанию и образу жизни. Это
            не только о контроле за потребляемыми калориями, но и о поддержании здорового баланса в питании. Важно
            избегать чрезмерного потребления высококалорийной и обработанной пищи, которая может привести к возврату
            потерянного веса. Регулярная физическая активность также играет ключевую роль в поддержании веса, так как
            она помогает сжигать калории и укрепляет мышцы.
          </p>
          <p>
            Сбалансированный рацион с умеренным количеством углеводов, белков и жиров помогает поддерживать достигнутый
            вес и общее состояние здоровья. Важно включать в рацион цельные продукты, такие как овощи, фрукты,
            цельнозерновые, белки (мясо, рыба, бобовые) и здоровые жиры (авокадо, орехи, оливковое масло). Эти продукты
            не только насыщают, но и обеспечивают организм важными питательными веществами.
          </p>
          <div className={style.divider} />
          <p>
            Поддержание сбалансированного рациона помогает контролировать вес и обеспечивает организм всеми необходимыми
            питательными веществами. Регулярное включение разнообразных продуктов из всех пищевых групп гарантирует
            получение всех необходимых макро- и микроэлементов. Это также помогает избежать диетического стресса и
            скуки, которые могут привести к срывам и перееданию.
          </p>
          <p>
            Кроме того, важно слушать свое тело и отвечать на его потребности в пище. Ешьте, когда чувствуете голод, и
            останавливайтесь, когда чувствуете сытость. Избегайте еды по эмоциональным причинам или из-за скуки.
            Поддержание здорового отношения к еде и понимание сигналов, которые отправляет ваше тело, является ключевым
            аспектом поддержания здорового веса на долгосрочной основе.
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
