import { useState } from "react"

import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"

import API from "lib/api"
import config from "config"

import type { FC } from "react"

type Props = {
  visible: boolean
  onHide: () => void
  refetch: () => void
}

export const CreateStream: FC<Props> = ({ visible, onHide, refetch }) => {
  const [formData, setFormData] = useState({
    name: "",
  })
  const [isLoading, setLoading] = useState(false)

  const onSubmit = () => {
    if (!formData.name) {
      return
    }

    setLoading(true)

    API.post(config.paths.admin.streams, formData)
      .then(({ data }) => {
        if (data.success) {
          setFormData({ name: "" })
          refetch()
          onHide()
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (!visible) {
    return null
  }

  return (
    <Dialog
      header="Создать новый поток"
      visible={visible}
      style={{ width: "660px" }}
      onHide={onHide}
      draggable={false}
      blockScroll
      dismissableMask
      baseZIndex={9000}
    >
      <InputText
        id="name"
        style={{ width: "100%" }}
        placeholder="Название потока"
        value={formData.name}
        onChange={e => setFormData({ ...formData, name: e.target.value })}
        maxLength={30}
      />

      <div className="flex justify-end mt-4">
        <Button label="Создать" onClick={onSubmit} disabled={isLoading} />
      </div>
    </Dialog>
  )
}
