import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "18"

export const Course16: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Долгосрочное Поддержание Здорового Веса</h1>
      </div>

      <Ration kkal={650} portion="3 - 4" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти блюда являются прекрасным выбором для долгосрочного планирования питания. Они не только питательные и
        сбалансированные, но и достаточно разнообразные, чтобы поддерживать интерес к здоровому питанию.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Стратегии для поддержания здорового веса на долгий срок</h3>
          <p>
            Поддержание здорового веса на долгий срок требует не просто временных изменений, а разработки и следования
            устойчивым привычкам и образу жизни. Вот несколько стратегий, которые помогут вам сохранить достигнутые
            результаты и поддерживать здоровый вес на протяжении долгого времени.
          </p>
          <p>
            <b>Регулярные Физические Упражнения:</b> Сохранение активного образа жизни критически важно для поддержания
            здорового веса. Найдите виды физической активности, которые вам нравятся, будь то ходьба, бег, плавание или
            йога, и делайте их регулярной частью вашей жизни.
          </p>
          <p>
            <b>Сбалансированное Питание:</b> Сосредоточьтесь на сбалансированном рационе, который включает в себя
            множество цельных продуктов – фруктов, овощей, цельнозерновых, белка и здоровых жиров. Избегайте переедания
            и контролируйте размеры порций.
          </p>
          <p>
            <b>Умение Справляться со Стрессом:</b> Стресс может привести к эмоциональному перееданию и набору веса.
            Найдите эффективные способы управления стрессом, такие как медитация, дыхательные упражнения или хобби,
            которые помогут вам сохранять спокойствие и контроль.
          </p>
          <p>
            <b>Достаточный Сон:</b> Недостаток сна может негативно сказаться на гормонах голода и сытости и увеличить
            желание есть нездоровую пищу. Стремитесь спать от 7 до 9 часов каждую ночь для поддержания здорового веса.
          </p>
          <p>
            <b>Регулярный Мониторинг Веса:</b> Регулярное взвешивание поможет вам отслеживать свой прогресс и быстро
            реагировать на любые изменения в весе.
          </p>
          <p>
            <b>Гибкость и Прощение Себе:</b> Будьте готовы к тому, что иногда вы можете отступать от плана. Важно не
            слишком критично относиться к себе за ошибки и возвращаться к здоровым привычкам, как только это возможно.
          </p>
          <p>
            Поддержание здорового веса – это долгосрочный процесс, который требует постоянства и терпения. Важно не
            искать быстрых решений, а сконцентрироваться на устойчивых изменениях в образе жизни.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Постоянно сбалансированный рацион</h3>
          <p>
            Поддержание постоянно сбалансированного рациона – ключ к долгосрочному здоровью и поддержанию идеального
            веса. Это не о строгих диетах, а о создании устойчивого образа жизни, в котором здоровое питание становится
            естественной частью вашего каждодневного режима.
          </p>
          <p>
            <b>Разнообразие – Основа Питания:</b> Ваш рацион должен включать широкий спектр продуктов из всех пищевых
            групп. Фрукты и овощи обеспечивают витамины, минералы и клетчатку. Цельнозерновые продукты, такие как
            овсянка, коричневый рис и цельнозерновой хлеб, предоставляют необходимые углеводы и энергию. Белки, будь то
            из мяса, рыбы или растительных источников, важны для восстановления и роста тканей. Здоровые жиры, такие как
            оливковое масло, авокадо и орехи, поддерживают работу мозга и сердца.
          </p>
          <p>
            <b>Умеренность в Еде:</b> Важно слушать свое тело и есть только тогда, когда вы действительно голодны.
            Избегайте переедания, останавливайтесь, когда чувствуете легкое насыщение. Это поможет контролировать вес и
            избежать ненужного перегруза организма.
          </p>
          <p>
            <b>Регулярность Приемов Пищи:</b> Старайтесь придерживаться регулярного расписания приемов пищи. Это
            помогает поддерживать стабильный уровень сахара в крови и предотвращает переедание. Небольшие здоровые
            перекусы между основными приемами пищи также могут помочь поддерживать энергию и предотвратить голод.
          </p>
          <p>
            <b>Гидратация:</b> Поддержание гидратации важно для общего здоровья и может помочь в контроле аппетита.
            Часто жажда может быть принята за голод, поэтому пейте достаточно воды в течение дня.
          </p>
          <p>
            <b>Планирование Питания:</b> Запланированный подход к еде помогает избежать импульсивных решений о еде и
            употреблении нездоровых закусок. Планирование блюд заранее и приготовление дома дают больше контроля над
            ингредиентами и порциями.
          </p>
          <p>
            Сбалансированный рацион – это не только о здоровье, это также о наслаждении пищей и удовлетворении. Найти
            равновесие между питательными продуктами и любимыми лакомствами в умеренности – ключ к созданию устойчивых и
            здоровых пищевых привычек.
          </p>

          <div className={style.divider} />

          <h3>Рецепты: Разнообразные и питательные блюда для долгосрочного планирования</h3>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
