import cn from "classnames"

import style from "../style.module.scss"

import type { FC } from "react"
import type { AdminProfitTotal } from "state/types/admin"

type Props = {
  total: AdminProfitTotal
}

export const Total: FC<Props> = ({ total }) => {
  return (
    <div className={cn("mt-6 text-end", style.total)}>
      <p>Всего: </p>
      <p className={cn("font-bold text-lg", Number(total.amount) >= 0 ? "amount" : "minus")}>
        {total?.amount || "0.00"} ₽
      </p>
    </div>
  )
}
