import { createSlice } from "@reduxjs/toolkit"

import type { PayloadAction } from "@reduxjs/toolkit"
import type { TopicsData, TopicsState } from "state/types/topics"

const initialState: TopicsState = {
  isLoading: true,

  data: {
    activeTopic: null,
    topicsCount: null,
    totalTopicsActive: null,
  },
}

export const topicsSlice = createSlice({
  name: "topics",
  initialState,
  reducers: {
    getTopicsDataRequest: state => {
      state.isLoading = true
    },

    getTopicsDataSuccess: (state, action: PayloadAction<TopicsData>) => {
      state.isLoading = false
      state.data = action.payload
    },

    getTopicsDataFailure: state => {
      state.isLoading = false
    },

    setActiveTopic: (state, action: PayloadAction<number>) => {
      state.data = {
        ...state.data,
        activeTopic: action.payload,
      }
    },
  },
})

export const { getTopicsDataFailure, getTopicsDataRequest, getTopicsDataSuccess, setActiveTopic } = topicsSlice.actions

export default topicsSlice.reducer
