import { Card } from "primereact/card"
import { Skeleton } from "primereact/skeleton"

import style from "../style.module.scss"

import type { FC } from "react"
import type { AdminFunFactsData } from "state/types/admin"

type Props = {
  isLoading: boolean
  data: AdminFunFactsData
}

export const Content: FC<Props> = ({ isLoading, data }) => {
  return (
    <Card className={style.content}>
      {isLoading ? (
        <div className={style.skeleton}>
          {Array.from({ length: 16 }).map((_, index) => (
            <Skeleton width="100%" height="72px" borderRadius="16px" key={index.toString()}></Skeleton>
          ))}
        </div>
      ) : (
        <div className={style.contentItem}>
          <div className={style.list}>
            <div>
              <span>Билл</span>
              <b>{data.bills}</b>
              <b>{data.billsDate}</b>
            </div>
            <div>
              <span>Ребилл</span>
              <b>
                {data.rebillsCount} {!!Number(data.rebills) && <span className={style.rebills}>({data.rebills}₽)</span>}
              </b>
              <b>{data.rebillsDate}</b>
            </div>
            <div>
              <span>LTV, ₽</span>
              <b>{data.ltv}</b>
              <b>{data.ltvDate}</b>
            </div>
            <div>
              <span>Итого, ₽</span>
              <b>{data.amount}</b>
              <b>{data.amountDate}</b>
            </div>
          </div>
        </div>
      )}
    </Card>
  )
}
