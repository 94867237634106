import React from "react"
import cn from "classnames"
import { useNavigate } from "react-router"

import { Button } from "primereact/button"

import { useAppSelector } from "hooks/dispatch"
import { coursesContent } from "app/sections/Topic/helper"

import type { FC } from "react"

import style from "../style.module.scss"
import { getTopicRoute } from "routes"

export const CurrentTopic: FC = () => {
  const navigate = useNavigate()

  const { activeTopic, topicsCount } = useAppSelector(state => state.topics.data)

  const goToCurrentTopic = () => {
    navigate(getTopicRoute(activeTopic === topicsCount ? topicsCount : Number(activeTopic) + 1))
  }

  const currentLessonText = activeTopic === topicsCount ? topicsCount : Number(activeTopic) + 1

  const currentTopicData =
    activeTopic === topicsCount
      ? coursesContent.find(el => el.id === topicsCount)
      : coursesContent.find(el => el.id === Number(activeTopic) + 1)

  if (!activeTopic || !currentTopicData?.id) return null

  return (
    <div className={cn(style.currentTopic, style.card)}>
      <h4 className={style.currentTopicTitle}>Текущий урок - {currentLessonText}</h4>

      <div className={style.currentTopicContent}>
        <h4 className={style.title}>{currentTopicData.title}</h4>
        <p className={style.text}>
          {currentTopicData.subtitle}{" "}
          <span className={cn(style.readMoreLink, "link")} onClick={goToCurrentTopic}>
            Читать далее
          </span>
        </p>
        <div className={style.img}>
          <img src={currentTopicData.previewImage} alt="currentTopicImage" />
        </div>
      </div>

      <Button className={style.readMoreButton} label="Читать" onClick={goToCurrentTopic} />
    </div>
  )
}
