import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { Link, useSearchParams } from "react-router-dom"

import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"

import config from "config"
import { AUTH_ROUTE } from "routes"
import API from "lib/api"

import style from "./style.module.scss"

import type { FC } from "react"
import type { AuthFormData } from "state/types/auth"

type Props = {
  isLogin: boolean
}

const defaultFormData: Partial<AuthFormData> = {
  username: "",
}

const RestorePassword: FC<Props> = ({ isLogin }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [formData, setFormData] = useState<Partial<AuthFormData>>(defaultFormData)
  const [isLoading, setLoading] = useState(false)
  const [isResetLoading, setResetLoading] = useState(false)
  const [resetError, setResetError] = useState("")

  const token = searchParams.get("token")
  const uuid = searchParams.get("uuid")

  const isResetWindow = token && uuid

  const isDisabled = !formData.username

  const onSubmit = () => {
    setLoading(true)

    API.post(config.paths.auth.restorePassword, formData)
      .then(({ data }) => {
        setLoading(false)
        setFormData(defaultFormData)

        if (data.success && data.message) {
          const notificationEvent = new CustomEvent("notification", {
            detail: {
              type: "success",
              message: data.message,
            },
          })

          window.dispatchEvent(notificationEvent)
        }
      })
      .catch(() => {
        setLoading(false)
        setFormData(defaultFormData)
      })
  }

  const resetContent = isResetLoading ? (
    <div>Проверка токена...</div>
  ) : (
    <div>{resetError || "Новый пароль был отправлен на Ваш E-mail!"}</div>
  )

  const footer = (
    <div className={style.buttons}>
      <Link to={AUTH_ROUTE}>Вход</Link>

      <Button label="Отправить ссылку" onClick={onSubmit} disabled={isDisabled} loading={isLoading} />
    </div>
  )

  useEffect(() => {
    if (token && uuid) {
      setResetLoading(true)

      const payload = {
        token,
        uuid,
      }

      API.post(config.paths.auth.resetPassword, payload)
        .then(() => {
          setResetLoading(false)
        })
        .catch(({ response }) => {
          setResetLoading(false)

          if (response.data.message) {
            setResetError(response.data.message)
          }
        })
    }
  }, [token, uuid])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    isLogin && navigate("/")
  }, [isLogin])

  if (isLogin) {
    return null
  }

  return (
    <div className={style.wrapper}>
      <div className="container">
        {isResetWindow ? (
          resetContent
        ) : (
          <Card className={style.form} title="Восстановление пароля" footer={footer}>
            <div className="flex gap-2 flex-col">
              <label htmlFor="username">E-mail</label>
              <InputText
                id="username"
                value={formData.username}
                onChange={e => setFormData({ ...formData, username: e.target.value })}
                className={style.input}
                maxLength={70}
                aria-describedby="username-help"
              />
              <small id="username-help">Введите ваш e-mail, чтобы восстановить пароль.</small>
            </div>
          </Card>
        )}
      </div>
    </div>
  )
}

export default RestorePassword
