import React from "react"
import cn from "classnames"
import { Chart } from "primereact/chart"
import ChartDataLabels from "chartjs-plugin-datalabels"

import type { FC } from "react"
import type { GlassItem } from "./types"

import style from "./style.module.scss"

type Props = {
  glassesHistory: Array<GlassItem>
}

export const WaterBar: FC<Props> = ({ glassesHistory }) => {
  const barLabels = glassesHistory.map(el => el.date)
  const barData = glassesHistory.map(el => el.glasses).map(el => el / 2)

  const data = {
    labels: barLabels,
    datasets: [
      {
        label: "Объем воды (л)",
        data: barData,
        backgroundColor: "rgba(0, 143, 251, 0.85)",
        borderColor: "rgba(0, 143, 251, 0.85)",
      },
    ],
  }

  const options = {
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "center",
        align: "center",
        font: {
          weight: "bold",
          size: 12,
        },
        color: "#fff",
        formatter: (value: number) => `${value}л`,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        ticks: {
          stepSize: 0.5,
        },
      },
    },
  }

  return (
    <div className={cn(style.barChart, "mt-6")}>
      <Chart type="bar" options={options} data={data} plugins={[ChartDataLabels]} />
    </div>
  )
}
