import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "7"

export const Course5: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Гидратация и Похудение</h1>
      </div>

      <Ration kkal={110} portion="3 - 4" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Эти напитки помогут не только улучшить гидратацию тела, но и обогатят рацион полезными веществами, способствуя
        процессу похудения.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Важность воды и других жидкостей</h3>
          <p>
            Гидратация играет ключевую роль в процессе похудения и общем здоровье организма. Вода не только помогает
            поддерживать нормальное пищеварение, но и ускоряет метаболизм, способствует выведению токсинов и улучшает
            функции органов. Адекватное потребление воды может также уменьшить чувство голода, так как иногда организм
            может ошибочно интерпретировать жажду как голод. Недостаток жидкости может привести к замедлению
            метаболизма, увеличению чувства голода и снижению энергии.
          </p>
          <p>
            Зеленый чай, инфузированная вода и свежевыжатые соки могут быть отличными источниками гидратации. Зеленый
            чай содержит антиоксиданты, такие как катехины, которые могут ускорять метаболизм и способствовать сжиганию
            жиров. Инфузированная вода с фруктами или овощами — это не только способ увеличить потребление воды, но и
            получить дополнительные витамины и минералы. Свежевыжатые соки, особенно из овощей, могут обеспечить
            организм витаминами и минералами без избыточного количества сахара, содержащегося во фруктовых соках.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Зеленый чай, инфузированная вода, свежевыжатые соки</h3>
          <p>
            Включение в рацион зеленого чая помогает ускорить метаболизм и сжигание жиров, а также обеспечивает
            антиоксидантную защиту. Инфузированная вода с фруктами или овощами делает процесс гидратации более приятным
            и разнообразным, позволяя избежать скуки от обычной воды и увеличивая вероятность достаточного употребления
            жидкости в течение дня. Свежевыжатые соки, особенно овощные, являются отличным дополнением к рациону, так
            как они обеспечивают организм витаминами и минералами, необходимыми для поддержания здоровья и ускорения
            метаболизма. Однако важно учитывать калорийность и содержание сахара в соках, особенно фруктовых, и
            употреблять их в умеренных количествах.
          </p>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
