import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "16"

export const Course14: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Здоровые Привычки на Каждый День</h1>
      </div>

      <Ration kkal={310} portion="3 - 4" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Здоровые привычки – это не только о том, что вы едите или сколько времени проводите в спортзале. Это о создании
        сбалансированной и счастливой жизни, где забота о себе является приоритетом.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Разработка здоровых привычек питания и образа жизни</h3>
          <p>
            Разработка здоровых привычек питания и образа жизни – ключевой момент на пути к долгосрочному благополучию и
            поддержанию идеального веса. Это не о диетах или временных изменениях, а о создании устойчивого образа
            жизни, который приносит радость и здоровье.
          </p>
          <p>
            <b>Сбалансированное питание:</b> Основа здорового питания – это разнообразие. Стремитесь включать в рацион
            больше цельных продуктов: фруктов, овощей, цельнозерновых, белков и здоровых жиров. Эти продукты
            обеспечивают организм необходимыми питательными веществами и способствуют чувству сытости.
          </p>
          <p>
            <b>Регулярные приемы пищи:</b> Поддерживайте регулярный график приемов пищи. Это помогает контролировать
            аппетит и предотвращает переедание. Небольшие, сбалансированные перекусы между основными приемами пищи могут
            помочь поддерживать уровень энергии.
          </p>
          <p>
            <b>Умеренность, а не лишения:</b> Здоровые привычки не означают полного отказа от любимых блюд. Важна
            умеренность и сознательное отношение к еде. Наслаждайтесь вкусом каждого кусочка и избегайте чрезмерного
            употребления.
          </p>
          <p>
            <b>Физическая активность:</b> Найдите виды активности, которые вам нравятся. Это может быть ходьба, йога,
            танцы или занятия в тренажерном зале. Главное, чтобы это приносило удовольствие и было регулярным.
          </p>
          <p>
            <b>Достаточный сон:</b> Качественный сон необходим для восстановления организма и поддержания здорового
            метаболизма. Старайтесь спать 7-9 часов каждую ночь.
          </p>
          <p>
            <b>Управление стрессом:</b> Научитесь справляться со стрессом через медитацию, глубокое дыхание или хобби.
            Стресс может привести к эмоциональному перееданию, поэтому важно находить способы его снижения.
          </p>
          <p>
            Здоровые привычки – это не только о том, что вы едите или сколько времени проводите в спортзале. Это о
            создании сбалансированной и счастливой жизни, где забота о себе является приоритетом.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Ежедневный план здорового питания</h3>
          <p>
            Создание ежедневного плана здорового питания – это не просто способ контролировать вес, но и улучшить общее
            состояние здоровья и благополучие. Важно учитывать баланс макро- и микронутриентов, разнообразие и
            умеренность в еде.
          </p>
          <p>
            <b>Завтрак</b>
            <br />
            Начните день с питательного завтрака, который даст энергию и предотвратит голод в течение утра. Идеальный
            вариант – сочетание белка, сложных углеводов и здоровых жиров. Например, овсянка с ягодами и горсткой орехов
            или омлет из яиц со шпинатом и цельнозерновым тостом.
          </p>
          <p>
            <b>Перекус</b>
            <br />
            Для перекуса выбирайте продукты, которые утолят голод и дадут энергию до следующего приема пищи. Хороший
            выбор – горсть орехов, свежие фрукты или йогурт.
          </p>
          <p>
            <b>Обед</b>
            <br />
            Обед должен быть сбалансированным и включать белки, углеводы и овощи. Попробуйте куриную грудку с киноа и
            салатом из свежих овощей или легкий овощной суп с кусочком цельнозернового хлеба.
          </p>
          <p>
            <b>Второй перекус</b>
            <br />
            Выберите что-то легкое, например, морковные палочки с хумусом или свежий фруктовый салат.
          </p>
          <p>
            <b>Ужин</b>
            <br />
            Ужин должен быть легким, но питательным. Фокусируйтесь на белках и овощах, избегая тяжелых углеводов.
            Отличный вариант – лосось, запеченный с овощами, или тушеная чечевица с овощами.
          </p>
          <p>
            <b>Поздний перекус</b>
            <br />
            Если вы чувствуете голод перед сном, выберите что-то легкое и низкокалорийное, например, небольшой йогурт
            или несколько ломтиков яблока с нежирным сыром.
          </p>
          <p>
            Помните, что план должен быть гибким и адаптированным к вашим индивидуальным потребностям и предпочтениям.
            Учитывайте свой образ жизни, уровень активности и личные цели. Здоровое питание – это не только о выборе
            продуктов, но и о наслаждении едой и создании полезных привычек. Это не только о том, что вы едите, но и о
            том, как и когда вы едите, а также о вашем отношении к питанию в целом. Здоровое питание должно приносить
            удовольствие и быть частью счастливой и удовлетворенной жизни.
          </p>

          <div className={style.divider} />

          <h3>Рецепты: Ягодный йогурт, овощные супы</h3>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
