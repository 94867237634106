import React from "react"
import Actions from "./components/Actions"
import { Lesson } from "./components/Lesson"
import { exercisesData } from "./exercises"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "1"

export const Training1: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Тренировка, 8 упражнений</h1>
      </div>

      <div className={style.content}>
        <div className={style.article}>
          {[1, 2, 3, 4, 5, 6, 7, 16].map((el, index) => (
            <React.Fragment key={el}>
              <Lesson number={index + 1} lesson={exercisesData[el]} />

              <div className={style.divider} />
            </React.Fragment>
          ))}
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
