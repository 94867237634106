export enum Role {
  Admin = "admin",
  User = "user",
}

export enum AccessLevel {
  Zero = 0,
  First = 1,
  Second = 2,
}

export enum UserStatus {
  Active = "active",
  Lite = "lite",
  Unsubscribed = "unsubscribed",
}

export type Profile = {
  id: string
  username: string
  uuid: string
  role: Role
  status: UserStatus
  daysActive: number
  daysRemaining: number
  age: string
  isActive: boolean
  activeTill: string
  accessLevel: AccessLevel
  firstSixCardNumbers: string
  lastFourCardNumbers: string
  unsubscribedAt: string | null
  unsubscribedLiteAt: string | null
}

export type AppState = {
  isLoading: boolean
  profile: Profile
}
