import React from "react"
import { Outlet } from "react-router"

import { Header } from "app/components/Header"
import { Footer } from "app/components/Footer"

import type { FC } from "react"

import style from "./style.module.scss"

type Props = {
  isLogin: boolean
  signOut: () => void
}

export const MainLayout: FC<Props> = ({ isLogin, signOut }) => {
  return (
    <>
      <Header isLogin={isLogin} signOut={signOut} />

      <main className={style.pageWrapper}>
        <Outlet />
      </main>

      <Footer />
    </>
  )
}
