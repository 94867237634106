import { Navigate, Outlet } from "react-router"

import { MATERIALS_ROUTE } from "routes"
import { getRefreshToken } from "lib/helper"

import type { ReactElement } from "react"

type Props = {
  isAdmin: boolean
  isProfileLoaded: boolean
  children?: ReactElement
}

export const AdminRoute = ({ isAdmin, isProfileLoaded, children }: Props) => {
  const refreshToken = getRefreshToken()

  if ((!isAdmin && isProfileLoaded) || !refreshToken) {
    return <Navigate to={MATERIALS_ROUTE} replace />
  }

  return children ? children : <Outlet />
}
