import { useState, useEffect } from "react"

import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { Accordion, AccordionTab } from "primereact/accordion"

import config from "config"
import API from "lib/api"

import { faqItems } from "./helper"

import style from "./style.module.scss"

import type { FC } from "react"

type CancelFormData = {
  username: string
}

const defaultFormData: CancelFormData = {
  username: "",
}

const Cancel: FC = () => {
  const [formData, setFormData] = useState<CancelFormData>(defaultFormData)
  const [isLoading, setLoading] = useState(false)

  const isDisabled = !formData.username

  const onSubmit = () => {
    setLoading(true)

    const payload = {
      username: formData.username,
    }

    API.post(config.paths.cancelSubLite, payload)
      .then(({ data }) => {
        setLoading(false)
        setFormData(defaultFormData)

        if (data.success) {
          window.alert("Подписка полностью отменена.")
        }
      })
      .catch(() => {
        setLoading(false)
        setFormData(defaultFormData)
      })
  }

  const footer = (
    <div className={style.buttons}>
      <Button label="Отменить Lite" onClick={onSubmit} disabled={isDisabled} loading={isLoading} />
    </div>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={style.wrapper}>
      <div className="container">
        <Card>
          <Accordion multiple activeIndex={[0, 1, 2]}>
            {faqItems.map(item => (
              <AccordionTab header={item.header} key={item.header}>
                <p className="m-0">{item.content}</p>
              </AccordionTab>
            ))}
          </Accordion>
        </Card>

        <div className="flex justify-center mt-24">
          <Card className={style.form} title="Отмена Lite подписки" footer={footer}>
            <div className="flex gap-2 flex-col">
              <label htmlFor="username">Ваша электронная почта</label>
              <InputText
                id="username"
                value={formData.username}
                placeholder="mail@mail.ru"
                onChange={e => setFormData({ ...formData, username: e.target.value })}
                className={style.input}
                maxLength={70}
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Cancel
