import { useState, useEffect, type FC } from "react"

import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { FormBody, type RefundFormData } from "./FormBody"

import API from "lib/api"
import config from "config"

import style from "./style.module.scss"

const defaultFormData: RefundFormData = {
  email: "",
  fio: "",
  date_transaction: "",
  refund_reason: "",
  first_card_num: "",
  last_card_num: "",
}

const Refund: FC = () => {
  const [isLoading, setLoading] = useState(false)
  const [formData, setFormData] = useState<RefundFormData>(defaultFormData)

  const isDisabled =
    !formData.email ||
    !formData.date_transaction ||
    !formData.fio ||
    !formData.refund_reason ||
    !formData.first_card_num ||
    !formData.last_card_num

  const onSubmit = () => {
    setLoading(true)

    API.post(config.paths.refund, formData)
      .then(({ data }) => {
        setLoading(false)
        setFormData(defaultFormData)

        if (data.success) {
          const notificationEvent = new CustomEvent("notification", {
            detail: {
              type: "success",
              message: "Заявка на возврат средств успешно отправлена!",
            },
          })

          window.dispatchEvent(notificationEvent)
        }
      })
      .catch(() => {
        setLoading(false)
        setFormData(defaultFormData)
      })
  }

  const footer = (
    <div className={style.buttons}>
      <Button label="Отправить заявку" onClick={onSubmit} disabled={isDisabled} loading={isLoading} />
    </div>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={style.wrapper}>
      <div className="container">
        <Card className={style.form} title="Заявка на возврат средств" footer={footer}>
          <FormBody formData={formData} setFormData={setFormData} />
        </Card>
      </div>
    </div>
  )
}

export default Refund
