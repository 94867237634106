import { Accordion, AccordionTab } from "primereact/accordion"

import style from "./style.module.scss"

import type { FC } from "react"
import type { ExerciseData } from "../../types"

type Props = {
  number: number
  lesson: ExerciseData
}

export const Lesson: FC<Props> = ({ number, lesson }) => {
  const { name, videoUrl, reps, tries, method, technique, isSeconds } = lesson

  return (
    <div className={style.lesson}>
      <h2>
        <span>{number}</span> {name}
      </h2>

      <iframe
        className={style.video}
        src={videoUrl}
        width="560"
        height="315"
        title="Упражнение 1"
        allowFullScreen
      ></iframe>

      <span className={style.reps}>
        {reps} {isSeconds ? "секунд" : "повторов"} ({tries} подхода)
      </span>

      <div className={style.method}>
        <Accordion multiple activeIndex={[]}>
          <AccordionTab header="Способ выполнения">
            {method.map((item, index) => (
              <p key={item}>
                {index + 1}. {item}
              </p>
            ))}
          </AccordionTab>
          <AccordionTab header="Техника выполнения">
            {technique.map((item, index) => (
              <p key={item}>
                {index + 1}. {item}
              </p>
            ))}
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  )
}
