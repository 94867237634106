import Actions from "./components/Actions"
import { Ration } from "./components/Ration"
import { rationsData } from "./rations"

import style from "./style.module.scss"

import type { FC } from "react"

const COURSE_ID = "15"

export const Course13: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.courseTitle}>
        <h1>{COURSE_ID}. Понимание и Преодоление Плато в Похудении</h1>
      </div>

      <Ration kkal={610} portion="4 - 5" rationItems={rationsData[COURSE_ID]} />

      <p className={style.comment}>
        Ключ к преодолению стагнации веса заключается в гибкости подхода и готовности вносить изменения в свой образ
        жизни и пищевые привычки.
      </p>

      <div className={style.content}>
        <div className={style.article}>
          <h3>Информация: Стратегии преодоления стагнации веса</h3>
          <p>
            Стагнация веса или достижение плато в процессе похудения – это обычное явление, с которым сталкиваются
            многие люди. Понимание и преодоление этой стадии требует стратегического подхода и терпения.
          </p>
          <p>
            <b>Переоценка калорийного баланса:</b> Со временем ваше тело адаптируется к уменьшенному калорийному
            потреблению, и метаболизм может замедлиться. Рассмотрите возможность пересмотра своего калорийного
            потребления и расхода. Иногда умеренное увеличение калорий или изменение соотношения белков, жиров и
            углеводов может стимулировать метаболизм.
          </p>
          <p>
            <b>Усиление физической активности:</b> Если ваше тело привыкло к определенному уровню физической активности,
            увеличение интенсивности или изменение типа упражнений может дать новый стимул для сжигания калорий и
            похудения.
          </p>
          <p>
            <b>Достаточный сон и управление стрессом:</b> Недостаток сна и высокий уровень стресса могут способствовать
            увеличению веса и стагнации. Обеспечение хорошего сна и снижение уровня стресса через медитацию, йогу или
            другие расслабляющие практики может помочь преодолеть плато.
          </p>
          <p>
            <b>Гидратация:</b> Поддержание достаточного уровня гидратации важно для метаболических процессов и может
            помочь в преодолении стагнации веса. Вода также помогает контролировать аппетит и увеличивает чувство
            сытости.
          </p>
          <p>
            <b>Изменение пищевых привычек:</b> Иногда небольшие изменения в пищевых привычках, такие как увеличение
            потребления клетчатки, снижение потребления обработанных продуктов и добавление большего количества цельных
            продуктов, могут помочь преодолеть плато.
          </p>
          <p>
            <b>Терпение и последовательность:</b> Важно помнить, что похудение – это долгосрочный процесс. Плато – это
            временное явление, и с терпением и последовательностью в следовании здоровым привычкам вы сможете его
            преодолеть.
          </p>

          <div className={style.divider} />

          <h3>Рацион: Адаптация питания для преодоления плато</h3>
          <p>
            Достижение плато в процессе похудения может быть разочаровывающим, но адаптация питания может стать ключом к
            преодолению этой стагнации. Изменение рациона не только может помочь возобновить потерю веса, но и обогатить
            ваше питание новыми питательными веществами и вкусами.
          </p>
          <p>
            <b>Пересмотр калорийного потребления:</b> Когда вы теряете вес, вашему телу требуется меньше энергии для
            поддержания функций. Рассмотрите возможность уменьшения калорийного потребления на 100-200 ккал, но при этом
            убедитесь, что вы все еще получаете достаточно питательных веществ.
          </p>
          <p>
            <b>Увеличение потребления белка:</b> Белок не только помогает насытиться и снизить общий калорийный прием,
            но и способствует ускорению метаболизма и поддержанию мышечной массы. Включите в свой рацион такие продукты,
            как куриная грудка, рыба, бобовые и орехи.
          </p>
          <p>
            <b>Добавление большего количества клетчатки:</b> Продукты с высоким содержанием клетчатки, такие как овощи,
            фрукты, цельные зерна и бобовые, помогают дольше сохранять чувство сытости и поддерживают здоровье
            пищеварительной системы.
          </p>
          <p>
            <b>Снижение потребления углеводов:</b> Уменьшение употребления простых углеводов, таких как белый хлеб и
            сладости, и замена их на сложные углеводы, например, сложные зерна и крахмалистые овощи, может помочь
            возобновить потерю веса.
          </p>
          <p>
            <b>Повышение гидратации:</b> Иногда жажда может быть принята за голод. Увеличение потребления воды может
            помочь контролировать аппетит и способствовать общему благополучию.
          </p>
          <p>
            <b>Регулярное включение «чистой» пищи:</b> Потребление продуктов, минимально обработанных и богатых
            питательными веществами, таких как свежие овощи и фрукты, цельные зерна и чистые источники белка, может
            помочь оптимизировать вашу диету и преодолеть плато в похудении.
          </p>
          <p>
            Изменение рациона для преодоления плато в процессе похудения требует внимательного подхода и осознанности.
            Слушайте свое тело и не бойтесь экспериментировать, чтобы найти то, что лучше всего подходит именно вам.
            Важно помнить, что каждый организм уникален, и то, что работает для одного человека, может не подойти
            другому. Главное – сохранять баланс и получать удовольствие от здорового питания.
          </p>

          <div className={style.divider} />

          <h3>Рецепты: Салат с киноа и авокадо, протеиновые панкейки</h3>
        </div>
      </div>

      <Actions courseId={Number(COURSE_ID)} />
    </div>
  )
}
