import { useEffect } from "react"
import { useNavigate, useParams } from "react-router"

import { useAppDispatch, useAppSelector } from "hooks/dispatch"
import { getTopicsData } from "state/dispatchers/topics"
import { MATERIALS_ROUTE } from "routes"

import { coursesContent } from "./helper"

import type { FC } from "react"

type Params = {
  topicId: string
}

const Topic: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { topicId } = useParams<Params>()

  const { totalTopicsActive } = useAppSelector(state => state.topics.data)

  const currentTopic = coursesContent.find(el => el.id === Number(topicId))

  useEffect(() => {
    if (totalTopicsActive && Number(topicId) > totalTopicsActive) {
      navigate(MATERIALS_ROUTE)
    }
  }, [topicId, totalTopicsActive])

  useEffect(() => {
    if (!totalTopicsActive) {
      dispatch(getTopicsData())
    }
  }, [totalTopicsActive])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [topicId])

  if (!topicId || !totalTopicsActive || !currentTopic?.id) return null

  return <div className="container">{currentTopic.content}</div>
}

export default Topic
