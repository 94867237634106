import { useRef } from "react"
import cn from "classnames"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"

import { Button } from "primereact/button"
import { Menu } from "primereact/menu"
import { MenuItem } from "primereact/menuitem"

import { getRefreshToken } from "lib/helper"
import { useAppDispatch } from "hooks/dispatch"
import { clearProfile } from "state/slices/app"
import API from "lib/api"
import { useAppSelector } from "hooks/dispatch"
import {
  AUTH_ROUTE,
  NEW_PASSWORD_ROUTE,
  CABINET_ROUTE,
  MATERIALS_ROUTE,
  ADMIN_STATS_ROUTE,
  CANCEL_SUB_ROUTE,
  SETTINGS_ROUTE,
  CANCEL_LITE_ROUTE,
} from "routes"

import config from "config"
import { Role, UserStatus } from "state/types/app"
import logoImage from "images/logo.png"

import type { FC } from "react"

import style from "./style.module.scss"

type Props = {
  isLogin: boolean
  signOut: () => void
}

export const Header: FC<Props> = ({ isLogin, signOut }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profileRef = useRef<Menu | null>(null)

  const { role, isActive, username, activeTill, status } = useAppSelector(state => state.app.profile)

  const isAdmin = role === Role.Admin

  const handleGoToAuth = () => {
    navigate(AUTH_ROUTE)
  }

  const handleGoToNewPassword = () => {
    navigate(NEW_PASSWORD_ROUTE)
  }

  const handleGoToCancel = () => {
    navigate(CANCEL_SUB_ROUTE)
  }

  const handleGoToCancelLite = () => {
    navigate(CANCEL_LITE_ROUTE)
  }

  const handleGoToSettings = () => {
    navigate(SETTINGS_ROUTE)
  }

  const onLogout = () => {
    const refreshToken = getRefreshToken()

    API.post(config.paths.auth.logout, null, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    }).finally(() => {
      signOut()
      dispatch(clearProfile())
    })
  }

  const menuItems: Array<MenuItem> = [
    {
      label: username,
    },
    {
      label: isActive ? `Активен до: ${activeTill}` : "Подписка не активна",
      visible: !!activeTill,
    },
    {
      label: "Изменить пароль",
      command: handleGoToNewPassword,
    },
    {
      label: "Управление подпиской",
      command: handleGoToCancel,
      visible: false,
    },
    {
      label: "Настройка",
      command: handleGoToSettings,
    },
    {
      label: "Отмена Lite подписки",
      command: handleGoToCancelLite,
      visible: status === UserStatus.Lite,
    },
    {
      label: "Выход",
      command: onLogout,
    },
  ]

  return (
    <header className={style.wrapper}>
      <div className="container">
        <div className={style.content}>
          <Link to={MATERIALS_ROUTE} className={style.logo}>
            <img src={logoImage} alt="dietgolodplan" />
          </Link>
          {isLogin ? (
            <div className={cn(style.menuItems, "flex items-center")}>
              {isActive && (
                <>
                  {isAdmin && <Link to={ADMIN_STATS_ROUTE}>Админка</Link>}
                  <Link to={MATERIALS_ROUTE}>Программа</Link>
                  {!isAdmin && <Link to={CABINET_ROUTE}>Результаты</Link>}
                </>
              )}
              <div>
                <Menu
                  model={menuItems}
                  className={style.popupMenu}
                  popup
                  ref={profileRef}
                  id="popup_menu_right"
                  popupAlignment="right"
                />
                <Button
                  icon="pi pi-user"
                  className="mr-2"
                  onClick={event => profileRef.current?.toggle(event)}
                  aria-controls="popup_menu_right"
                  aria-haspopup
                />
              </div>
            </div>
          ) : (
            <Button label="Войти" outlined onClick={handleGoToAuth} />
          )}
        </div>
      </div>
    </header>
  )
}
